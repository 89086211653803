import { alertMail } from '../actions/constants';
import { sendMail } from '../actions/logs';

const bakeErrorString = (property, expectedType) => {
  // console.log('property', property);
  // console.log('typeof property', typeof property);
  return `Got ${property || 'null'} as ${typeof property} expected it to be ${expectedType}`;
};

const sendMail_ = (body, mqttTopic) => {
  const requestBody = {
    to: alertMail,
    subject: `${localStorage.getItem('machineId')} - Got wrong mqtt data at ${mqttTopic}`,
    html_body: `${body}. Current app version ${process.env.REACT_APP_VERSION}`,
    alt_body: 'Tested',
  };
  sendMail(requestBody);
};

const heartbeatResponseSchema = data => {
  const { m } = { ...data };
  let MailString = '';
  let isValidated = true;
  if (typeof m !== 'string') {
    isValidated = false;
    MailString = `Got m as ${typeof m} expected it to be string`;
  }

  if (!isValidated) {
    sendMail_(MailString, 'heartbeatResponseSchema');
  }

  return isValidated;
};

export const initiateOrderResponse = data => {
  const { m } = { ...data };
  let MailString = '';
  let isValidated = true;
  if (typeof m !== 'string') {
    isValidated = false;
    MailString = `Got m as ${typeof m} expected it to be string`;
  }

  if (!isValidated) {
    sendMail_(MailString, 'initiateOrderResponse');
  }

  return isValidated;
};
// mid:WENDOR_CONFIG.machineId,
//            oid:oid,
//            oiid:oiid,
//            oir:data.toString(),
//            items:position,
//            s:wasVendingSuccessful(data.substring(0,1)),
//            e:"MOTOR DID NOT TURN"

export const sendResForResponseValidate = data => {
  const { mid, oid, oiid, oir, items } = { ...data };
  let MailString = '';
  let isValidated = true;

  if (!(typeof mid === 'string' || typeof mid === 'number')) {
    MailString += bakeErrorString('mid', ['string', 'number']);
    isValidated = false;
  }
  if (typeof oid !== 'string') {
    MailString += bakeErrorString('oid', ['string']);
    isValidated = false;
  }

  if (typeof oiid !== 'string') {
    MailString += bakeErrorString('oiid', ['string']);
    isValidated = false;
  }

  if (!(typeof oir === 'string' || typeof oir === 'number')) {
    MailString += bakeErrorString('oir', ['string', 'number']);
    isValidated = false;
  }

  if (typeof items !== 'string') {
    MailString += bakeErrorString('items', ['string']);
    isValidated = false;
  }

  if (!isValidated) {
    sendMail_(MailString, 'sendResForResponseValidate');
  }
  return isValidated; // && e === 'string' && oid === 'string' && (mid === 'string' || mid === 'number')
};

// code: 100
// isReady: true
// machineId: "1022"
// message: "success"
// requestId: "1022_5d440cda91b5a_bb"
// status: "READY"
export const currencyReturnerDispensingValidate = data => {
  const { machineId, requestId, isReady, code, message, status } = { ...data };
  let MailString = '';
  let isValidated = true;

  if (typeof machineId !== 'string') {
    MailString += bakeErrorString('machineId', ['string']);
    isValidated = false;
  }
  if (typeof requestId !== 'string') {
    MailString += bakeErrorString('requestId', ['string']);
    isValidated = false;
  }
  if (typeof isReady !== 'boolean') {
    MailString += bakeErrorString('isReady', ['boolean']);
    isValidated = false;
  }
  if (typeof code !== 'number') {
    MailString += bakeErrorString('code', ['number']);
    isValidated = false;
  }
  if (typeof status !== 'string') {
    MailString += bakeErrorString('status', ['string']);
    isValidated = false;
  }
  if (typeof message !== 'string') {
    MailString += bakeErrorString('message', ['string']);
    isValidated = false;
  }

  if (!isValidated) {
    sendMail_(MailString, 'cashInitResponseValidate');
  }
  return isValidated;
};

export const currencyReturnerDispensedValidate = data => {
  const { machineId, requestId, totalCoinPayout, totalCashPayout, message, error } = { ...data };
  let MailString = '';
  let isValidated = true;

  if (typeof machineId !== 'string') {
    MailString += bakeErrorString('machineId', ['string']);
    isValidated = false;
  }
  if (typeof requestId !== 'string') {
    MailString += bakeErrorString('requestId', ['string']);
    isValidated = false;
  }
  if (typeof totalCoinPayout !== 'object') {
    MailString += bakeErrorString('totalCoinPayout', ['object']);
    isValidated = false;
  }
  if (typeof totalCashPayout !== 'object') {
    MailString += bakeErrorString('totalCashPayout', ['object']);
    isValidated = false;
  }
  if (typeof message !== 'string') {
    MailString += bakeErrorString('message', ['string']);
    isValidated = false;
  }
  if (typeof error !== 'boolean') {
    MailString += bakeErrorString('error', ['boolean']);
    isValidated = false;
  }

  if (!isValidated) {
    sendMail_(MailString, 'cashInitResponseValidate');
  }
  return isValidated;
};

export const cashInitResponseValidate = data => {
  const { machineId, requestId, isReady, code, message, status } = { ...data };
  let MailString = '';
  let isValidated = true;

  if (typeof machineId !== 'string') {
    MailString += bakeErrorString('machineId', ['string']);
    isValidated = false;
  }
  if (typeof requestId !== 'string') {
    MailString += bakeErrorString('requestId', ['string']);
    isValidated = false;
  }
  if (typeof isReady !== 'boolean') {
    MailString += bakeErrorString('isReady', ['boolean']);
    isValidated = false;
  }
  if (typeof code !== 'number') {
    MailString += bakeErrorString('code', ['number']);
    isValidated = false;
  }
  if (typeof status !== 'string') {
    MailString += bakeErrorString('status', ['string']);
    isValidated = false;
  }
  if (typeof message !== 'string') {
    MailString += bakeErrorString('message', ['string']);
    isValidated = false;
  }

  if (!isValidated) {
    sendMail_(MailString, 'cashInitResponseValidate');
  }
  return isValidated;
};

// billCollected: 20
// machineId: "1022"
// requestId: "1022_5d440cda91b5a_bb"
export const cashReceivedValidate = data => {
  const { billCollected, requestId, uuid } = { ...data };
  let MailString = '';
  let isValidated = true;

  if (typeof billCollected !== 'number') {
    MailString += bakeErrorString('billCollected', ['number']);
    isValidated = false;
  }

  if (typeof requestId !== 'string') {
    MailString += bakeErrorString('requestId', ['string']);
    isValidated = false;
  }

  if (typeof uuid !== 'string') {
    MailString += bakeErrorString('uuid', ['string']);
    isValidated = false;
  }

  // console.log('MailString', MailString);

  if (!isValidated) {
    sendMail_(MailString, 'cashReceivedValidate');
  }

  return isValidated;
};

// didRequestTimedOut: false
// machineId: "1022"
// requestId: "1022_5d440cda91b5a_bb"
// totalCashCollected: 40
// totalCashRequested: 24
export const cashCollectionCompleteValidate = data => {
  const { requestId, machineId, totalCashRequested, totalCashCollected, didRequestTimedOut } = { ...data };
  let MailString = '';
  let isValidated = true;

  if (typeof machineId !== 'string') {
    MailString += bakeErrorString('machineId', ['string']);
    isValidated = false;
  }
  if (typeof requestId !== 'string') {
    MailString += bakeErrorString('requestId', ['string']);
    isValidated = false;
  }
  if (!(typeof totalCashRequested === 'number' || typeof totalCashRequested === 'string')) {
    MailString += bakeErrorString('totalCashRequested', ['string', 'number']);
    isValidated = false;
  }
  if (!(typeof totalCashCollected === 'number' || typeof totalCashCollected === 'string')) {
    MailString += bakeErrorString('totalCashCollected', ['string', 'number']);
    isValidated = false;
  }
  if (typeof didRequestTimedOut !== 'boolean') {
    MailString += bakeErrorString('didRequestTimedOut', ['boolean']);
    isValidated = false;
  }

  if (!isValidated) {
    sendMail_(MailString, 'cashCollectionCompleteValidate');
  }

  return isValidated;
};

export const paymentCompleteMessageValidate = data => {
  const { machineId, paymentMethodUsed, requestId, status, totalAmountPaid } = { ...data };
  let MailString = '';
  let isValidated = true;

  if (typeof machineId !== 'string') {
    MailString += bakeErrorString('machineId', ['string']);
    isValidated = false;
  }
  if (typeof paymentMethodUsed !== 'string') {
    MailString += bakeErrorString('paymentMethodUsed', ['string']);
    isValidated = false;
  }
  if (typeof requestId !== 'string') {
    MailString += bakeErrorString('requestId', ['string']);
    isValidated = false;
  }
  if (typeof status !== 'string') {
    MailString += bakeErrorString('status', ['string']);
    isValidated = false;
  }
  if (typeof totalAmountPaid !== 'string') {
    MailString += bakeErrorString('totalAmountPaid', ['string']);
    isValidated = false;
  }

  if (!isValidated) {
    sendMail_(MailString, 'paymentCompleteMessageValidate');
  }
  return isValidated;
};

export const coinReturnerInitReponseValidate = payload => {
  const { request_id: requestId } = { ...payload };
  let MailString = '';
  let isValidated = true;

  if (typeof requestId !== 'string') {
    MailString += bakeErrorString('requestId', ['string']);
    isValidated = false;
  }

  if (!isValidated) {
    sendMail_(MailString, 'coinReturnerInitReponseValidate');
  }
  return isValidated;
};

export const cashDispensedMessageValidate = payload => {
  const { message, error, data } = { ...payload };

  let MailString = '';
  let isValidated = true;

  if (typeof message !== 'string') {
    MailString += bakeErrorString('message', ['string']);
    isValidated = false;
  }

  if (!(typeof error === 'string' || typeof error === 'boolean')) {
    MailString += bakeErrorString('error', ['string', 'boolean']);
    isValidated = false;
  }
  if (!(typeof data.amountDispensed === 'number' || typeof data.amountDispensed === 'string')) {
    MailString += bakeErrorString('data.amountDispensed', ['string', 'number']);
    isValidated = false;
  }

  if (!(typeof data.amountRequested === 'number' || typeof data.amountRequested === 'string')) {
    MailString += bakeErrorString('data.amountRequested', ['string', 'number']);
    isValidated = false;
  }
  if (!(typeof data.totalCoinsDispensed === 'number' || typeof data.totalCoinsDispensed === 'string')) {
    MailString += bakeErrorString('data.totalCoinsDispensed', ['string', 'number']);
    isValidated = false;
  }
  if (!(typeof data.coinResponseDetails === typeof [])) {
    MailString += bakeErrorString('data.coinResponseDetails', ['Array']);
    isValidated = false;
  }

  if (!isValidated) {
    sendMail_(MailString, 'cashDispensedMessageValidate');
  }

  return isValidated;
};

export default heartbeatResponseSchema;
