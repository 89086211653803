import React, { Fragment, memo } from 'react';
import uniqid from 'uniqid';
import '../assets/css/RowItemCard.css';
import NewItemCardDisplayWithoutScroll from './NewItemCardDisplayWithoutScroll';

class NewRowItemCardsWithoutScroll extends React.PureComponent {
  // export default ({ productsData, updateCart, totalItems }) => {\
  render() {
    const { productsData, updateCart, machineType, productDisplayPage, maxItems } = {
      ...this.props,
    };
    const currencyDetails = JSON.parse(localStorage.getItem('cash'));

    return (
      <div key={uniqid()} className="tray-row">
        {machineType !== 'rowproductselect' &&
          machineType !== 'selectionbyproductid' &&
          productsData.map(productItem => {
            return (
              <Fragment key={uniqid()}>
                <NewItemCardDisplayWithoutScroll
                  symbol={currencyDetails.symbol}
                  suffix={currencyDetails.isSuffix}
                  productItem={productItem}
                  updateCart={updateCart}
                  machineType={machineType}
                  productDisplayPage={productDisplayPage}
                  maxItems={maxItems}
                />
              </Fragment>
            );
          })}
        <div className="row-footer">.</div>
      </div>
    );
  }
}

export default memo(NewRowItemCardsWithoutScroll);
