import axios from 'axios';

export default async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_KIOSK_CRITICAL_SERVICE_URL}/api/isUpdateAvailable`,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};
