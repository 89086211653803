import React from 'react';
import MetroStationNameListing from './MetroStationNameListing';
import './style.css';

export default ({ productsData, updateCart, checkout, totalItems }) => (
  <div className="metro-screen">
    {
      <MetroStationNameListing
        productsData={productsData}
        updateCart={updateCart}
        totalItems={totalItems}
        checkout={checkout}
      />
    }
  </div>
);
