import React, { useState, useEffect } from 'react';
import { getPaymentDetails } from '../../actions/payment';
import { ThreeBubbleLoader } from '../../components';
import QRDisplay from '../../components/QrDisplay';

const PaytmFlow = ({ requestId, checkPaymentStatus }) => {
  const [isPayTmQRFetched, setisPayTmQRFetched] = useState(false);
  const [isLoadingPayTmQR, setisLoadingPayTmQR] = useState(true);
  const [isPayTmQRFetchedError, setisPayTmQRFetchedError] = useState(false);
  const [PayTmQRData, setPayTmQRData] = useState('');

  const getPayTMDetails = () => {
    // GET PayTM QR

    window.heap.track('Payment Method', {
      paymentMethod: 'PayTM',
      time: Date.now(),
      requestId,
    });

    if (PayTmQRData !== '') {
      // console.log('have qr data');
      return;
    }

    if (!requestId) {
      window.heap.track('no request id found ', {
        time: Date.now(),
      });
      setisPayTmQRFetched(false);
      setisLoadingPayTmQR(false);
      setisPayTmQRFetchedError(true);
      return;
    }

    const details = {
      payment_method: 'paytm',
      request_id: requestId,
    };

    setisPayTmQRFetched(false);
    setisLoadingPayTmQR(true);
    setisPayTmQRFetchedError(false);

    getPaymentDetails(details).then(data => {
      if (!data.error) {
        window.heap.track('Paytm details Response', {
          paymentMethod: 'PayTM',
          time: Date.now(),
          requestId,
          data,
        });
        setisPayTmQRFetched(true);
        setisLoadingPayTmQR(false);
        setisPayTmQRFetchedError(false);
        setPayTmQRData(data.data);

        checkPaymentStatus(); // Check payment status for paytm
      } else {
        window.heap.track('Paytm details Error', {
          paymentMethod: 'PayTM',
          time: Date.now(),
          requestId,
        });

        setisPayTmQRFetched(false);
        setisLoadingPayTmQR(false);
        setisPayTmQRFetchedError(true);
        setPayTmQRData('');
      }
    });
  };

  useEffect(() => {
    // console.log('getPayTMDetails ----');
    window.heap.track('Paytm component renderd', {
      time: Date.now(),
    });
    getPayTMDetails();
  }, []);

  if (!requestId) {
    return <ThreeBubbleLoader />;
  }

  return (
    <QRDisplay
      isQRLoading={isLoadingPayTmQR}
      isQRFetched={isPayTmQRFetched}
      isQRFetchError={isPayTmQRFetchedError}
      QRData={PayTmQRData}
      requestId={requestId}
      showRetry
      retryButtonClickFunction={getPayTMDetails}
    />
  );
};

export default PaytmFlow;
