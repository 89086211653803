import React, { Fragment, memo } from 'react';
import uniqid from 'uniqid';
import '../assets/css/RowItemCard.css';
import ItemCardDisplayRowVersion from './ItemCardRowVersionDisplay';
import WithImageCardDisplayDubai from './WithImageCardDisplayDubai';

class WithImageRowItemCardsDubai extends React.PureComponent {
  // export default ({ productsData, updateCart, totalItems }) => {\
  render() {
    const { productsData, index, updateCart, machineType, productDisplayPage, maxItems } = {
      ...this.props,
    };
    const currencyDetails = JSON.parse(localStorage.getItem('cash'));

    if (machineType === 'rowproductselect' || machineType === 'selectionbyproductid') {
      return (
        <div>
          <ItemCardDisplayRowVersion
            symbol={currencyDetails.symbol}
            suffix={currencyDetails.isSuffix}
            productItems={productsData}
            index={index}
            updateCart={updateCart}
            machineType={machineType}
            productDisplayPage={productDisplayPage}
          />
        </div>
      );
    }
    return (
      <div
        key={uniqid()}
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3,1fr)',
          padding: '10px 24px',
          gap: '2px',
          boxSizing: 'border-box',
        }}
        className="with-image-tray-row">
        {machineType !== 'rowproductselect' &&
          machineType !== 'selectionbyproductid' &&
          productsData.map(productItem => {
            return (
              <Fragment key={uniqid()}>
                <WithImageCardDisplayDubai
                  symbol={currencyDetails.symbol}
                  suffix={currencyDetails.isSuffix}
                  productItem={productItem}
                  updateCart={updateCart}
                  machineType={machineType}
                  productDisplayPage={productDisplayPage}
                  maxItems={maxItems}
                />
              </Fragment>
            );
          })}
      </div>
    );
  }
}

export default memo(WithImageRowItemCardsDubai);
