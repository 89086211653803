import { CART_REDUCER } from '../../actions/constants';

const cartReducer = (state = {}, { type, payload }) => {
  const newState = Object.assign({}, state);
  switch (type) {
    case CART_REDUCER.SET_PRODUCTS_WITH_CART_QUANTITY:
      return {
        ...state,
        productsWithCartQuantity: payload,
      };
    case CART_REDUCER.UPDATE_CART:
      return {
        ...state,
        cartData: payload.cartData,
        totalAmount: payload.totalAmount,
        totalItems: payload.totalItems,
        newCustomer: payload.totalItems === 0,
      };
    case CART_REDUCER.RESET_CART:
      return {
        ...state,
        cartData: [],
        totalAmount: 0.0,
        totalItems: 0,
        newCustomer: true,
        disableSelect: false,
        numberOfReset: newState.numberOfReset ? newState.numberOfReset + 1 : 1,
      };
    case CART_REDUCER.DISABLE_SELECT:
      return {
        ...state,
        disableSelect: payload,
      };
    default:
      return state;
  }
};

export default cartReducer;
