import React from 'react';
import { Row, Col } from 'reactstrap';
import NumberFormat from 'react-number-format';

const VendingCardDetailsUI = ({ itemsToVend, vendedItems, vendModalUIOptions }) => {
  let key = 0;
  const currencyDetails = JSON.parse(localStorage.getItem('cash'));
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Row>
          {itemsToVend.length > 0 &&
            itemsToVend.map((item, index) => {
              key += 1;
              let colClasses = 'vending-item-card ';
              if (vendedItems.length > index) {
                if (vendedItems[index].isSuccess) {
                  colClasses += 'vend-success ';
                } else {
                  colClasses += 'vend-failed ';
                }
              } else if (vendedItems.length < index) {
                colClasses += 'vend-loading ';
              } else {
                colClasses += 'vending-item ';
              }
              return (
                <Col key={key} xs={6} sm={6} md={4} lg={4}>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} className={colClasses}>
                      {vendModalUIOptions.vendingItemCardDetails.showImage && (
                        <img
                          className="vending-item-card-image"
                          src={item.image_mini || '/logo.png'}
                          alt={item.product_name}
                        />
                      )}
                      {vendModalUIOptions.vendingItemCardDetails.showName && (
                        <h5>{item.product_name ? item.product_name.substring(0, 15) : ''}</h5>
                      )}
                      {vendModalUIOptions.vendingItemCardDetails.showPosition && <h5>{item && item.show_pos}</h5>}
                      {vendModalUIOptions.vendingItemCardDetails.showPrice && (
                        <h5>
                          {item && (
                            <NumberFormat
                              value={item.price}
                              displayType="text"
                              thousandSeparator
                              prefix={currencyDetails.isSuffix ? null : currencyDetails.symbol}
                              suffix={currencyDetails.isSuffix ? currencyDetails.symbol : null}
                            />
                          )}
                        </h5>
                      )}
                      {vendedItems.length > index &&
                        vendedItems[index].isSuccess &&
                        vendedItems[index].itemId === item.bill_item_id && <ion-icon name="checkmark" />}
                      {vendedItems.length > index && !vendedItems[index].isSuccess && <ion-icon name="close" />}
                      {vendedItems.length <= index && (
                        <span>
                          <ion-icon name="ellipsis-horizontal-outline" />{' '}
                          <ion-icon name="ellipsis-horizontal-outline" />{' '}
                          <ion-icon name="ellipsis-horizontal-outline" />{' '}
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
              );
            })}
        </Row>
      </Col>
    </Row>
  );
};

export default VendingCardDetailsUI;
