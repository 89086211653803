import React from 'react';
import { Row, Col, Button } from 'reactstrap';

import { ThreeBubbleLoader } from '..';

const EzeTapUI = ({ isEzeTapInitiated, isLoadingEzeTap, isEzeTapInitError, retryEzeTap }) => {
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            {isLoadingEzeTap && (
              <div>
                <h3>Please wait</h3>
                <h5>We are checking card acceptor.</h5>
              </div>
            )}

            {isEzeTapInitiated && (
              <div>
                <ion-icon name="happy" />
                <h5>Please proceed on the machine</h5>
              </div>
            )}

            {isEzeTapInitError && (
              <div className="sorry-message">
                <ion-icon name="sad" />
                <h3>Sorry!</h3>
                <h5>Error in starting the device please try again!</h5>
                <Button className="wendor-bg-green wendor-small-button" onClick={retryEzeTap}>
                  Retry
                </Button>
              </div>
            )}

            {isLoadingEzeTap && <ThreeBubbleLoader />}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EzeTapUI;
