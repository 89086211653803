import React from 'react';
import { Row, Col } from 'reactstrap';
import NumberFormat from 'react-number-format';
// import Slider from 'react-slick';
import Carousel from 'nuka-carousel';
import '../assets/css/RowItemCard.css';

export default ({ productsData, updateCart }) => {
  // console.log(productsData);
  const currencyDetails = JSON.parse(localStorage.getItem('cash'));

  const settings = {
    customPaging(i) {
      return (
        <a href="/">
          <img
            className="width80"
            alt={productsData[i].name.toUpperCase()}
            src={productsData[i].image_mini || 'https://graphics.basics.com/img/fl/49049_fl.png'}
          />
          {/* <p>{productsData[i].name.toUpperCase()}</p> */}
          <p>
            <NumberFormat
              value={` ${productsData[i].price} `}
              displayType="text"
              thousandSeparator
              prefix={currencyDetails.isSuffix ? null : currencyDetails.symbol}
              suffix={currencyDetails.isSuffix ? currencyDetails.symbol : null}
            />
          </p>
        </a>
      );
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <div className="col-sm-12">
      <div className="row">
        <div className="col-sm-12">
          <Carousel
            {...settings}
            renderCenterLeftControls={({ previousSlide }) => (
              <ion-icon
                style={{ color: '#d80000', fontSize: '100px', marginLeft: '-15px' }}
                onClick={previousSlide}
                name="ios-arrow-back"
              />
            )}
            renderBottomCenterControls={() => <div />}
            renderCenterRightControls={({ nextSlide }) => (
              <ion-icon
                style={{ color: '#d80000', fontSize: '100px', marginLeft: '-15px' }}
                onClick={nextSlide}
                name="ios-arrow-forward"
              />
            )}
            className="slider-control-bottomcenter paging-item">
            {productsData.map(data => (
              <Row
                onClick={() => {
                  if (data.left_units >= 1) {
                    updateCart(data.id, 1);
                  }
                }}
                onKeyDown={() => {
                  if (data.left_units >= 1) {
                    updateCart(data.id, 1);
                  }
                }}
                role="menuitem"
                tabIndex="0"
                key={data.id}>
                {/* {// console.log(data)} */}
                {/* {// console.log('data')} */}
                <Col xs={12} sm={12} md={12} lg={12} className="text-center ">
                  <h1 className="text-center font-Muli font-weight-bold margin-bottom-0 color-brown">
                    {data.name.replace(/\b\w/g, l => {
                      return l.toUpperCase();
                    })}
                  </h1>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} className="text-center ">
                  <Row className="justify-content-center align-items-center">
                    <img
                      className="text-center small-machine-image-height"
                      alt={data.name.toUpperCase()}
                      src={data.image_mini ? data.image_mini : 'https://graphics.basics.com/img/fl/49049_fl.png'}
                    />
                  </Row>
                  <button type="button" className="wendor-big-button wendor-bg-green btn btn-default">
                    {' '}
                    Buy for
                    <NumberFormat
                      value={` ${data.price} `}
                      displayType="text"
                      thousandSeparator
                      prefix={currencyDetails.isSuffix ? null : currencyDetails.symbol}
                      suffix={currencyDetails.isSuffix ? currencyDetails.symbol : null}
                    />
                  </button>
                </Col>
              </Row>
            ))}
          </Carousel>
        </div>
      </div>
      {/* <Col xs={12} sm={12} md={12} lg={12} className="text-center"> */}
      {/* <h4 className="text-red font-Muli font-weight-bold margin-top-5 margin-bottom-0">Powered By</h4> */}
      {/* <img alt="Wendor" src="/public/logo.png" height="200" /> */}
      {/* </Col> */}
    </div>
  );
};
