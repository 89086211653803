import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'reactstrap';

const RefillDisplayBox = ({
  changeProduct,
  homeMotorTest,
  objectKey,
  product,
  updateProductQuantity,
  allowPriceChange,
  allowProductChange,
}) => {
  return (
    <div data-testid="refill-item-options" className="item-box" key={uuidv4()}>
      <div className="item-pos">
        <div className={`${product.changed ? 'bg-green' : 'bg-red'}`}>
          <span data-testid="refill-item-show_pos">{product.show_pos}</span>
        </div>
      </div>
      {allowProductChange && (
        <div className="refill-name div-2">
          <div className="refill-product-name">
            <h6 data-testid="refill-item-product-name">{product.name.substring(0, 20)}</h6>
          </div>
          <div className="change-product">
            <Button
              className="wendor-small-button"
              onClick={() => changeProduct(product, 'name')}
              data-testid="refill-item-change-name">
              Change
            </Button>
          </div>
        </div>
      )}
      {!allowProductChange && (
        <div className="refill-name justify-center">
          <div className="refill-product-name">
            <h5 data-testid="refill-item-product-name">{product.name.substring(0, 20)}</h5>
          </div>
        </div>
      )}
      {allowPriceChange && (
        <div className="refill-price div-2">
          <div className="price">
            ₹
            {parseFloat(product.price) === parseFloat(product.actual_price) ? (
              <span data-testid="refill-item-actual_price">{product.actual_price}</span>
            ) : (
              <span data-testid="refill-item-actual_price">
                <span className="cancel">{product.actual_price}</span> {product.price}
              </span>
            )}
          </div>
          <div className="change-price">
            <Button
              className="wendor-small-button"
              onClick={() => changeProduct(product, 'price')}
              data-testid="refill-item-change-price">
              Change
            </Button>
          </div>
        </div>
      )}
      {!allowPriceChange && (
        <div className="refill-price justify-center">
          <div className="price">
            ₹
            {parseFloat(product.price) === parseFloat(product.actual_price) ? (
              <span data-testid="refill-item-actual_price">{product.actual_price}</span>
            ) : (
              <span>
                <span className="cancel" data-testid="refill-item-actual_price">
                  {product.actual_price}
                </span>{' '}
                {product.price}
              </span>
            )}
          </div>
        </div>
      )}
      <div className="quantity">
        <div className="minus">
          <Button
            onClick={() => product.left_units > 0 && updateProductQuantity(objectKey, product.pos, -1)}
            className="wendor-small-button wendor-bg-red refill-qty-button"
            data-testid="refill-item-minus-button">
            -
          </Button>
        </div>
        <div className="quantity-left">
          <span data-testid="refill-item-quantity">
            {product.left_units}/{product.total_units}
          </span>
        </div>
        <div className="plus">
          <Button
            onClick={() => product.left_units < product.total_units && updateProductQuantity(objectKey, product.pos, 1)}
            className="wendor-small-button wendor-bg-green refill-qty-button"
            data-testid="refill-item-plus-button">
            +
          </Button>
        </div>
      </div>
      <div className="change-price">
        <Button
          className="wendor-flat-button-full"
          onClick={() => homeMotorTest(product.pos)}
          data-testid="refill-item-home_motor">
          Motor to home
        </Button>
      </div>
    </div>
  );
};

export default RefillDisplayBox;
