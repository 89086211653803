import React from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import './styleotp.css';

export default ({ onKeyPress, isNumberOnly }) => {
  // const defaultKeyboard = [
  //   '@easports.com',
  //   '1 2 3 4 &#8592;',
  //   '5 6 7 8 9 0',
  //   'q w e r t y u i o p',
  //   'a s d f g h j k l',
  //   'z x c v b n m . _ @',
  // ];
  const defaultKeyboard = [
    '0 1 2 3 4',
    '5 6 7 8 9',
    'q w e r t y u i o p',
    'a s d f g h j k l',
    '. _ z x c v b n m @ &#8592;',
  ];
  const onlyNumber = ['1 2 3', '4 5 6', '7 8 9', ' 0 &#8592;'];
  if (!isNumberOnly) {
    return (
      <div className="width-100-percent small-keys-email-otp">
        <Keyboard
          style={{ flex: 1, width: '100%', display: 'block', minHeight: '500px', fontSize: '30px' }}
          className="something"
          onKeyPress={button => onKeyPress(button)}
          layout={{
            default: isNumberOnly ? onlyNumber : defaultKeyboard,
          }}
        />
      </div>
    );
  }
  return (
    <div className="width-100-percent big-keys">
      <Keyboard
        height="85px"
        style={{ flex: 1, height: '85px', width: '100%', display: 'block', minHeight: '500px', fontSize: '30px' }}
        onKeyPress={button => onKeyPress(button)}
        layout={{
          default: isNumberOnly ? onlyNumber : defaultKeyboard,
        }}
      />
    </div>
  );
};
