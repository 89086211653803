import { PAYMENT_STATUS, REQUEST_REDUCER_TYPES } from '../../actions/constants';

const requestReducer = (state = {}, { type, payload }) => {
  const newState = Object.assign({}, state);
  switch (type) {
    case REQUEST_REDUCER_TYPES.SET_REQUEST_ID:
      return {
        ...state,
        totalAmountPaid: 0, // If setting requestId means new user is using
        totalAmountUpdated: 0, // Total Amount Updated to API
        requestId: payload, // Set Request Id
        paymentStatus: PAYMENT_STATUS.NOT_INITIATED,
        paymentMethodUsed: null,
      };
    case REQUEST_REDUCER_TYPES.RESET_REQUEST_ID:
      return {
        ...state,
        totalAmountPaid: 0, // If setting requestId means new user is using
        totalAmountUpdated: 0, // Total Amount Updated to API
        requestId: null, // Set Request Id
        paymentStatus: PAYMENT_STATUS.NOT_INITIATED,
        paymentMethodUsed: null,
      };
    case REQUEST_REDUCER_TYPES.UPDATE_TOTAL_AMOUNT:
      if (state.paymentStatus === PAYMENT_STATUS.COMPLETED) {
        return {
          ...state,
          totalAmountUpdated: newState.totalAmountUpdated + payload.addedAmount,
        }; // If Payment Status completed then totalAmountPaid === final amount
      }
      return {
        ...state,
        totalAmountPaid: newState.totalAmountPaid + payload.addedAmount,
        totalAmountUpdated: newState.totalAmountUpdated + payload.addedAmount,
      };
    case REQUEST_REDUCER_TYPES.FINAL_PAYMENT:
      return {
        ...state,
        paymentStatus: payload.status,
        totalAmountPaid: payload.totalAmountPaid,
        paymentMethodUsed: payload.method,
      };
    case REQUEST_REDUCER_TYPES.SET_REQUEST_ID_AFTER_VEND:
      return {
        ...state,
        requestIdForAfterVend: payload,
      };
    case REQUEST_REDUCER_TYPES.SET_IS_PAYMENT_CANCEL:
      return {
        ...state,
        isPaymentCancel: payload,
      };
    case REQUEST_REDUCER_TYPES.UNSET_REQUEST_ID_AFTER_VEND:
      return {
        ...state,
        requestIdForAfterVend: null,
      };
    default:
      return state;
  }
};

export default requestReducer;
