import React, { useState, useEffect } from 'react';
import './style.css';

const Timer = ({ maxTime }) => {
  const [timeLeft, settimeLeft] = useState(maxTime); // PAYMENT_TIME is in seconds

  useEffect(() => {
    // start Feedback timer
    const timer = window.setTimeout(() => {
      settimeLeft(timeLeft - 1);
    }, 1000); // Wait for 400 sec and then close the feedback modal
    return () => {
      // Stop Feedback Timer
      window.clearTimeout(timer);
    };
  }, [timeLeft]);

  return (
    <span style={{ color: 'black', marginLeft: '5px' }} className={timeLeft > maxTime / 3 ? '' : 'text-animation-size'}>
      {' '}
      {timeLeft} seconds
    </span>
  );
};

export default Timer;
