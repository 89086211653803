import React from 'react';
import { Row, Col } from 'reactstrap';
import uniqid from 'uniqid';
import Carousel from 'nuka-carousel';
import NumberFormat from 'react-number-format';

const cartDataItemsUI = (cartDataItem, paymentModalUIOptions, defaultProductURL, symbol, suffix) => {
  const { showImage, showDescription } = { ...paymentModalUIOptions.cartDetails.productDetails };
  const { showPrice } = paymentModalUIOptions;
  const productImage = cartDataItem.product_mini_image;

  const renderProductImage = () => {
    if (productImage) {
      return <img style={{ height: '200px', width: '200px' }} src={productImage} alt="product_image" />;
    }
    if (!productImage && defaultProductURL) {
      return <img style={{ height: '200px', width: '200px' }} src={defaultProductURL} alt="product_image" />;
    }
    return (
      <div className="no-image-container">
        <p>NO IMAGE</p>
      </div>
    );
  };

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="cart-image">
            {showImage && renderProductImage()}
          </Col>
        </Row>

        {showPrice && (
          <NumberFormat
            value={` ${cartDataItem.price} `}
            displayType="text"
            thousandSeparator
            prefix={suffix ? null : symbol}
            suffix={suffix ? symbol : null}
            className="cart-price"
          />
        )}
        <h3 style={{ marginBottom: '-3px', fontWeight: '800' }}>{cartDataItem.name}</h3>
        {showDescription && (
          <div style={{ marginTop: '10px' }}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <span style={{ fontSize: '20px', display: 'inline-block' }}>{cartDataItem.description || ''}</span>
              </Col>
            </Row>
          </div>
        )}
        <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />
      </Col>
    </Row>
  );
};

export default ({ cartData, paymentModalUIOptions, defaultProductURL }) => {
  const totalCards = cartData.length;
  const currencyDetails = JSON.parse(localStorage.getItem('cash'));

  const renderCards = cartData.map(item => (
    <Row key={uniqid()}>
      <Col xs={12} sm={12} md={12} lg={12} style={{ padding: '0px' }} key={uniqid()}>
        {cartDataItemsUI(
          item,
          paymentModalUIOptions,
          defaultProductURL,
          currencyDetails.symbol,
          currencyDetails.isSuffix,
        )}
      </Col>
    </Row>
  ));

  if (totalCards > 1) {
    const settings = {
      infinite: false,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      arrows: totalCards > 1,
      className: 'slides',
    };

    return (
      <Carousel
        {...settings}
        renderCenterLeftControls={({ previousSlide, currentSlide }) => {
          if (!settings.arrows || currentSlide === 0) {
            return '';
          }
          return (
            <ion-icon style={{ color: '#d80000', fontSize: '34px' }} onClick={previousSlide} name="ios-arrow-back" />
          );
        }}
        renderBottomCenterControls={() => <div />}
        renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) => {
          if (!settings.arrows || currentSlide + settings.slidesToShow >= slideCount) {
            return '';
          }
          return (
            <ion-icon style={{ color: '#d80000', fontSize: '34px' }} onClick={nextSlide} name="ios-arrow-forward" />
          );
        }}
        className="slider-control-bottomcenter paging-item">
        {renderCards}
      </Carousel>
    );
  }
  return <div>{renderCards}</div>;
};
