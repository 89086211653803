import axios from 'axios';

const authRfidTagId = async (id, requestId) => {
  const requestBody = {
    requestId,
    rfidData: id,
  };

  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/rfidData`;
    // console.log('Data sending ', requestBody);
    const response = await axios({
      url,
      method: 'post',
      data: requestBody,
    });

    return response.data;
  } catch (err) {
    // console.log('err.response.data', err);
    // console.log('err.response.data', err.response.data);
    return {
      error: true,
      ...err.response.data,
    };
  }
};

const authRfidPayment = async ({ requestId, rfidPin }) => {
  const requestBody = {
    request_id: requestId,
    pin: rfidPin,
  };

  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/authoriseRFIDPayment`;

    const response = await axios({
      url,
      method: 'post',
      data: requestBody,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export { authRfidTagId, authRfidPayment };
