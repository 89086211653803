import React from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './index.css';
import './assets/scss/main.scss';
import store from './store';
import withLanguage from './utils/language';
import Login from './containers/Login';
import Refill from './containers/Refill';
import SelectionPage from './containers/SelectionPageHeader';
import ScreenSaver from './containers/ScreenSaver';
import withTracker from './utils/withTracker';
import withAuth from './utils/withAuth';
import './assets/css/selectionPage.css';
// import { RefreshSendMail } from './actions/logs';

const RELEASE = '0.1.1'; // for virsion info please visit https://docs.google.com/document/d/1RhmYKj-heIDbYrsgy76lkDuX-1hFIYT-9VqN9wGrQbQ/edit?usp=drive_web&ouid=100428644453494579140
// RefreshSendMail();
if (localStorage.getItem('token')) {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
} else {
  delete axios.defaults.headers.common.Authorization;
}
if (process.env.REACT_APP_NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_RAVEN_DSN,
    release: RELEASE,
  });
} else {
  Sentry.init({
    dsn: process.env.REACT_APP_RAVEN_DSN,
    release: RELEASE,
  });
}

// store.subscribe(() => {
//
//
//   Sentry.configureScope(scope => {
//     scope.setUser({
//       machineId: localStorage.getItem('machineId'),
//       token: localStorage.getItem('token'),
//       reduxStore: store.getState(),
//     });
//   });
// });

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/login" component={compose(withTracker)(Login)} />
          <Route path="/refill" exact component={compose(withLanguage, withAuth, withTracker)(Refill)} />
          <Route path="/ScreenSaver" exact component={compose(withLanguage, withAuth, withTracker)(ScreenSaver)} />
          <Route path="/" component={compose(withLanguage, withAuth, withTracker)(SelectionPage)} />
        </Switch>
      </Router>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
