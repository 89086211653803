import React from 'react';
import QRCode from 'qrcode.react';

import { Row, Col, Button } from 'reactstrap';
import { ThreeBubbleLoader } from './Loader';

const QRDisplay = ({
  QRData,
  isQRLoading,
  isQRFetched,
  isQRFetchError,
  showRetry,
  retryButtonClickFunction,
  mainMsg,
  buttonTxt,
  showButton,
  buttonClickFunction,
}) => {
  return (
    <div>
      {isQRLoading && !isQRFetched && <ThreeBubbleLoader />}
      {showRetry && !isQRLoading && !isQRFetched && isQRFetchError && (
        <Button color="green" onClick={() => retryButtonClickFunction()}>
          Retry
        </Button>
      )}
      {isQRFetched && !isQRFetchError && (
        <div className="row content-center">
          <QRCode value={QRData.toString()} size={200} />
        </div>
      )}
      {mainMsg && (
        <Row>
          <span>{mainMsg}</span>
        </Row>
      )}
      {showButton && (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <span>
              <Button color="link" onClick={() => buttonClickFunction()}>
                {buttonTxt}
              </Button>
            </span>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default QRDisplay;
