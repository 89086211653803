import React from 'react';
import NumberFormat from 'react-number-format';
import { Button } from 'reactstrap';

import { CASH_MODULE_STATUS } from '../../actions/constants';
import { ThreeBubbleLoader } from '..';
import GetUserPhoneNumber from '../GetUserPhoneNumber';

export default ({
  symbol,
  suffix,
  cashModuleStatus,
  isPhoneNumberValid,
  handlePhoneNumberChange,
  sendNumberForCashToDatabase,
  phoneNumber,
  totalAmount,
  handleRetryButton,
  totalAmountPaid,
  toggleConformPaymentCancelModal,
  txtMainHeading,
  txtSubHeadingError,
  txtSubHeadingInfo,
  btnProceed,
  txtCashInitMainHeadingInfo,
  txtCashInitSuccessMainInfo,
  txtCashInitSuccessSubInfo,
  txtCashInitSubHeadingInfo,
  txtCashInitError,
  btnCashInitRetry,
  btnCashCancelAndExit,
}) => (
  <div>
    <div>
      {(cashModuleStatus === CASH_MODULE_STATUS.GETTING_NUMBER ||
        cashModuleStatus === CASH_MODULE_STATUS.ERROR_NUMBER_SAVED) && (
        <div>
          <GetUserPhoneNumber
            phoneNumber={phoneNumber}
            isPhoneNumberValid={isPhoneNumberValid}
            handlePhoneNumberChange={handlePhoneNumberChange}
            onButtonClick={sendNumberForCashToDatabase}
            mainHeading={txtMainHeading}
            subHeading={
              cashModuleStatus === CASH_MODULE_STATUS.ERROR_NUMBER_SAVED ? txtSubHeadingError : txtSubHeadingInfo
            }
            buttonText={btnProceed}
          />
        </div>
      )}
      {cashModuleStatus === CASH_MODULE_STATUS.INITIATING && (
        <div>
          <h3>{txtCashInitMainHeadingInfo}</h3>
          <h5>{txtCashInitSubHeadingInfo} </h5>
          <ThreeBubbleLoader />
        </div>
      )}
      {cashModuleStatus === CASH_MODULE_STATUS.BROKEN && (
        <div className="sorry-message">
          <ion-icon name="sad" />
          <h5>{txtCashInitError}</h5>
          <Button className="wendor-bg-green wendor-small-button " onClick={handleRetryButton}>
            {btnCashInitRetry}
          </Button>
        </div>
      )}
      {cashModuleStatus === CASH_MODULE_STATUS.LOADING && <ThreeBubbleLoader />}
    </div>
    {cashModuleStatus === CASH_MODULE_STATUS.ACCEPTING && (
      <>
        <div className="cashCardTotalParent">
          <h3 style={{ fontWeight: 800 }}>{txtCashInitSuccessMainInfo}</h3>
          <div className="cashCardTotal">
            <h6 className="txtCashInitSuccessSubInfo">{txtCashInitSuccessSubInfo}</h6>
            <h1 style={{ fontWeight: 1000 }}>
              <NumberFormat
                value={` ${totalAmount - totalAmountPaid} `}
                displayType="text"
                thousandSeparator
                prefix={suffix ? null : symbol}
                suffix={suffix ? symbol : null}
              />
            </h1>
          </div>
        </div>
        <div>
          <Button
            className="wendor-bg-red "
            disabled={parseInt(totalAmountPaid, 10) === 0}
            onClick={() => toggleConformPaymentCancelModal()}>
            {btnCashCancelAndExit}
          </Button>
        </div>
      </>
    )}
  </div>
);
