/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import React from 'react';
import { Button, Modal, ModalBody, Row, Col } from 'reactstrap';
import Select from 'react-select/creatable';
import NumberFormat from 'react-number-format';
// import uniqid from 'uniqid';

import { ReactKeyboard } from '..';

const UpdateProductNameUI = ({
  open,
  toggle,
  update,
  selectedProduct,
  changeType,
  options,
  isFetching,
  newProduct,
  price,
  searchQuery,
  maxPrice,
  keyboardRequired,
  isNumberOnly,
  openKeyboard,
  handleChange,
  handlePriceChange,
  handleSearchChange,
  onKeyboardInput,
  txtChangeProductDetails,
  txtSearchForProduct,
  txtMachinePrice,
  txtMoneySymbol,
  pHAmount,
  txtMrp,
  btnCancel,
  btnUpdate,
}) => {
  const currencyDetails = JSON.parse(localStorage.getItem('cash'));
  return (
    <Modal size="xl" isOpen={open} toggle={() => toggle()}>
      <ModalBody style={{ minHeight: '700px', display: 'flex', flexDirection: 'column' }}>
        <Row style={{ flex: 1 }}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <h5 data-testid="refill-change-product-model-heading">
              {txtChangeProductDetails} {selectedProduct.pos.toUpperCase()}
            </h5>
            <hr />
          </Col>
        </Row>
        {changeType === 'name' && (
          <Row style={{ flex: 1, width: '100%' }}>
            <Col xs={4} sm={4} md={4} lg={4}>
              <h5 data-testid="refill-change-product-model-input-label">{txtSearchForProduct}</h5>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8}>
              <Select
                defaultMenuIsOpen
                menuIsOpen
                options={options}
                isSearchable
                inputValue={searchQuery}
                onInputChange={handleSearchChange}
                onFocus={() => openKeyboard(false, 'product')}
                onChange={data => handleChange(data)}
                maxMenuHeight={100}
                isLoading={isFetching}
                className="selectable-input"
                styles={{ width: '100%', fontSize: '24px' }}
              />
            </Col>
          </Row>
        )}
        {changeType === 'price' && (
          <Row style={{ flex: 1, width: '100%' }}>
            <Col xs={4} sm={4} md={4} lg={4}>
              <h5>{txtMachinePrice}</h5>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} style={{ textAlign: 'left' }}>
              <label basic className="display-inline" style={{ fontSize: '24px' }}>
                {txtMoneySymbol}
              </label>
              <input
                readOnly
                data-testid="refill-update-price"
                labelPosition="left"
                type="number"
                style={{ padding: '10px', backgroundColor: '#eee', fontSize: '24px' }}
                placeholder={pHAmount}
                fluid
                name="price"
                min={1}
                max={maxPrice}
                value={price}
                onFocus={() => openKeyboard(true, 'price')}
                onChange={handlePriceChange}
              />

              <p className="font-weight-bold font-Muli">
                {txtMrp}{' '}
                <NumberFormat
                  value={` ${maxPrice} `}
                  displayType="text"
                  thousandSeparator
                  prefix={currencyDetails.isSuffix ? null : currencyDetails.symbol}
                  suffix={currencyDetails.isSuffix ? currencyDetails.symbol : null}
                />
              </p>
            </Col>
          </Row>
        )}
        <Row className="width-100-percent">
          <div className="width-100-percent">
            <div className={keyboardRequired ? 'keyboard_show' : 'keyboard_hide'}>
              {keyboardRequired && <ReactKeyboard onKeyPress={onKeyboardInput} isNumberOnly={isNumberOnly} />}
            </div>
          </div>
        </Row>
        <Row style={{ padding: '10px' }}>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Button
              data-testid="refill-btn-close"
              className="wendor-small-button wendor-big-button wendor-bg-red"
              onClick={() => toggle()}>
              {btnCancel}
            </Button>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Button
              data-testid="refill-btn-update"
              className="wendor-small-button wendor-big-button wendor-bg-green  "
              onClick={() => update(selectedProduct.pos, newProduct, price)}>
              {btnUpdate}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default UpdateProductNameUI;
