import React from 'react';
import { Row, Col, Button } from 'reactstrap';

import { LoaderInLine, WifiLoading } from '../index';

const ErrorUI = ({
  isConfiguring,
  isMQTTConnected,
  isESPConnected,
  showCancel,
  errorInComponent,
  checkESP,
  checkMQTT,
  isLoading,
  errorImageURL,
  isEspCheckSent,
  isConnectingMQTT,
  isErrorInProduct,
  goOffline,
  checkRefillRequest,
  isCheckingRefill,
  onRefresh,
  isResfreshing,
}) => {
  return (
    <div>
      {isConfiguring && (
        <div>
          <Row>
            <LoaderInLine />
          </Row>
          <Row>
            <Col xs={12}>
              <h5 data-testid="setting-machine">Syncing System Configuration</h5>
            </Col>
          </Row>
        </div>
      )}
      {!isConfiguring && (
        <div>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              {!isMQTTConnected && <WifiLoading />}
              {!isESPConnected && isMQTTConnected && (
                <img
                  data-testid="error-image"
                  className="image-height-400-width-300"
                  src={errorImageURL}
                  alt="error_image"
                />
              )}
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              {isErrorInProduct && <h5> Error in loading products.</h5>}
              {!isMQTTConnected && <h5>Connection is weak please check your Internet connection.</h5>}
              {showCancel && (
                <div>
                  <h5>Machine type not match.</h5>
                  <h5>Fallback to type big.</h5>
                </div>
              )}
              {errorInComponent && <h5>Oops... Something went wrong.</h5>}
              {isMQTTConnected && !isESPConnected && (
                <h5 data-testid="machine-down">Machine is down for maintainence.</h5>
              )}
            </Col>
          </Row>
          {isLoading && (
            <Row>
              <LoaderInLine />
            </Row>
          )}
          {!showCancel && (
            <div>
              <Row>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <Button
                    data-testid="error-modal-try-again"
                    className="wendor-small-button wendor-bg-green"
                    onClick={() => {
                      checkESP();
                      checkMQTT();
                    }}
                    disabled={isConnectingMQTT || isEspCheckSent}>
                    Try Again
                  </Button>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <Button
                    data-testid="error-modal-go-offline"
                    className="wendor-small-button wendor-bg-red"
                    onClick={() => goOffline()}>
                    Go Offline
                  </Button>
                </Col>
              </Row>
              <Row className="error-buttons">
                <Col xs={6} sm={6} md={6} lg={6}>
                  <Button
                    data-testid="error-modal-check-refill"
                    onClick={checkRefillRequest}
                    disabled={isCheckingRefill}
                    className="wendor-small-button wendor-bg-grey">
                    Check for refill
                  </Button>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <Button
                    data-testid="error-modal-refresh-app"
                    onClick={() => onRefresh()}
                    disabled={isResfreshing}
                    className="wendor-small-button wendor-bg-grey">
                    Refresh app
                  </Button>
                </Col>
              </Row>

              {/* <Row className="error-buttons">
								<Col xs={6} sm={6} md={12} lg={12}>
									<span
										role="img"
                    style={{ position: 'absolute', bottom: '10px', right: '20px' }}
                    onKeyDown={()=>// console.log("SOMETHING")}
										aria-label="setting"
										data-testid="error-modal-check-refill"
										onClick={openServiceMode}
									> */}
              {/* ⚙️  Open Service mode */}
              {/* </span>
								</Col>
							</Row> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ErrorUI;
