import React from 'react';
import { Row, Col } from 'reactstrap';
import NumberFormat from 'react-number-format';

const VendingCardDetailsNewUI = ({ itemsToVend, vendedItems, vendModalUIOptions }) => {
  let key = 0;
  const currencyDetails = JSON.parse(localStorage.getItem('cash'));

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Row>
          {itemsToVend.length > 0 &&
            itemsToVend.map((item, index) => {
              key += 1;
              let colClasses = 'vending-item-card vend-item-new ';
              if (vendedItems.length > index) {
                if (vendedItems[index].isSuccess) {
                  colClasses += 'vend-success-new ';
                } else {
                  colClasses += 'vend-failed-new ';
                }
              } else if (vendedItems.length < index) {
                colClasses += 'vend-loading-new ';
              } else {
                colClasses += 'vending-item-new ';
              }
              return (
                <Col key={key} xs={4} sm={4} md={4} lg={4}>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} className={colClasses}>
                      {vendModalUIOptions.vendingItemCardDetails.showImage && (
                        <img
                          className="vending-item-card-image"
                          src={item.image_mini || '/logo.png'}
                          alt={item.product_name}
                        />
                      )}
                      {vendModalUIOptions.vendingItemCardDetails.showName && (
                        <h5>{item.product_name ? item.product_name.substring(0, 15) : ''}</h5>
                      )}
                      {vendModalUIOptions.vendingItemCardDetails.showPosition && (
                        <h3 style={{ fontWeight: 800 }}>{item && item.show_pos}</h3>
                      )}
                      {vendModalUIOptions.vendingItemCardDetails.showPrice && (
                        <h4 style={{ color: '#333', fontWeight: 'bold' }}>
                          {item && (
                            <NumberFormat
                              value={item.price}
                              displayType="text"
                              thousandSeparator
                              prefix={currencyDetails.isSuffix ? null : currencyDetails.symbol}
                              suffix={currencyDetails.isSuffix ? currencyDetails.symbol : null}
                            />
                          )}
                        </h4>
                      )}
                      {vendedItems.length > index &&
                        vendedItems[index].isSuccess &&
                        vendedItems[index].itemId === item.bill_item_id && <span>Success!</span>}
                      {vendedItems.length > index && !vendedItems[index].isSuccess && <span>Failed!</span>}
                      {vendedItems.length <= index && <span>In Queue</span>}
                    </Col>
                  </Row>
                </Col>
              );
            })}
        </Row>
      </Col>
    </Row>
  );
};

export default VendingCardDetailsNewUI;
