/*
  Top heading 
  manualReload the full page
*/

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { connect } from 'react-redux';
import TopHeadUsingImage from './TopHeadUsingImage';
import TopHeadWithCarousel from './TopHeadWithCarousel';
import TopHeadLockerAnimation from './TopHeadLockerAnimation';

const SelectionPageHeaderTop = ({
  topHeadImageURL,
  topHeadImageSize,
  showTopHeadText,
  hideTopHeadImage,
  machineStatus,
  // eslint-disable-next-line no-unused-vars
  machineBalance,
  headingTitle,
  txtMachineOffline,
  buttonCheckForInternet,
  txtBalanceInfo,
  useCarouselHeader,
  customHeaderVariant,
}) => {
  const style = {
    height: '40px',
  };
  if (topHeadImageSize === 'lg') {
    style.height = '150px';
  }

  //     const machineType = localStorage.getItem('machineType')

  return (
    <>
      {!useCarouselHeader && customHeaderVariant === 'default' && (
        <TopHeadUsingImage
          topHeadImageURL={topHeadImageURL}
          topHeadImageSize={topHeadImageSize}
          machineStatus={machineStatus}
          showTopHeadText={showTopHeadText}
          hideTopHeadImage={hideTopHeadImage}
          headingTitle={headingTitle}
          txtMachineOffline={txtMachineOffline}
          buttonCheckForInternet={buttonCheckForInternet}
          txtBalanceInfo={txtBalanceInfo}
        />
      )}
      {useCarouselHeader && !customHeaderVariant && (
        <TopHeadWithCarousel
          topHeadImageURL={topHeadImageURL}
          topHeadImageSize={topHeadImageSize}
          machineStatus={machineStatus}
          showTopHeadText={showTopHeadText}
          hideTopHeadImage={hideTopHeadImage}
          headingTitle={headingTitle}
          txtMachineOffline={txtMachineOffline}
          buttonCheckForInternet={buttonCheckForInternet}
          txtBalanceInfo={txtBalanceInfo}
        />
      )}
      {customHeaderVariant === 'atlaswithlocker' && (
        <TopHeadLockerAnimation
          topHeadImageURL={topHeadImageURL}
          topHeadImageSize={topHeadImageSize}
          machineStatus={machineStatus}
          showTopHeadText={showTopHeadText}
          hideTopHeadImage={hideTopHeadImage}
          headingTitle={headingTitle}
          txtMachineOffline={txtMachineOffline}
          buttonCheckForInternet={buttonCheckForInternet}
          txtBalanceInfo={txtBalanceInfo}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ machine }) => {
  return {
    machineBalance: machine.machineBalance,
  };
};

export default connect(mapStateToProps)(SelectionPageHeaderTop);
