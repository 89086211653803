import axios from 'axios';

export const disablingEzetapModule = async requestData => {
  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/disableEzetapModule`;

    const response = await axios({
      url,
      method: 'post',
      data: requestData,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};
