import { combineReducers } from 'redux';
// import { reducer as form } from 'redux-form';
import machine from './machine';
import modalReducer from './modals';
import cartReducer from './cart';
import requestReducer from './request';
import cashReducer from './cash';
import currencyReducer from './currency_module';
import vendReducer from './vend';
import log from './log';
import rfidModule from './rfid';
import languageReducer from './language';

const Reducer = combineReducers({
  machine,
  modalReducer,
  cartReducer,
  requestReducer,
  log,
  rfidModule,
  cashReducer,
  vendReducer,
  languageReducer,
  currencyReducer,
});

export default Reducer;
