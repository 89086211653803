import React from 'react';
import { withRouter } from 'react-router-dom';
import { checkIfServiceModeIsOn } from '../../actions/machine';
import RefillLoginUI from '../../components/Refill/login';

class RefillLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allFields: [
        { name: 'Enter refiller Id', type: 'text', 'data-testid': 'refill-login-input-uid', value: '' },
        { name: 'Enter pin', type: 'password', 'data-testid': 'refill-login-input-password', value: '' },
      ],
      isError: false,
      errorMsg: false,
      keyboardRequired: false,
      activeFieldIndex: 0,
      isCapsOn: false,
      isShiftOn: false,
    };
  }

  onKeyboardInput = button => {
    const { activeFieldIndex, allFields, isShiftOn, isCapsOn } = { ...this.state };
    if (button === '&#8592;') {
      allFields[activeFieldIndex].value = allFields[activeFieldIndex].value.substring(
        0,
        allFields[activeFieldIndex].value.length - 1,
      );
    } else if (button === '{enter}') {
      return;
    } else if (button === '{tab}') {
      if (activeFieldIndex + 1 > allFields.length) {
        this.closeKeyboard();
      } else {
        this.setState({ activeFieldIndex: activeFieldIndex + 1 });
      }
    } else if (button === '{lock}') {
      this.setState({ isCapsOn: !isCapsOn });
    } else if (button === '{shift}') {
      this.setState({ isShiftOn: !isShiftOn });
    } else if (button === '{space}') {
      allFields[activeFieldIndex].value += ' ';
    } else {
      allFields[activeFieldIndex].value += button;
    }

    this.setState({ allFields });
  };

  onInput = e => {
    if (e.key === 'Enter') {
      return;
    }
    if (e.keyCode === 8 || e.keyCode === 46) {
      this.onKeyboardInput('&#8592;');
    } else {
      const inp = e.key;
      if (/[-a-zA-Z0-9-_ @!#$%^&*()<>.?|\\/'":;~`+=}{[\]]/.test(inp) && inp.length === 1) {
        // Accept given inputs and 1 char at a time
        this.onKeyboardInput(e.key);
      }
    }
  };

  closeKeyboard = () => {
    this.setState({ keyboardRequired: false });
  };

  openKeyboard = fieldIndex => {
    this.setState({ activeFieldIndex: fieldIndex, keyboardRequired: true });
  };

  handleSubmit = () => {
    const { allFields } = { ...this.state };
    const { login } = this.props;
    const userId = allFields[0].value;
    const pin = allFields[1].value;
    login(userId, pin);
  };

  openServiceMode = async () => {
    try {
      const { error } = await checkIfServiceModeIsOn();
      if (!error) {
        window.location.href = process.env.REACT_APP_SERVICE_MODE_URL;

        this.setState({
          isError: false,
          errorMsg: '',
        });
      } else {
        this.setState({
          isError: true,
          errorMsg: 'Service mode app is not runnning',
        });
        // console.log('ERROR IN OPENING SERVICE MODE');
      }
    } catch (e) {
      this.setState({
        isError: true,
        errorMsg: 'Service mode app is not runnning',
      });
      // console.log('EXCEPTION IN OPENING SERVICE MODE');
    }
  };

  render() {
    const { keyboardRequired, allFields, activeFieldIndex, isError, errorMsg } = { ...this.state };
    const { loginFailed, logout, refillLoginFailedReason, isSubmitting, refillPageLoginURL } = { ...this.props };
    return (
      <RefillLoginUI
        keyboardRequired={keyboardRequired}
        allFields={allFields}
        isError={isError}
        errorMsg={errorMsg}
        activeFieldIndex={activeFieldIndex}
        loginFailed={loginFailed}
        logout={logout}
        refillLoginFailedReason={refillLoginFailedReason}
        isSubmitting={isSubmitting}
        refillPageLoginURL={refillPageLoginURL}
        handleSubmit={this.handleSubmit}
        onKeyboardInput={this.onKeyboardInput}
        openKeyboard={this.openKeyboard}
        openServiceMode={this.openServiceMode}
      />
    );
  }
}

export default withRouter(RefillLogin);
