import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap';
import NumberFormat from 'react-number-format';

export default ({
  isOpen,
  close,
  cancelPayment,
  paymentModeSelected,
  totalAmountPaid,
  phoneNumber,
  modalSize,

  txtCancelOrderConfirm,
  txtBalanceTransferToSwift,
  btnCancelConfirmNo,
  btnCancelConfirmYes,
}) => {
  const [className, setClassName] = useState('');
  const [pressedButton, setPressedButton] = useState('');

  const machinetype = localStorage.getItem('machineType');

  useEffect(() => {
    const timer = setTimeout(() => {
      setClassName('');
    }, 155);

    return () => {
      clearTimeout(timer);
    };
  }, [className]);

  const handleClick = button => {
    setPressedButton(button);
    setClassName('touch-animation-small ');
  };

  const currencyDetails = JSON.parse(localStorage.getItem('cash'));
  return (
    <Modal isOpen={isOpen} toggle={() => close()} size={modalSize || 'md'}>
      <ModalHeader>{txtCancelOrderConfirm}</ModalHeader>
      <ModalBody>
        {paymentModeSelected === 'cash' && totalAmountPaid > 0 && (
          <div>
            {' '}
            <NumberFormat
              value={` ${totalAmountPaid} `}
              displayType="text"
              thousandSeparator
              prefix={currencyDetails.isSuffix ? null : currencyDetails.symbol}
              suffix={currencyDetails.isSuffix ? currencyDetails.symbol : null}
            />
            {txtBalanceTransferToSwift} {phoneNumber}.
          </div>
        )}
        {paymentModeSelected === 'offlinecash' && totalAmountPaid > 0 && (
          <div>
            <p>
              <NumberFormat
                value={` ${totalAmountPaid} `}
                displayType="text"
                thousandSeparator
                prefix={currencyDetails.isSuffix ? null : currencyDetails.symbol}
                suffix={currencyDetails.isSuffix ? currencyDetails.symbol : null}
              />{' '}
              will be transfered to {phoneNumber}
              {"'s"} swift account upon connecting to Internet.
            </p>
            <p>You will receive the message regarding the same.</p>
          </div>
        )}
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Button
              onTouchEnd={() => handleClick('no')}
              className={
                machinetype !== 'big'
                  ? `${
                      pressedButton === 'no' ? className : ''
                    } wendor-bg-green new-3d-button new-red-submit-btn cancel-btn-2`
                  : 'wendor-small-button wendor-big-button   wendor-bg-green'
              }
              onClick={() => close()}>
              {btnCancelConfirmNo}
            </Button>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <Button
              onTouchEnd={() => handleClick('yes')}
              className={
                machinetype !== 'big'
                  ? `${
                      pressedButton === 'yes' ? className : ''
                    } wendor-bg-red new-3d-button new-red-submit-btn cancel-btn`
                  : 'wendor-small-button wendor-big-button   wendor-bg-red'
              }
              onClick={() => {
                cancelPayment();
              }}>
              {btnCancelConfirmYes}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
