import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { SMS_SENT_STATUS } from '../../actions/constants';
import { ThreeBubbleLoader } from '..';
import GetUserPhoneNumber from '../GetUserPhoneNumber';

export default ({
  phoneNumber,
  handlePhoneNumberChange,
  isPhoneNumberValid,
  changePaymentMethod,
  smsSentStatus,
  getSmsLinkStatus,
  txtEnterPhoneNumber,
  txtPostEnterPhoneNumber,
  txtSmsSentLoading,
  txtPostSmsSent,
  txtErrorMsg,
  btnOnPhonenumberEnter,
  btnChangeNumber,
}) => (
  <Row className="content-center ">
    <Col xs={12} sm={12} md={12} lg={12}>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <h5>
            {smsSentStatus !== SMS_SENT_STATUS.COMPLETED && smsSentStatus !== SMS_SENT_STATUS.INITIATED
              ? txtEnterPhoneNumber
              : `${txtPostEnterPhoneNumber} ${phoneNumber}`}
            <br />
            {/* <NumberFormat value={totalAmount} displayType="text" thousandSeparator  /> */}
          </h5>
        </Col>
      </Row>
      {smsSentStatus === SMS_SENT_STATUS.INITIATED && (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <h5>
              {txtSmsSentLoading}
              <br />
            </h5>
            <ThreeBubbleLoader />
          </Col>
        </Row>
      )}
      {smsSentStatus === SMS_SENT_STATUS.COMPLETED && (
        <div>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <img src="/images/drawing_sms_payment.svg" alt="Wendor" className="image-height-400-width-300" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <p>{txtPostSmsSent}</p>
            </Col>
          </Row>
        </div>
      )}
      {smsSentStatus === SMS_SENT_STATUS.FAILED && (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <p>{txtErrorMsg}</p>
          </Col>
        </Row>
      )}
      {smsSentStatus !== SMS_SENT_STATUS.COMPLETED && smsSentStatus !== SMS_SENT_STATUS.INITIATED && (
        <GetUserPhoneNumber
          isPhoneNumberValid={isPhoneNumberValid}
          handlePhoneNumberChange={handlePhoneNumberChange}
          phoneNumber={phoneNumber}
          onButtonClick={getSmsLinkStatus}
          buttonText={btnOnPhonenumberEnter}
        />
      )}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          {smsSentStatus === SMS_SENT_STATUS.COMPLETED && (
            <Button
              className="wendor-bg-red wendor-small-button"
              disabled={!isPhoneNumberValid || smsSentStatus === SMS_SENT_STATUS.INITIATED}
              onClick={() => changePaymentMethod('link')}>
              {btnChangeNumber}
            </Button>
          )}
        </Col>
      </Row>
    </Col>
  </Row>
);
