import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Alert, Button, Modal, ModalBody } from 'reactstrap';
import NumberFormat from 'react-number-format';
// import openSocket from 'socket.io-client';

import { updateMachine, openSetting, refillLogin, refillerValidate, homeMotor } from '../../actions/refill';
import ProductList from './ProductList';
import { getMachineData, closeMachine } from '../../actions/machine';
import UpdateProductNameModal from './UpdateProductNameModal';
import RefillLogin from './RefillLogin';
import './styles.css';
import { LoaderInLine as Loader } from '../../components';
import { STATE_OF_MACHINE } from '../../actions/constants';
import LogoutButton from '../logout';
import SettingsModal from './settingsModel';
import RefillHeaderButtons from './headerButtons';
import './style.css';

const { SOCKET_EVENTS } = { ...STATE_OF_MACHINE.WHEN_IN_PAYMENT_PAGE };

class Refill extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpeningSettings: false,
      isRefiller: false, // True->refill login successful
      isSubmitting: false,
      refillLoginFailedReason: '',
      allowPriceChange: false,
      allowProductChange: false,
      refillLoginFailed: false, // if True-> show error message
      products: [], // Products of machine
      isProductsLoading: false, // True-> fetching products
      openNameModal: false, // change Product-> open Product Modal
      error: false, // Error Fetching machine data
      updated: false, // Update successful -> true
      isUpdating: false, // Updated the products
      errorUpdating: false, // Error while updating the products
      selectedProduct: null, // Product in focus for which the name or price to be changed
      refilledProducts: [], // Changes while refilling
      exit: false, // If true, then exit the refill mode in hideDimmer function.
      isErrorInProduct: false,
      isCloseMachineError: false,
      exitButtonLoading: false,
      saveButtonLoading: false,
      saveExitButtonLoading: false,
      showKeyboardModal: false,
      keyboardInput: '',
      isValidKeyBoardInput: '',
      isSocketConnected: false,
      openSettingModal: false,
    };

    this.refillLogin = this.refillLogin.bind(this); // Refill Login
    this.toggleNameModal = this.toggleNameModal.bind(this); // Open Product Change modal
    this.getMachineDetails = this.getMachineDetails.bind(this); // Get Machine products
    this.updateProductQuantity = this.updateProductQuantity.bind(this); // Update Products Quantity of a position
    this.updateProductName = this.updateProductName.bind(this); // Update Product of a position
    this.updateMachineProducts = this.updateMachineProducts.bind(this); // Refill push
    this.logout = this.logout.bind(this); // logout
    this.changeProduct = this.changeProduct.bind(this); // put product in focus which is to be changed
    this.hideDimmer = this.hideDimmer.bind(this); // CLose the `check` dimmer
    this.closeModalKeyboard = this.closeModalKeyboard.bind(this); // CLose the `check` dimmer
    this.toggleSettingModal = this.toggleSettingModal.bind(this);
  }

  componentDidMount() {
    window.heap.track('on Refill page', {
      time: Date.now(),
    });

    const refillerToken = window.localStorage.getItem('refillerToken');
    if (refillerToken) {
      refillerValidate(refillerToken).then(data => {
        if (!data.error) {
          this.setState({
            isRefiller: true,
            isSubmitting: false,
            isProductsLoading: true,
            refillLoginFailed: false,
            refillLoginFailedReason: '',
            refillerToken,
            allowPriceChange: data.allowPriceChange,
            allowProductChange: data.allowProductChange,
          });
          this.getMachineDetails();
        }
      });
    }
    const { refillPageUIOptions } = { ...this.props };
    document.body.style.backgroundColor = refillPageUIOptions.backgroundColor;
    if (refillPageUIOptions.backgroundImage !== '') {
      document.body.style.background = `#999 url(/assets/${refillPageUIOptions.backgroundImage}) no-repeat fixed 50%`;
      document.body.style.backgroundSize = 'cover';
    }
  }

  componentWillUnmount() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  getMachineDetails() {
    // const tempObj = {};
    this.setState({ isProductsLoading: true });
    getMachineData().then(async response => {
      // Get machine data
      if (!response.error) {
        const newProductsData = response.data.reduce((acc, obj) => {
          let key;
          if (!obj.pos) {
            return acc;
          }
          if (obj.row) {
            key = obj.row;
          } else {
            key = obj.pos.substring(0, 1).toUpperCase();
          }
          if (!(key in acc)) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});

        await this.setState({
          products: newProductsData,
          error: false,
          isProductsLoading: false,
          isErrorInProduct: false,
        });
      } else {
        this.setState({
          products: [],
          error: true,
          isErrorInProduct: true,
          isProductsLoading: false,
        });
      }
    });
  }

  openSettings = () => {
    // const { machineType } = {...this.props}
    // when we make spl machine type for keypad based system
    // console.log('OPENING SETTINGS');
    this.setState({
      isOpeningSettings: true,
      showKeyboardModal: true,
      isValidKeyBoardInput: true,
    });
    openSetting().then(() => {
      this.setState({
        isOpeningSettings: false,
      });
    });
  };

  homeMotorTest = async pos => {
    const posArray = [];
    // console.log('homeMotorTest', pos);
    posArray.push(pos);
    try {
      homeMotor(posArray);
      // console.log('response', response);
    } catch (e) {
      // console.log('GOt error in api', e);
    }
  };

  sendDataToSocket(data) {
    // console.log('SENDING DATA');
    this.socket.emit(SOCKET_EVENTS.KEYPAD_KEYPRESS, data);
  }

  async hideDimmer() {
    const { exit } = { ...this.state };
    this.setState({
      isUpdating: false,
      errorUpdating: false,
    });
    if (exit) {
      const res = await closeMachine();
      if (res) {
        this.setState({
          updated: false,
          isCloseMachineError: false,
        });
        this.logout();
      } else {
        this.setState({
          updated: true,
          isCloseMachineError: true,
        });
      }
    } else if (!exit) {
      this.setState({
        updated: false,
        isCloseMachineError: false,
      });
    }
  }

  changeProduct(product, type) {
    window.heap.track('change product', {
      product,
      time: Date.now(),
    });
    this.setState({
      selectedProduct: product,
      openNameModal: true,
      changeType: type,
    });
  }

  refillLogin(userId, pin) {
    this.setState({
      isSubmitting: true,
    });
    refillLogin(userId, pin).then(data => {
      if (data.error) {
        this.setState({
          isRefiller: false,
          isSubmitting: false,
          isProductsLoading: false,
          refillLoginFailed: true,
          refillLoginFailedReason: data.message,
          refillerToken: null,
          allowPriceChange: false,
          allowProductChange: false,
        });
      } else {
        this.setState({
          isRefiller: true,
          isSubmitting: false,
          isProductsLoading: true,
          refillLoginFailed: false,
          refillLoginFailedReason: '',
          refillerToken: data.refillerToken,
          allowPriceChange: data.allowPriceChange,
          allowProductChange: data.allowProductChange,
        });
        window.localStorage.setItem('refillerToken', data.refillerToken.access_token);
        this.getMachineDetails();
      }
    });
  }

  updateProductQuantity(objectKey, pos, updateBy) {
    // Change Quantity on given position
    const { products, refilledProducts } = { ...this.state };
    window.heap.track('Update product quantity', {
      objectKey,
      pos,
      updateBy,
      time: Date.now(),
    });
    const updatedProducts = []; // Products array containing updated product
    products[objectKey].map(product => {
      const newProduct = product;
      if (product.pos === pos) {
        newProduct.left_units += updateBy;

        const refillIndex = (refilledProducts || []).findIndex(item => item.pos === pos);
        if (refillIndex > -1) {
          const rProducts = refilledProducts;
          rProducts[refillIndex].left_units = newProduct.left_units;
          this.setState({ refilledProducts: rProducts });
        } else {
          const rProducts = refilledProducts;
          rProducts.push({
            pos,
            left_units: newProduct.left_units,
          });
          this.setState({ refilledProducts: rProducts });
        }
      }
      return updatedProducts.push(newProduct);
    });
    this.setState({
      products: Object.assign({}, products, { [objectKey]: updatedProducts }),
    });
  }

  updateProductName(pos, updateProduct, price) {
    // Change Product at given position
    // console.log('i am called ');
    // console.log('pos', pos);
    // console.log('updateProduct', updateProduct);
    // console.log('price', price);
    const { products, refilledProducts } = { ...this.state };
    window.heap.track('Update product name', {
      pos,
      updateProduct,
      price,
      time: Date.now(),
    });
    const newProducts = [];
    products[pos.substring(0, 1)].map(product => {
      if (product.pos === pos) {
        const newProduct = product;
        if (updateProduct) {
          newProduct.product_id = updateProduct.id;
          newProduct.name = updateProduct.name;
          newProduct.actual_price = updateProduct.price;
          newProduct.image = updateProduct.image;
          newProduct.image_mini = updateProduct.image_mini || updateProduct.image;
          newProduct.changed = true;
        }
        if (price) {
          newProduct.price = price;
          newProduct.changed = true;
        }

        const refillIndex = (refilledProducts || []).findIndex(item => item.pos === pos);
        if (refillIndex > -1) {
          const rProducts = refilledProducts;
          rProducts[refillIndex].product_id = newProduct.product_id;
          rProducts[refillIndex].new_price = newProduct.price;
          this.setState({ refilledProducts: rProducts });
        } else {
          const rProducts = refilledProducts;
          rProducts.push({
            pos,
            product_id: newProduct.product_id,
            left_units: newProduct.left_units,
            new_price: newProduct.price,
          });
          this.setState({ refilledProducts: rProducts });
        }

        return newProducts.push(newProduct);
      }
      return newProducts.push(product);
    });
    products[pos.substring(0, 1)] = newProducts;

    return this.setState({
      openNameModal: false,
      products,
    });
  }

  updateMachineProducts(totalAmount, exit) {
    // Update Machine's Products on server. Refill
    // Here totalAmount is total amount of products in the machine when refill is done.

    const { refilledProducts, refillerToken } = { ...this.state };
    if (!exit) {
      this.setState({ saveButtonLoading: true });
    } else {
      this.setState({ saveExitButtonLoading: true });
    }
    this.setState({
      isUpdating: true,
    });
    updateMachine({ totalAmount, refilledProducts, refillerToken }).then(data => {
      this.setState({
        saveButtonLoading: false,
        saveExitButtonLoading: false,
      });
      if (data && !data.error) {
        this.setState({
          updated: true,
          refilledProducts: [],
          isUpdating: false,
          errorUpdating: false,
          exit,
        });
        this.getMachineDetails();
      } else {
        this.setState({
          updated: false,
          isUpdating: false,
          errorUpdating: true,
          exit,
        });
      }
    });
  }

  closeModalKeyboard() {
    this.setState({
      keyboardInput: '',
      isValidKeyBoardInput: false,
      showKeyboardModal: false,
    });
  }

  toggleNameModal() {
    // toggle Product Change Modal
    this.setState({
      openNameModal: false,
    });
  }

  logout() {
    const { history } = { ...this.props };
    this.setState({ exitButtonLoading: true });
    window.localStorage.removeItem('refillerToken');
    closeMachine();
    this.setState({ exitButtonLoading: false });
    history.push('/');
  }

  toggleSettingModal() {
    const { openSettingModal } = { ...this.state };
    // console.log('HERE', openSettingModal);
    this.setState({
      openSettingModal: !openSettingModal,
    });
  }

  render() {
    const currencyDetails = JSON.parse(localStorage.getItem('cash'));

    const {
      isRefiller,
      isSubmitting,
      isProductsLoading,
      products,
      isUpdating,
      updated,
      openNameModal,
      selectedProduct,
      changeType,
      refillLoginFailed,
      refillLoginFailedReason,
      allowProductChange,
      allowPriceChange,
      isErrorInProduct,
      errorUpdating,
      exitButtonLoading,
      saveButtonLoading,
      saveExitButtonLoading,
      isCloseMachineError,
      isOpeningSettings,
      openSettingModal,
    } = { ...this.state };

    const { refillPageUIOptions, modalSize, refillPageLanguage } = { ...this.props };
    const {
      btnRetry,
      // btnExit,
      btnOk,
      // txtUnableToConnect,
      txtSuccessUpdateInfo,
      txtExitRefillModeError,
      alertFailToUpdate,
      txtRefillSummaryTitle,
      txtTotalAmount,
      txtServiceMode,
      btnRefillModeExit,
      btnRefillModeRefresh,
      btnRefillModeSave,
      btnRefillModeSaveExit,
      btnRefillModeAllMotorsToHome,
    } = refillPageLanguage;

    let rowKey = 0;
    let totalAmount = 0;
    Object.keys(products).forEach(key => {
      products[key].forEach(product => {
        totalAmount += parseFloat(product.price) * parseInt(product.left_units, 10);
      });
    });

    if (isRefiller) {
      // show refill page
      return (
        <div className="login-page">
          {openNameModal && (
            <UpdateProductNameModal
              open={openNameModal}
              modalSize={modalSize}
              selectedProduct={selectedProduct}
              changeType={changeType}
              toggle={this.toggleNameModal}
              update={this.updateProductName}
            />
          )}
          {openSettingModal && (
            <SettingsModal
              isOpen={openSettingModal}
              modalSize={modalSize}
              selectedProduct={selectedProduct}
              changeType={changeType}
              toggleSettingModal={this.toggleSettingModal}
            />
          )}
          {/* {showKeyboardModal && (
            <Modal size="xl" isOpen={showKeyboardModal} toggle={() => this.hideDimmer()}>
              <ModalBody style={{ display: 'flex', flexDirection: 'column' }}>
                <Row>
                  
                </Row>
              </ModalBody>
            </Modal>
          )} */}
          {updated && (
            <Modal size="xl" isOpen={updated} toggle={() => this.hideDimmer()}>
              <ModalBody style={{ display: 'flex', flexDirection: 'column' }}>
                <Row>
                  {!isCloseMachineError && (
                    <div>
                      <img alt="emotions" src="/assets/check.png" />
                      <h5>{txtSuccessUpdateInfo}</h5>
                      <Button
                        data-testid="refill-updated-successfully"
                        className="wendor-big-button wendor-bg-green"
                        onClick={() => this.hideDimmer()}>
                        {btnOk}
                      </Button>
                    </div>
                  )}
                  {isCloseMachineError && (
                    <div>
                      <img alt="emotions" src="/assets/sad.svg" />
                      <h5>{txtExitRefillModeError}</h5>
                      <Button className="wendor-big-button wendor-bg-green" onClick={() => this.hideDimmer()}>
                        {btnRetry}
                      </Button>
                    </div>
                  )}
                </Row>
              </ModalBody>
            </Modal>
          )}
          {isProductsLoading && <Loader />}
          {errorUpdating && <Alert color="warning">{alertFailToUpdate}</Alert>}
          {!isProductsLoading && (
            <div style={{ height: '90vh', overflow: 'scroll' }}>
              <Row>
                <Col xs={12} sm={12} lg={12} md={12} className="text-center">
                  <h3 data-testid="refill-page-title" className="text-red">
                    {txtRefillSummaryTitle}
                  </h3>
                  <h5>
                    {txtTotalAmount}{' '}
                    <NumberFormat
                      value={` ${totalAmount} `}
                      displayType="text"
                      data-testid="refill-updated-amount"
                      thousandSeparator
                      prefix={currencyDetails.isSuffix ? null : currencyDetails.symbol}
                      suffix={currencyDetails.isSuffix ? currencyDetails.symbol : null}
                    />
                  </h5>
                  {refillPageUIOptions.showOpenSettingsButton && (
                    <div className="open-settings">
                      <Button
                        disabled={isOpeningSettings}
                        className="wendor-small-button wendor-bg-grey"
                        onClick={() => this.openSettings()}>
                        {txtServiceMode}
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
              {!isErrorInProduct && (
                <RefillHeaderButtons
                  logout={this.logout}
                  exitButtonLoading={exitButtonLoading}
                  btnRefillModeExit={btnRefillModeExit}
                  saveButtonLoading={saveButtonLoading}
                  totalAmount={totalAmount}
                  btnRefillModeSave={btnRefillModeSave}
                  saveExitButtonLoading={saveExitButtonLoading}
                  btnRefillModeSaveExit={btnRefillModeSaveExit}
                  updateMachineProducts={this.updateMachineProducts}
                  homeMotorTest={this.homeMotorTest}
                  toggleSettingModal={this.toggleSettingModal}
                  isUpdating={isUpdating}
                  btnRefillModeAllMotorsToHome={btnRefillModeAllMotorsToHome}
                />
              )}
              <div style={{ width: window.innerWidth }} className="products-container-refill">
                {!isErrorInProduct &&
                  Object.keys(products).map(productData => {
                    rowKey += 1;
                    // console.log('productData', products[productData]);
                    return (
                      <div key={rowKey} className="item-list">
                        <ProductList
                          products={products[productData]}
                          objectKey={productData}
                          changeProduct={this.changeProduct}
                          updateProductQuantity={this.updateProductQuantity}
                          allowPriceChange={allowPriceChange}
                          allowProductChange={allowProductChange}
                          homeMotorTest={this.homeMotorTest}
                        />
                      </div>
                    );
                  })}
                {isErrorInProduct && (
                  <Button className=" wendor-big-button wendor-bg-red" onClick={() => this.getMachineDetails()}>
                    {btnRefillModeRefresh}
                  </Button>
                )}
              </div>
            </div>
          )}

          <Row style={{ margin: '20px 0px 100px 0px', height: '20vh' }}>
            <Col xs={12} sm={12} md={12} lg={12}>
              <LogoutButton dataTestid="refill-logout-btn" />
            </Col>
          </Row>
        </div>
      );
    }
    // show refill login form
    return (
      <RefillLogin
        logout={this.logout}
        login={this.refillLogin}
        refillLoginFailedReason={refillLoginFailedReason}
        loginFailed={refillLoginFailed}
        isSubmitting={isSubmitting}
        refillPageLoginURL={refillPageUIOptions.refillLoginImageURL}
      />
    );
  }
}

const mapState = state => ({
  machineId: state.machine.machineId,
  socketURL: state.machine.socketURL,
  modalSize: state.machine.machineDetails.modalSize,
  refillPageUIOptions: state.machine.refillPage,
  refillPageLanguage: state.languageReducer.refillPageLanguage,
});

export default withRouter(connect(mapState)(Refill));
