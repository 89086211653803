/*
  Top heading 
  manualReload the full page
*/

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { MACHINE_STATUS } from '../../actions/constants';

function checkIfOnline() {
  if (navigator.onLine) {
    return window.location.reload();
  }
  return false;
}

const SelectionPageHeaderTop = ({
  topHeadImageURL,
  topHeadImageSize,
  showTopHeadText,
  hideTopHeadImage,
  machineStatus,
  machineBalance,
  headingTitle,
  txtMachineOffline,
  buttonCheckForInternet,
  txtBalanceInfo,
}) => {
  const [manualReloadTry, setmanualReloadTry] = useState(0);
  const { cashPhoneOption } = JSON.parse(localStorage.getItem('cash')) || { cashPhoneOption: 'null' };

  const manualReload = () => {
    if (manualReloadTry >= 1 && navigator.onLine) {
      return window.location.reload();
    }
    return setmanualReloadTry(manualReloadTry + 1);
  };

  const style = {
    height: '40px',
  };
  if (topHeadImageSize === 'lg') {
    style.height = '150px';
  }

  const machinetype = localStorage.getItem('machineType');

  return (
    <div>
      <Row className={machinetype !== 'newuiwithoutphotov2' ? '' : 'row-container'}>
        <Col
          xs={12}
          className={machinetype !== 'newuiwithoutphotov2' ? 'title-container' : 'title-container-v2 public-sans-font'}>
          {machineStatus === MACHINE_STATUS.OFFLINE && (
            <Alert color="danger">
              {txtMachineOffline}{' '}
              <button type="button" onClick={() => checkIfOnline()}>
                {buttonCheckForInternet}
              </button>
            </Alert>
          )}
          {showTopHeadText && (
            <div style={{ textAlign: 'left', padding: '30px 10px' }}>
              <h1 style={{ fontSize: '48px', fontWeight: '700' }}>{headingTitle}</h1>
              {/* <span style={{ fontSize: '20px', fontWeight: '100' }}>
                  There are many variations of passages of Lorem lpsum.{' '}
              </span> */}
            </div>
          )}
          {topHeadImageURL && (
            <img
              alt="pick"
              onClick={() => manualReload()}
              className={machinetype !== 'newuiwithoutphotov2' ? 'title-image' : 'title-image-v2'}
              style={style}
              src={topHeadImageURL}
            />
          )}
          {!hideTopHeadImage && !topHeadImageURL && (
            <>
              <img
                alt="pick"
                onClick={() => manualReload()}
                className={machinetype !== 'newuiwithoutphotov2' ? 'title-image' : 'title-image-v2'}
                style={style}
                src="/assets/select.svg"
              />
              <span className={machinetype !== 'newuiwithoutphotov2' ? 'title-text' : 'title-text-v2'}>
                {headingTitle}
              </span>
              {/* {machinetype === 'newuiwithoutphotov2' && (
                <div className="help-section">
                  <img src="/assets/help.png" alt="No icon" className="help-section-img" />
                  <div className="help-section-text">Need help?</div>
                </div>
              )} */}
            </>
          )}
        </Col>
      </Row>
      {cashPhoneOption === 'carry' && (
        <div>
          <h2>
            {txtBalanceInfo} {machineBalance}
          </h2>
          <hr />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ machine }) => {
  return {
    machineBalance: machine.machineBalance,
  };
};

export default connect(mapStateToProps)(SelectionPageHeaderTop);
