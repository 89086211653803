import { LANGUAGE_REDUCER } from '../../actions/constants';

const languageReducer = (
  state = {
    homeScreen: {
      headingTitle: 'PICK', // "I want to buy"
      txtMachineOffline: 'Machine is Offline. Only cash is accepted.',
      buttonCheckForInternet: 'Check for Internet.',
      txtBalanceInfo: 'Balance in machine :',
      txtCartFull: 'Cart Full :',

      txtCartEmpty: 'Empty Cart',
      txtSelectProductFirst: 'Select a product first.',
      txtMaxItemAllowed: 'are maximum items are allowed in one go.',

      buttonResetTxt: 'Clear Cart',
      buttonPayTxt: 'Pay',
    },
    payModalLanguage: {
      txtPayModalTitle: 'Complete your payment in',
      txtFetchRequestIdFail: 'Failed Fetching Request Id. Try Again.',
      txtInternetConnectionError: 'Please check internet connectivity or contact wendor support.',
      txtRetry: 'Close and Try Again.',
      btnCacnel: 'Cancel',
      btnRetry: 'Retry',
      txtCash: 'Cash',
      txtSmartCard: 'Smart card',
      txtCard: 'Card',
      txtWendorSwift: 'Swift',
      txtLps: 'Lps',
      txtPrasuk: 'Organic Kitchen',
      txtCancelOrderConfirm: 'Are you sure you want to Cancel the Order?',
      txtBalanceTransferToSwift: `will be transfered to 's swift account`,
      btnCancelConfirmNo: 'No',
      btnCancelConfirmYes: 'Yes, Cancel',
    },
    swiftPaymentLanguage: {
      txtSwiftTitle: 'Enter your phone number',
      txtPleaseWait: 'Please wait ...',
      txtErrorMsg: 'Account with this number have insufficient points',
      txtEnterOtp: 'Please enter OTP sent to',
      txtPayFail: 'Payment Failed.',
      txtRemainingTries: 'Remaining Tries: ',
      txtotpSentInfo: 'The OTP can take upto 60 seconds',
      btnChangeNumber: 'Change number ?',
      btnSendOtp: 'Send OTP',
    },

    FreeVendPaymentLanguage: {
      txtSwiftTitle: 'Enter your phone number',
      txtPleaseWait: 'Please wait ...',
      txtErrorMsg: 'No free vends left for this phone number',
      txtEnterOtp: 'Please enter OTP sent to',
      txtPayFail: 'Payment Failed.',
      txtRemainingTries: 'Remaining Tries: ',
      txtotpSentInfo: 'The OTP can take upto 60 seconds',
      btnChangeNumber: 'Change number ?',
      btnSendOtp: 'Send OTP',
    },

    cashPaymentLanguage: {
      txtMainHeading: 'Enter your phone number',
      txtSubHeadingError: 'Error in request please retry',
      txtSubHeadingInfo: 'Enter your phone number for support, refunds and cashbacks',
      btnProceed: 'Proceed',
      txtCashInitMainHeadingInfo: 'Do not insert cash.....',
      txtCashInitSubHeadingInfo: 'We are checking the cash acceptor.',
      txtCashInitError: 'Sorry cash acceptor not working.',
      txtCashInitSuccessMainInfo: 'Please Insert Cash',
      txtCashInitSuccessSubInfo: 'Balance to be Paid',
      btnCashInitRetry: 'Retry',
      btnCashCancelAndExit: 'Cancel and send balance to Swift Points',
    },
    linkPaymentLanguage: {
      txtEnterPhoneNumber: 'Enter your phone number to recieve payment link via SMS',
      txtPostEnterPhoneNumber: 'We have sent you the payment link via SMS to',
      txtSmsSentLoading: 'Sending you sms',
      txtPostSmsSent: 'Check Your Phone',
      txtErrorMsg: 'Some error occured',
      btnOnPhonenumberEnter: 'Send Link',
      btnChangeNumber: 'Change number ?',
    },
    phoneNumberInputLanguage: {
      txtSubHeading: 'Extra money will be refunded to this account',
      txtMainMsg: 'Enter Phone Number',
      txtMainMsgSuccess: 'Success',
      txtSubHeadingCoinErr:
        'Can not connect to coin returner machine. Extra money will be refunded to your swift account',
      txtSubHeadingErr: 'Some Error Occured. Try again ?',
      btnSubmit: 'Submit',
    },
    rfidPaymentLanguage: {
      txtTriesPending: 'Tries pending',
      txtWaitTitle: 'Please wait',
      txtWaitInfo: 'We are checking card acceptor.',
      txtToucSmartCardInfo: 'Touch smart card to card acceptor',
      txtTouchSmartCardLowBalance: 'Sorry you have insufficient balance',
      txtCardErrorMsg: 'We do not recognize you.',
      txtCardErrorInstruction: 'You can retry by clicking button below.',
      btnRetryCardMachine: 'Retry',
      txtRfidBrokenMsg: 'Sorry... Smart Card acceptor not working.',
      btnRfidBrokenRetry: 'Retry',
      btnCardVerifyRetry: 'Retry',
      txtPostOtpErrorMsg: 'We have trouble sending you OTP',
      btnPostOtpErrorRetry: 'Retry',
      btnGetUserPin: 'Proceed',
    },
    vendModalLanguage: {
      txtVendingItemsTitle: 'Vending Items',
      txtPaymentCompleteTitle: 'Payment complete',
      txtOrderFailedTitle: 'Order Failed',
      txtOrderCompleteTitle: 'Order Complete',
      btnOrderNotAllowedMsg: 'Order for this number is not allowed',
      btnOrderNotAllowedInstruction: 'Please contact your organisation for more information',
      txtExtraAmountSwiftMsg:
        'as extra amount will be transferred to your Wendor Swift account within next 3 mins. Please check your inbox for SMS alert.',
      txtExtraAmountAfterVend: 'After vend complete get back the extra amount of ₹',
      txtCurrentVendingInfo: 'Current Vending: ',
      btnVendNotAuthClose: 'Close',
      txtOrderItemSubHeadding: 'Order items',
      txtMoneySymbol: '₹',
    },
    refillPageLanguage: {
      btnRetry: 'Retry',
      btnExit: 'Exit',
      btnOk: 'Ok',
      txtUnableToConnect: 'Unable to connect',
      txtSuccessUpdateInfo: 'Updated Successfully',
      txtExitRefillModeError: 'Exit refill mode failed',
      alertFailToUpdate: 'Failed to update.',
      txtRefillSummaryTitle: 'Refill Summary',
      txtTotalAmount: 'Total Amount: ',
      txtServiceMode: 'Service mode',
      btnRefillModeExit: 'Exit',
      btnRefillModeRefresh: 'Refresh',
      btnRefillModeSave: 'Save',
      btnRefillModeSaveExit: 'Save & Exit',
      btnRefillModeAllMotorsToHome: 'All Motors to home',
    },
    updateProductLanguage: {
      txtChangeProductDetails: 'Change Product Details at',
      txtSearchForProduct: 'Search for new Product',
      txtMachinePrice: 'Machine Price',
      txtMoneySymbol: '₹',
      pHAmount: 'Amount',
      txtMrp: 'MRP: ',
      btnCancel: 'Cancel',
      btnUpdate: 'Update',
    },
  },
  { type, payload },
) => {
  switch (type) {
    case LANGUAGE_REDUCER.SET_VALUES: {
      // console.log('SET VALUES PENDING USE DEFAULT INSTEAD', payload);
      const {
        cashPaymentLanguage,
        homeScreen,
        linkPaymentLanguage,
        payModalLanguage,
        phoneNumberInputLanguage,
        refillPageLanguage,
        rfidPaymentLanguage,
        swiftPaymentLanguage,
        emailOtpPaymentLanguage,
        updateProductLanguage,
        vendModalLanguage,
      } = payload;

      return {
        ...state,
        cashPaymentLanguage: { ...state.cashPaymentLanguage, ...cashPaymentLanguage },
        homeScreen: { ...state.homeScreen, ...homeScreen },
        linkPaymentLanguage: { ...state.linkPaymentLanguage, ...linkPaymentLanguage },
        payModalLanguage: { ...state.payModalLanguage, ...payModalLanguage },
        phoneNumberInputLanguage: { ...state.phoneNumberInputLanguage, ...phoneNumberInputLanguage },
        refillPageLanguage: { ...state.refillPageLanguage, ...refillPageLanguage },
        rfidPaymentLanguage: { ...state.rfidPaymentLanguage, ...rfidPaymentLanguage },
        swiftPaymentLanguage: { ...state.swiftPaymentLanguage, ...swiftPaymentLanguage },
        emailOtpPaymentLanguage: { ...state.emailOtpPaymentLanguage, ...emailOtpPaymentLanguage },
        updateProductLanguage: { ...state.updateProductLanguage, ...updateProductLanguage },
        vendModalLanguage: { ...state.vendModalLanguage, ...vendModalLanguage },
      };
    }
    default:
      return state;
  }
};

export default languageReducer;
