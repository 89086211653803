import React from 'react';
import { connect } from 'react-redux';
import {
  CASH_MODULE_STATUS,
  LOG,
  CASH_REDUCER,
  TIMER,
  MODAL_REDUCER,
  REQUEST_REDUCER_TYPES,
} from '../../actions/constants';
import { disablingCashModule, restartCashMachine } from '../../actions/cashActions';
import PayModeCashUI from '../../components/PaymentModes/PaymentModeCash';
import { getPaymentDetails } from '../../actions/payment';
import { sendMail } from '../../actions/logs';

class CashFlow extends React.Component {
  constructor(props) {
    super(props);
    const { requestId } = this.props;

    this.state = {
      requestIdSaved: requestId,
    };

    this.getNumberForCashModule = this.getNumberForCashModule.bind(this);
    this.sendNumberForCashToDatabase = this.sendNumberForCashToDatabase.bind(this);
    this.disableCashModule = this.disableCashModule.bind(this);
  }

  componentDidMount() {
    this.handleRetryButton();
  }

  componentWillUnmount() {
    const { requestIdSaved } = { ...this.state };
    const { totalAmountPaid, setRequestIdAfterVend, openPushRemainderToWallet } = this.props;
    const { cashPhoneOption } = JSON.parse(localStorage.getItem('cash'));
    // console.log('cashPhoneOptioncashPhoneOption', cashPhoneOption);
    // console.log('totalAmountPaid', totalAmountPaid);
    this.disableCashModule(requestIdSaved);
    if (cashPhoneOption === 'phone_after_payment' && totalAmountPaid > 0) {
      setRequestIdAfterVend(requestIdSaved);
      openPushRemainderToWallet();
    }
  }

  getNumberForCashModule() {
    const { updateCashModuleStatus } = { ...this.props };

    updateCashModuleStatus({
      status: CASH_MODULE_STATUS.GETTING_NUMBER,
    });
    // this.initCashModule()
  }

  handleRetryButton = () => {
    const { cashPhoneOption } = JSON.parse(localStorage.getItem('cash'));

    if (cashPhoneOption === 'phone_before_payment') {
      this.getNumberForCashModule();
    } else {
      this.initCashModule();
    }
  };

  async initCashModule() {
    const { requestId, phoneNumber, updateCashModuleStatus, machineId } = { ...this.props };
    let { cashModuleStatus } = { ...this.props };
    // console.log('initCashModule ----1 ', Date.now());
    const details = {
      payment_method: 'cash',
      request_id: requestId,
      phone: phoneNumber,
    };

    updateCashModuleStatus({
      status: CASH_MODULE_STATUS.INITIATING,
    });
    getPaymentDetails(details).then(async data => {
      if (!data.error) {
        window.initiatingCachModuleTimout = window.customSetTimeOut(
          () => {
            ({ cashModuleStatus } = { ...this.props });
            // console.log('initCashModule ----2', Date.now());
            window.customClearSetTimeout(window.initiatingCachModuleTimout, TIMER.INITIATING_CASH_MACHINE_30_SEC);
            if (
              cashModuleStatus === CASH_MODULE_STATUS.INITIATING ||
              cashModuleStatus === CASH_MODULE_STATUS.NOT_ACCEPTING
            ) {
              // logh
              window.heap.track('cash machine not connected', {
                phoneNumber,
                time: Date.now(),
              });
              const requestBody = {
                to: 'machine1002@wendor.in',
                subject: `${machineId} - Cash Machine not connected`,
                html_body: `MId ${machineId}. Cash <b>machine status broken</b>`,
                alt_body: 'Tested',
              };
              sendMail(requestBody);
              restartCashMachine();
              this.disableCashModule();
              updateCashModuleStatus({
                status: CASH_MODULE_STATUS.BROKEN,
              });
            }
          },
          1000 * 15,
          TIMER.INITIATING_CASH_MACHINE_30_SEC,
        ); // Wait time for cash Machine
      } else {
        updateCashModuleStatus({ status: CASH_MODULE_STATUS.BROKEN });
      }
    });
  }

  disableCashModule(requestIdSaved) {
    const { requestId, phoneNumber } = this.props;
    // updateCashModuleStatus
    // Disable Cash Module
    window.heap.track('disable cash machine', {
      phoneNumber,
      time: Date.now(),
    });
    const requestBody = {
      requestId: requestId || requestIdSaved,
      comment: 'Disable Cash Module',
    };
    // console.log('requestBody sending ');
    // console.log(requestBody);
    window.customClearSetTimeout(window.initiatingCachModuleTimout, TIMER.INITIATING_CASH_MACHINE_30_SEC);
    disablingCashModule(requestBody);
  }

  sendNumberForCashToDatabase() {
    const { updateCashModuleStatus, phoneNumber } = { ...this.props };
    window.heap.track('Proceed button in cash machine', {
      phoneNumber,
      time: Date.now(),
    });
    updateCashModuleStatus({
      status: CASH_MODULE_STATUS.LOADING,
    });
    this.initCashModule();
  }

  render() {
    const {
      cashModuleStatus,
      totalAmountPaid,
      totalAmount,
      isPhoneNumberValid,
      phoneNumber,
      handlePhoneNumberChange,
      toggleConformPaymentCancelModal,
      cashPaymentLanguage,
    } = this.props;

    const {
      txtmainHeading,
      txtSubHeadingError,
      txtSubHeadingInfo,
      btnProceed,
      txtCashInitMainHeadingInfo,
      txtCashInitSuccessMainInfo,
      txtCashInitSuccessSubInfo,
      txtCashInitSubHeadingInfo,
      txtCashInitError,
      btnCashInitRetry,
      btnCashCancelAndExit,
    } = cashPaymentLanguage;
    const currencyDetails = JSON.parse(localStorage.getItem('cash'));

    return (
      <PayModeCashUI
        symbol={currencyDetails.symbol}
        suffix={currencyDetails.isSuffix}
        cashModuleStatus={cashModuleStatus}
        isPhoneNumberValid={isPhoneNumberValid}
        handlePhoneNumberChange={handlePhoneNumberChange}
        sendNumberForCashToDatabase={this.sendNumberForCashToDatabase}
        phoneNumber={phoneNumber}
        totalAmount={totalAmount}
        txtmainHeading={txtmainHeading}
        txtSubHeadingError={txtSubHeadingError}
        txtSubHeadingInfo={txtSubHeadingInfo}
        btnProceed={btnProceed}
        txtCashInitMainHeadingInfo={txtCashInitMainHeadingInfo}
        txtCashInitSuccessMainInfo={txtCashInitSuccessMainInfo}
        txtCashInitSuccessSubInfo={txtCashInitSuccessSubInfo}
        txtCashInitSubHeadingInfo={txtCashInitSubHeadingInfo}
        txtCashInitError={txtCashInitError}
        btnCashInitRetry={btnCashInitRetry}
        btnCashCancelAndExit={btnCashCancelAndExit}
        totalAmountPaid={totalAmountPaid}
        handleRetryButton={this.handleRetryButton}
        toggleConformPaymentCancelModal={toggleConformPaymentCancelModal}
      />
    );
  }
}

const mapStateToProps = ({ cashReducer, languageReducer, cartReducer, requestReducer, machine }) => ({
  cashModuleStatus: cashReducer.cashModuleStatus,
  totalAmount: cartReducer.totalAmount,
  totalAmountPaid: requestReducer.totalAmountPaid,
  machineId: machine.machineId,
  cashPaymentLanguage: languageReducer.cashPaymentLanguage,
});

const mapDispatchToProps = dispatch => ({
  openPushRemainderToWallet: () => dispatch({ type: MODAL_REDUCER.OPEN_PUSH_REMAINDER_TO_WALLET_MODAL, payload: true }),
  setRequestIdAfterVend: payload => dispatch({ type: REQUEST_REDUCER_TYPES.SET_REQUEST_ID_AFTER_VEND, payload }),
  addClickToLog: payload => dispatch({ type: LOG.ADD_CLICK_TO_LOG, payload }),
  updateCashModuleStatus: payload => dispatch({ type: CASH_REDUCER.UPDATE_CASH_MODULE, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CashFlow);
