import axios from 'axios';
import { LOG } from './constants';

export const login = async (machineId, password) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_KIOSK_CRITICAL_SERVICE_URL}/machineLogin`,
      data: {
        machineId,
        password,
      },
    });

    return response.data;
  } catch (err) {
    if (err.response && err.response.status === 403) {
      return {
        error: true,
        message: err.response.data.message,
      };
    }
    return {
      error: true,
      message: 'Error in connecting to internet',
    };
  }
};

export const validate = async token => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_KIOSK_CRITICAL_SERVICE_URL}/api/machineValidate`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    // console.log('machine validation failed');
    return {
      error: true,
      status: err.response.status,
      networkError: true,
      data: {},
    };
  }
};

export const unassignMachine = async () => {
  const token = localStorage.getItem('token');

  try {
    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_KIOSK_CRITICAL_SERVICE_URL}/api/unassignMachine`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    // console.log('er');
    return {
      error: true,
      networkError: true,
      data: {},
    };
  }
};

export const setLoggedIn = payload => ({
  type: LOG.ADD_CLICK_TO_LOG,
  payload,
});
