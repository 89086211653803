/*
  Render selected payment data
*/

import React from 'react';
import '../../assets/css/PaymentDetailsUI.css';
import PaymentOptions from './paymentOptions';
import RenderPaymentData from './paymentData';

export default ({
  changePaymentMethod,
  paymentMethod,
  isPhoneNumberValid,
  smsSentStatus,
  phoneNumber,
  handlePhoneNumberChange,
  handlePinNumberChange,
  totalAmountPaid,
  checkPaymentStatus,
  isSwiftOTPSent,
  swiftWalletOTPStatusUpdate,
  showWarningWhenCashReceviedAndTryToChangePaymentMode,
  requestId,
  enabledPaymentGateways,
  rfidPin,
  toggleConformPaymentCancelModal,
  paymentModalUIOptions,
  isOnline,
  txtCash,
  txtSmartCard,
  txtCard,
  txtWendorSwift,
  txtLps,
  txtPrasuk,
}) => {
  const styles = {
    paymentOptions: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      padding: '5px',
      flexDirection: 'column',
    },
  };

  const renderPaymentOptions = UIstyles => (
    <div style={UIstyles.paymentOptions}>
      <PaymentOptions
        enabledPaymentGateways={enabledPaymentGateways}
        isOnline={isOnline}
        paymentMethod={paymentMethod}
        changePaymentMethod={changePaymentMethod}
        totalAmountPaid={totalAmountPaid}
        isSwiftOTPSent={isSwiftOTPSent}
        txtCash={txtCash}
        txtSmartCard={txtSmartCard}
        txtCard={txtCard}
        txtWendorSwift={txtWendorSwift}
        txtLps={txtLps}
        txtPrasuk={txtPrasuk}
      />
    </div>
  );

  const renderPaymentData = () => (
    <div className="get-payment-data">
      <RenderPaymentData
        enabledPaymentGateways={enabledPaymentGateways}
        paymentMethod={paymentMethod}
        requestId={requestId}
        checkPaymentStatus={checkPaymentStatus}
        smsSentStatus={smsSentStatus}
        handlePhoneNumberChange={handlePhoneNumberChange}
        phoneNumber={phoneNumber}
        changePaymentMethod={changePaymentMethod}
        isPhoneNumberValid={isPhoneNumberValid}
        toggleConformPaymentCancelModal={toggleConformPaymentCancelModal}
        handlePinNumberChange={handlePinNumberChange}
        rfidPin={rfidPin}
        swiftWalletOTPStatusUpdate={swiftWalletOTPStatusUpdate}
      />
    </div>
  );

  const renderPaymentSection = () => {
    if (paymentModalUIOptions.paymentPos === 'horizontal-bottom') {
      styles.paymentOptions.flexDirection = 'row';
      return (
        <>
          {renderPaymentData()}
          {renderPaymentOptions(styles)}
        </>
      );
    }
    if (paymentModalUIOptions.paymentPos === 'horizontal-top') {
      styles.paymentOptions.flexDirection = 'row';
      return (
        <>
          {renderPaymentOptions(styles)}
          {renderPaymentData()}
        </>
      );
    }
    if (paymentModalUIOptions.paymentPos === 'vertical-right') {
      return (
        <>
          {renderPaymentData()}
          {renderPaymentOptions(styles)}
        </>
      );
    }
    // Default. vertical-left
    return (
      <>
        {renderPaymentOptions(styles)}
        {renderPaymentData()}
      </>
    );
  };

  const paymentModalBoxOuter = {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '600px',
  };

  if (paymentModalUIOptions.paymentPos.indexOf('horizontal') > -1) {
    paymentModalBoxOuter.flexDirection = 'column';
  }

  return (
    <div className="payment-modal-body">
      <div className={showWarningWhenCashReceviedAndTryToChangePaymentMode ? 'alert alert-warning' : 'remove'}>
        {showWarningWhenCashReceviedAndTryToChangePaymentMode && (
          <h5>
            {' '}
            <ion-icon name="alert" /> Sorry can not change payment option
          </h5>
        )}
      </div>
      <div style={paymentModalBoxOuter}>{renderPaymentSection()}</div>
    </div>
  );
};
