import { LOG } from '../../actions/constants';

const logsReducer = (state = {}, { type, payload }) => {
  const newState = Object.assign({}, state);
  switch (type) {
    case LOG.ADD_CLICK_TO_LOG:
      // const oldClickes = ;

      return {
        ...state,
        ...{
          pastClicks: [payload, ...(newState.pastClicks || [])].slice(0, 50),
        },
      };
    case LOG.SCREEN_SAVER_WAS_ACTIVE:
      return { ...state, isScreenSaverActive: true };
    case LOG.SCREEN_SAVER_WAS_NOT_ACTIVE:
      return { ...state, isScreenSaverActive: false };
    default:
      return state;
  }
};

export default logsReducer;
