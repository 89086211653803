/* eslint-disable no-return-await */
/*
  Merge 
  Head Component 
  Product items
  Footer

  Interval that will check if Update is available nd check vmc is connected.
*/
import React from 'react';
import { connect } from 'react-redux';
import { ReactInternetSpeedMeter } from 'react-internet-meter';
import ProductsDisplay from '../ProductsDisplay/index';
import { updateMachineStatus } from '../../actions/machine';
import Error from '../Error';
import { refillStateCheck } from '../../actions/refill';
import checkUpdate from '../../actions/checkUpdate';
import {
  LOG,
  ESP_STATUS,
  MQTT_STATUS,
  ERROR_CODE,
  TIMER,
  MACHINE_STATUS,
  MACHINE,
  alertMail,
} from '../../actions/constants';
import { checkMultivac, connectToMQTT as connectToMQTTFunction } from '../../actions/mqtt';
import { syncLocalDB } from '../../actions/db';
import Tophead from './TopHead';
// import Footer from '../../components/Footer';
import { getCashInMachine } from '../../actions/cashActions';
import { sendMail } from '../../actions/logs';
import MachineToggleModal from '../MachineToggleModal';
import { Footer } from '../../components';

class SelectionPageHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCheckingRefill: false,
      newSW: null,
      internetSpeedThreshold: 10, // mB
    };
    window.activeIntervals = 0; // Keep count of active Intervals as a lot of interval are being used
    window.intervals = []; // Array for the names of the interval being used
    window.customSetInterval = (func, delay, name) => {
      // Custom SetInterval function is made to keep track of intervals by their names.
      // If interval is not present then add Interval to the array of intervals and setInterval
      if (window.intervals.indexOf(name) === -1 && name !== undefined) {
        window.activeIntervals += 1;
        window.intervals.push(name);
        // console.log('interval is now running ', name);
        return window.setInterval(func, delay);
      }
      return {};
    };

    window.customClearInterval = (timerID, name) => {
      // Custom ClearInterval function is made to keep track of intervals by their names.
      // If interval is present in the array of intervals then clear that Interval. ELse do nothing
      // console.log('total intervals', window.intervals);
      // console.log('clearing interval', name);
      const intervalIndex = window.intervals.indexOf(name);
      if (intervalIndex > -1) {
        window.activeIntervals -= 1;
        window.intervals.splice(intervalIndex, 1);
        window.clearInterval(timerID);
      }

      return {};
    };
    window.activeTimeOut = 0; // Keep count of active Intervals as a lot of intervals are being used
    window.timeOut = []; // Array for the names of the interval being used
    window.customSetTimeOut = (func, delay, name) => {
      // console.log('adding timeout', name);
      if (window.timeOut.indexOf(name) === -1 && name !== undefined) {
        window.activeTimeOut += 1;
        window.timeOut.push(name);
        return window.setTimeout(func, delay);
      }
      // console.log('timeout not added ', name);
      return '';
    };

    window.customClearSetTimeout = (timerID, name) => {
      const intervalIndex = window.timeOut.indexOf(name);
      // console.log('total timeout', window.timeOut);
      // console.log('clearing timeout', name);
      if (intervalIndex > -1) {
        window.activeTimeOut -= 1;
        window.timeOut.splice(intervalIndex, 1);
        window.clearTimeout(timerID);
      }

      return {};
    };
    this.checkRefillRequest = this.checkRefillRequest.bind(this);
    this.checkVersionUpdate = this.checkVersionUpdate.bind(this);
    this.setPingInterval = this.setPingInterval.bind(this);
    this.getSetMachineBalance = this.getSetMachineBalance.bind(this);
    this.sendInternetMail = this.sendInternetMail.bind(this);
  }

  componentDidMount() {
    /*  
        Tasks
        Clear intervals , Start heartbeat interval,connectToMqtt, Sync local db , update machine status
     */
    const { connectToMQTT, setIsConfiguring, multivacDetails, vmcPingTimeout } = this.props;
    const { cashPhoneOption } = JSON.parse(localStorage.getItem('cash')) || { cashPhoneOption: null };
    // Clear all intervals
    for (let i = 0; i < 100; i += 1) {
      window.clearInterval(i);
    }

    if ('serviceWorker' in navigator) {
      window.navigator.serviceWorker
        .register('./new-sw.js')
        .then(reg => {
          // eslint-disable-next-line no-param-reassign
          reg.onupdatefound = () => {
            const newSW = reg.installing;
            newSW.postMessage('skipWaiting');
          };
        })
        .catch(() => {
          // console.log('error in registering ');
          // // console.log(e);
        });
    }
    for (let i = 0; i < 100; i += 1) {
      window.clearTimeout(i);
    }
    this.setPingInterval(); // Start Ping Interval. Heartbeat
    connectToMQTT(multivacDetails);
    if (navigator.onLine) {
      syncLocalDB();
    }
    // console.log('component is mounting -----');
    checkMultivac().then(() => {
      // set timeout to change firstCheck state key.
      setTimeout(() => {
        setIsConfiguring({ isConfiguring: false });
      }, vmcPingTimeout * 1000);
    });
    // Get Machine Data
    updateMachineStatus({ statusCode: 42, message: 'Refreshed', mode: 'interaction' });

    if (cashPhoneOption === 'carry') {
      this.getSetMachineBalance();
    }
  }

  componentWillUnmount() {
    // Clear intervals and timeouts

    window.customClearSetTimeout(this.setPingInterval, TIMER.PING_30_MIN);
    window.customClearSetTimeout(this.setTimeoutForVNC, TIMER.VMC_15_SEC_TIMMER);
  }

  async getSetMachineBalance() {
    const { setMachineBalance } = this.props;
    // console.log(this.state);
    try {
      const { error, left_amount: leftAmount } = await getCashInMachine();
      if (!error) {
        setMachineBalance(leftAmount);
      }
    } catch (e) {
      // console.log('ERROR IN FETCHING BALANCE');
    }
  }

  setPingInterval() {
    // set interval to check vmc, and version update

    const { vmcPingTimeout, vmcPingInterval } = this.props;
    // console.log(vmcPingInterval);
    this.pingInterval = window.customSetTimeOut(
      () => {
        const { updateESPStatus } = this.props;
        const isVending = this.isVendingInProcess();

        if (!isVending) {
          const { espStatus } = this.props;
          // Run only when payment or vending is not in process
          checkMultivac(); // Ping ESP
          if (espStatus !== ESP_STATUS.DISCONNECTED) {
            updateESPStatus({ status: ESP_STATUS.CONNECTING });
          }
          this.setTimeoutForVNC = window.customSetTimeOut(
            () => {
              const { espStatus: esp } = this.props;
              const isVendingInProcess = this.isVendingInProcess();
              if (!isVendingInProcess) {
                if (esp !== ESP_STATUS.CONNECTED) {
                  updateMachineStatus({
                    statusCode: ERROR_CODE.VMC_ERROR,
                    message: 'vmc not connect, in 30 min error ',
                    mode: 'periodic',
                  });
                  // VMC_ERROR
                  checkMultivac();
                  updateESPStatus({ status: ESP_STATUS.DISCONNECTED });
                }
              }
              window.customClearSetTimeout(this.setTimeoutForVNC, TIMER.VMC_15_SEC_TIMMER);
            },
            vmcPingTimeout * 1000,
            TIMER.VMC_15_SEC_TIMMER,
          ); // Wait for 25 secs.
        }
        window.customClearSetTimeout(this.setPingInterval, TIMER.PING_30_MIN);
        this.setPingInterval();
        this.checkVersionUpdate();
      },
      vmcPingInterval * 1000,
      TIMER.PING_30_MIN, // window.customSetInterval overwritten in constructor
    ); // Run every 15 mins
  }

  isVendingInProcess = () => {
    const { isOpenQRModal, isOpenVendModal, isOpenPaymentTimedOutModal } = this.props;
    if (!isOpenQRModal && !isOpenVendModal && !isOpenPaymentTimedOutModal) {
      return false;
    }
    return true;
  };

  sendInternetMail = speed => {
    const { internetSpeedThreshold } = { ...this.state };
    const requestBody = {
      to: alertMail,
      subject: `${localStorage.getItem('machineId')} - Internet alert`,
      html_body: `machine internet req is ${internetSpeedThreshold} mB and got ${speed} mB`,
      alt_body: 'Tested',
    };
    sendMail(requestBody);
  };

  deleteAllCookies = () => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name} =;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  };

  checkVersionUpdate() {
    // Check for software version update

    checkUpdate().then(data => {
      const isVending = this.isVendingInProcess();
      if (!isVending) {
        if (!data.error && data.data.update_available) {
          // window.caches.invalidate()
          // console.log('Invalidating cache');
          this.deleteAllCookies();
          window.location.reload(true); // Hard Refresh if a new version is available
        } else if (navigator.onLine) {
          // console.log('Syncing DB');
          syncLocalDB();
        }
      }
    });
  }

  checkRefillRequest() {
    // Check Refill Open Request
    const { addClickToLog, history } = this.props;
    addClickToLog(`check refill request `);
    this.setState({
      isCheckingRefill: true,
    });
    refillStateCheck().then(data => {
      const { error, success } = data;
      this.setState({
        isCheckingRefill: false,
      });
      if (!error && success) {
        updateMachineStatus({ statusCode: 42, message: 'Opened for refill', mode: 'interaction' });
        history.push('/refill');
      }
    });
  }

  render() {
    const { isCheckingRefill } = { ...this.state };
    const {
      mqttStatus,
      espStatus,
      openMachineTypeDidNotMatch,
      productDisplayPage,
      machineStatus,
      machineToggle,
      machineTypeDidNotMatch,
      homeScreenTxt,
      useCarouselHeader,
      customHeaderVariant,
      banner,
    } = this.props;

    const {
      topHeadImageURL,
      showFooterSubText,
      topHeadImageSize,
      hideTopHeadImage,
      showTopHeadText,
    } = productDisplayPage;

    const {
      txtBalanceInfo,
      buttonCheckForInternet,
      txtMachineOffline,
      headingTitle,
      buttonResetTxt,
      buttonPayTxt,
      txtCartEmpty,
      txtSelectProductFirst,
      txtMaxItemAllowed,
      txtCartFull,
    } = homeScreenTxt;
    const { bannerType, bannerUrl } = banner;
    const error =
      machineStatus !== MACHINE_STATUS.OFFLINE &&
      (openMachineTypeDidNotMatch || mqttStatus !== MQTT_STATUS.CONNECTED || espStatus === ESP_STATUS.DISCONNECTED);
    const isVending = this.isVendingInProcess();

    const machineToggled =
      machineToggle === MACHINE_STATUS.OFF &&
      mqttStatus === MQTT_STATUS.DISCONNECTED &&
      espStatus === ESP_STATUS.DISCONNECTED;

    return (
      <div className="home-screen-container">
        {/* {error && !isVending && <Error isCheckingRefill={isCheckingRefill} showCancel={openMachineTypeDidNotMatch} />} */}
        {!machineToggled && error && !isVending && (
          <Error isCheckingRefill={isCheckingRefill} showCancel={openMachineTypeDidNotMatch} />
        )}
        {machineToggled && !isVending && (
          <MachineToggleModal isCheckingRefill={isCheckingRefill} showCancel={openMachineTypeDidNotMatch} />
        )}
        {machineTypeDidNotMatch && <h3>Machine type invalid</h3>}
        <ReactInternetSpeedMeter
          outputType="empty"
          pingInterval={300 * 1000} /* milliseconds */
          thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
          threshold={10}
          imageUrl="https://res.cloudinary.com/dcwxsms2l/image/upload/v1610376487/pexels-ivan-samkov-6291574_bzqgps.jpg"
          downloadSize="1781287" // bytes
          callbackFunctionOnNetworkDown={speed => this.sendInternetMail(speed)}
        />
        <Tophead
          topHeadImageURL={topHeadImageURL}
          topHeadImageSize={topHeadImageSize}
          machineStatus={machineStatus}
          showTopHeadText={showTopHeadText}
          hideTopHeadImage={hideTopHeadImage}
          headingTitle={headingTitle}
          txtMachineOffline={txtMachineOffline}
          buttonCheckForInternet={buttonCheckForInternet}
          txtBalanceInfo={txtBalanceInfo}
          useCarouselHeader={useCarouselHeader}
          customHeaderVariant={customHeaderVariant}
        />
        {bannerType === 'image' && (
          <img style={{ objectFit: 'cover' }} height={607.5} width="100%" src={`${bannerUrl}`} alt="Banner" />
        )}
        {bannerType === 'video' && (
          <video width="100%" height="607.5">
            <source src={`${bannerUrl}`} />
            <track src="captions_en.vtt" kind="captions" />
          </video>
        )}
        <ProductsDisplay
          buttonResetTxt={buttonResetTxt}
          buttonPayTxt={buttonPayTxt}
          txtCartFull={txtCartFull}
          txtCartEmpty={txtCartEmpty}
          txtSelectProductFirst={txtSelectProductFirst}
          txtMaxItemAllowed={txtMaxItemAllowed}
          showFooterSubText={showFooterSubText}
        />
        {showFooterSubText && <Footer checkRefillMode={this.checkRefillRequest} />}
      </div>
    );
  }
}

const mapStateToProps = ({ machine, modalReducer, languageReducer }) => ({
  productDisplayPage: machine.productDisplayPage,
  multivacDetails: machine.multivacDetails,
  machineStatus: machine.machineStatus,
  machineToggle: machine.machineToggle,
  machineTypeDidNotMatch: machine.machineTypeDidNotMatch,
  vmcPingTimeout: machine.machineDetails.vmcPingTimeout,
  vmcPingInterval: machine.machineDetails.vmcPingInterval,
  isOpenQRModal: modalReducer.isOpenQRModal,
  isOpenVendModal: modalReducer.isOpenVendModal,
  isOpenErrorModal: modalReducer.isOpenErrorModal,
  mqttStatus: machine.mqttStatus,
  espStatus: machine.espStatus,
  homeScreenTxt: languageReducer.homeScreen,
  banner: machine?.banner,
  useCarouselHeader: machine.productDisplayPage?.useCarouselHeader
    ? machine.productDisplayPage?.useCarouselHeader
    : false,
  customHeaderVariant: machine.productDisplayPage?.customHeaderVariant
    ? machine.productDisplayPage?.customHeaderVariant
    : 'default',
});

const mapDispatchToProps = dispatch => ({
  connectToMQTT: multivacDetails => dispatch(connectToMQTTFunction(multivacDetails)),
  updateESPStatus: payload => dispatch({ type: MACHINE.SET_ESP_STATUS, payload }),
  setIsConfiguring: payload => dispatch({ type: MACHINE.SET_IS_CONFIGURING, payload }),
  setMachineBalance: payload => dispatch({ type: MACHINE.SET_MACHINE_BALANCE, payload }),
  addClickToLog: payload => dispatch({ type: LOG.ADD_CLICK_TO_LOG, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectionPageHeader);
