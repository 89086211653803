/*
  Cash payment process 
  UI for 
  - Inititating cash 
  - Broken (not connected)
  - Accepting cash
*/

import React from 'react';
import NumberFormat from 'react-number-format';
import { Button } from 'reactstrap';

import { ThreeBubbleLoader } from '../../index';
import { CASH_MODULE_STATUS } from '../../../actions/constants';

const InitiatingCash = ({
  cashModuleStatus,
  totalAmount,
  totalAmountPaid,
  connectToCash,
  toggleConformPaymentCancelModal,
}) => {
  const currencyDetails = JSON.parse(localStorage.getItem('cash'));
  return (
    <div>
      {cashModuleStatus === CASH_MODULE_STATUS.INITIATING && (
        <div>
          <h3>Do not insert cash.....</h3>
          <h5>We are checking the cash acceptor. </h5>
          <ThreeBubbleLoader />
        </div>
      )}
      {cashModuleStatus === CASH_MODULE_STATUS.BROKEN && (
        <div className="sorry-message">
          <ion-icon name="sad" />
          <h5>Sorry cash acceptor not working.</h5>
          <Button className="wendor-bg-green wendor-small-button " onClick={() => connectToCash()}>
            Retry.
          </Button>
        </div>
      )}
      {cashModuleStatus === CASH_MODULE_STATUS.ACCEPTING && (
        <>
          {totalAmount - totalAmountPaid >= 0 && (
            <div>
              <h5>Please Insert Cash</h5>
              <h1>
                <NumberFormat
                  value={` ${totalAmount - totalAmountPaid} `}
                  displayType="text"
                  thousandSeparator
                  prefix={currencyDetails.isSuffix ? null : currencyDetails.symbol}
                  suffix={currencyDetails.isSuffix ? currencyDetails.symbol : null}
                />
              </h1>
              <h5>Amount left to collect</h5>
              <Button
                className="wendor-bg-red"
                disabled={parseInt(totalAmountPaid, 10) === 0}
                onClick={() => toggleConformPaymentCancelModal()}>
                Cancel and send balance to Swift Points
              </Button>
            </div>
          )}
          {totalAmount - totalAmountPaid < 0 && (
            <div>
              <h5>Extra Cash</h5>
              <h1>
                <NumberFormat
                  value={` ${-1 * (totalAmount - totalAmountPaid)} `}
                  displayType="text"
                  thousandSeparator
                  prefix={currencyDetails.isSuffix ? null : currencyDetails.symbol}
                  suffix={currencyDetails.isSuffix ? currencyDetails.symbol : null}
                />
              </h1>
              <h5>Extra Cash will be added to your swift account when internet will be restored.</h5>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InitiatingCash;
