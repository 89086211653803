import React, { useState, useEffect } from 'react';
import { getPaymentDetails } from '../../actions/payment';
import { ThreeBubbleLoader } from '../../components';
import QRDisplay from '../../components/QrDisplay';

const UpiFlow = ({ checkPaymentStatus, requestId }) => {
  const [isUpiQRFetched, setisUpiQRFetched] = useState(false);
  const [isLoadingUPIQR, setisLoadingUPIQR] = useState(true);
  const [isUpiQRFetchedError, setisUpiQRFetchedError] = useState(false);
  const [UpiQRData, setUpiQRData] = useState('');

  const getUPIDetails = () => {
    // GET UPI QR
    window.heap.track('Getting upi details', {
      paymentMethod: 'UPI',
      requestId,
    });

    if (UpiQRData !== '') {
      // If payTm or UPI QRData already fetched, then just start Polling. Do not make a new request
      return;
    }

    // Loading QR for paytm or upi methods only
    setisLoadingUPIQR(true);
    setisUpiQRFetched(false);
    setisUpiQRFetchedError(false);

    const details = {
      payment_method: 'upi',
      request_id: requestId,
    };

    getPaymentDetails(details).then(async data => {
      // console.log('request complete ');
      window.heap.track('Getting UPI details resp', {
        requestId,
        data,
      });
      if (!data.error) {
        setUpiQRData(data.data);
        setisLoadingUPIQR(false);
        setisUpiQRFetched(true);
        setisUpiQRFetchedError(false);
        checkPaymentStatus(); // Check Payment Status for UPI
      } else {
        setUpiQRData('');
        setisLoadingUPIQR(false);
        setisUpiQRFetched(false);
        setisUpiQRFetchedError(true);
      }
    });
  };

  useEffect(() => {
    window.heap.track('Upi compoennt mounted', {
      time: Date.now(),
    });
    getUPIDetails();
  }, []);

  if (!requestId) {
    return <ThreeBubbleLoader />;
  }

  return (
    <QRDisplay
      isQRLoading={isLoadingUPIQR}
      isQRFetched={isUpiQRFetched}
      isQRFetchError={isUpiQRFetchedError}
      QRData={UpiQRData}
      showRetry
      retryButtonClickFunction={getUPIDetails}
    />
  );
};

export default UpiFlow;
