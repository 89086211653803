/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import uniqid from 'uniqid';
import '../assets/css/RowItemCard.css';

class NewItemCardWithoutScroll extends React.Component {
  // export default ({ productsData, updateCart, totalItems }) => {\
  constructor(props) {
    super(props);
    const { productItem } = this.props;
    this.state = {
      quantity: 0,
      isEmpty: productItem.left_units <= 0,
      className: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { numberOfReset, productItem } = { ...this.props };

    if (prevProps.numberOfReset !== numberOfReset || prevProps.productItem.left_units !== productItem.left_units) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  getSnapshotBeforeUpdate(prevProps) {
    const { numberOfReset, productItem } = { ...this.props };
    if (prevProps.numberOfReset !== numberOfReset || prevProps.productItem.left_units !== productItem.left_units) {
      this.setState({ quantity: 0, isEmpty: productItem.left_units === 0 });
    }
    return null;
  }

  handleClick = newQuantity => {
    const { quantity } = { ...this.state };
    const { productItem, updateCart, disableSelect } = this.props;
    if (newQuantity < 0) {
      this.setState({
        quantity: 0,
        isEmpty: false,
      });
      updateCart(productItem.id, newQuantity);
    } else if (!disableSelect) {
      if (productItem.left_units >= quantity + newQuantity) {
        this.setState({ quantity: quantity + newQuantity });
        updateCart(productItem.id, newQuantity + quantity);
      } else {
        this.setState({ isEmpty: true });
      }
    }
  };

  handleClickAnimation = () => {
    this.setState({ className: 'touch-animation-card ' });
    this.timer = setTimeout(() => {
      this.setState({ className: '' });
    }, 225);
  };

  render() {
    const {
      disableSelect,
      productItem,
      machineType,
      productDisplayPage,
      maxItems,
      defaultProductURL,
      symbol,
      suffix,
    } = {
      ...this.props,
    };
    const { quantity, isEmpty, className } = { ...this.state };
    const productDisplayClass = machineType !== 'imshow' ? 'product-card-animated' : 'product-card-imshow';

    const renderProductImage = () => {
      if (productItem && productItem.image_mini) {
        return <img style={{ height: '175px', width: '250px' }} src={productItem.image_mini} alt="product_image" />;
      }
      if (productItem && !productItem.image_mini && defaultProductURL) {
        return <img style={{ height: '175px', width: '250px' }} src={defaultProductURL} alt="product_image" />;
      }
      return (
        <div style={{ height: '175px', width: '250px', display: 'flex', backgroundColor: 'black' }}>
          <p
            style={{
              fontSize: '35px',
              justifyContent: 'center',
              alignSelf: 'center',
              width: '100%',
              color: '#fff',
            }}>
            NO IMAGE
          </p>
        </div>
      );
    };

    const isSlotDisable = isEmpty || productItem.left_units <= 0 || !productItem.enabled;
    const style = productItem.custom_css ? JSON.parse(productItem.custom_css) : {};
    return (
      <div
        onTouchEnd={this.handleClickAnimation}
        className={`${className} text-align-center ${
          isSlotDisable && !quantity > 0
            ? 'shake-component p-card-animated-disabled-without-scroll'
            : 'p-card-animated-without-scroll'
        } ${quantity > 0 ? 'p-card-animated-selected' : ''}`}>
        <div
          style={style}
          role="button"
          key={uniqid()}
          onClick={() => this.handleClick(1)}
          data-testid="product-display-box"
          className={`display-inline-block ${
            quantity > 0 && machineType !== 'imshow' ? 'selected-product-card' : 'unselected-product-card'
          } ${productDisplayClass}`}
          disabled={disableSelect}>
          <div className={` ${isSlotDisable ? 'empty-card ' : ''} card-body-animated`}>
            <div className="item-pos-display">
              {productDisplayPage.showImages && productItem.left_units > 0 && (
                <div className="image-display">{renderProductImage()}</div>
              )}
              {productDisplayPage.showImages && productItem.left_units <= 0 && (
                <div style={{ height: '175px', width: '250px', display: 'flex', backgroundColor: 'black' }}>
                  <p
                    style={{
                      fontSize: '35px',
                      justifyContent: 'center',
                      alignSelf: 'center',
                      width: '100%',
                      color: '#fff',
                    }}>
                    SOLD OUT
                  </p>
                </div>
              )}
              <div className="item-pos-name">
                <h3
                  className={`${isSlotDisable ? 'product-position-animated-disable' : 'product-position-animated'}`}
                  data-test="product-position">
                  {productItem.show_pos.toUpperCase()}
                </h3>
                {maxItems > 1 && quantity > 0 && (
                  <h5
                    data-testid="product-display-box-quantity"
                    className="product-cart-quantity"
                    data-test="product-cart-quantity">
                    {quantity}
                  </h5>
                )}
                {productDisplayPage.showName && <p className="product-name">{productItem.name.substring(0, 20)}</p>}
              </div>
            </div>
            {/* Disable when no. of left units are less than 0 and cart_quantity >= left_units */}{' '}
            <button
              type="button"
              disabled={disableSelect}
              data-test="product-select-button"
              className={`${isSlotDisable ? 'wendor-price-animated-disable' : 'wendor-price-animated'}`}>
              {productDisplayPage.showDiscount && productItem.actual_price !== productItem.price && (
                <span className="discount-price">
                  <NumberFormat
                    value={` ${productItem.actual_price} `}
                    displayType="text"
                    thousandSeparator
                    prefix={suffix ? null : symbol}
                    suffix={suffix ? symbol : null}
                  />
                </span>
              )}
              {/* <span> ₹{productItem.price}</span> */}
              <NumberFormat
                value={` ${productItem.price} `}
                displayType="text"
                thousandSeparator
                prefix={suffix ? null : `${symbol} `}
                suffix={suffix ? symbol : null}
              />
            </button>
          </div>
          {/* {quantity === 0 && machineType === 'big' && (
                        <h5 style={{fontWeight:"bold"}}>ADD</h5>
                    )} */}
        </div>

        {quantity > 0 && machineType === 'newuiwithoutphotowithoutscroll' && (
          <button
            type="button"
            data-testid="product-display-box-minus-button"
            className="product-card-remove-animated left-card-button"
            onClick={() => this.handleClick(-1 * quantity)}>
            <h5>x</h5>
          </button>
        )}
        {quantity > 0 && machineType === 'newuiwithoutphotowithoutscroll' && (
          <div className="product-card-quantity-animated right-card-button">
            <span>{quantity}</span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ cartReducer, machine }) => ({
  disableSelect: cartReducer.disableSelect,
  numberOfReset: cartReducer.numberOfReset,
  defaultProductURL: machine.machineDetails.defaultProductURL,
});

export default connect(mapStateToProps)(memo(NewItemCardWithoutScroll));
