import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  TIMER,
  MACHINE_STATUS,
  MODAL_REDUCER,
  REQUEST_REDUCER_TYPES,
  CASH_RETURNER_STAGE,
  CURRENCY_RETURNER_STAGE,
} from '../../actions/constants';
import { sendFeedback } from '../../actions/payment';
import { updateRequestFeedback } from '../../actions/db';
import OrderCompleteUI from '../../components/OrderComplete';
import { updateCashDisposed } from '../../actions/cashActions';

const OrderComplete = ({
  vendedItems,
  totalItemsFailed,
  paymentMethodUsed,
  maxItems,
  machineId,
  allowOrderBeforeCollection,
  getFeedback,
  requestId,
  machineStatus,
  closeModal,
  feedbackModalTimeout,
  setRequestIdAfterVend,
  cashReturnerStage,
  openPushRemainderToWallet,
  initCashReturner,
  handleCoinChangerInitTimeOut,
  initCurrencyReturnerStatus,
  resetCurrencyReturner,
  currencyReturnerStage,
  setCurrencyReturnerBroken,
  currencyModuleCreditLeft,
  totalAmountRequestedToDispensed,
  amountDispensedDispensed,
  isEnteredAmountMore,
  requestIdForAfterVend,
  coinResponseDetails,
}) => {
  const [feedbackSent, setfeedbackSent] = useState(false);
  const [isCoinReturner, setisCoinReturner] = useState(false);
  const [isCurrencyReturner, setisCurrencyReturner] = useState(false);
  let feedbackTimer;

  const close = () => {
    // close the modal
    closeModal();
  };

  const handleCurrencyReturnerInitTimeOut = () => {
    if (currencyReturnerStage === CURRENCY_RETURNER_STAGE.INIT) {
      setCurrencyReturnerBroken();
      openPushRemainderToWallet();
    }
  };

  const initCurrencyReturner = async () => {
    initCurrencyReturnerStatus();
    // await initCurrencyReturnerRefund("cash", requestId)// api call to init currency returner
    //   .then((response) => {
    //     if (response.error === "false") {
    //       setCurrencyReturnerDispensing();
    //       // set timeout when no response from currency modeule after 30 seconds of money returned
    //     } else {
    //       openPushRemainderToWallet();
    //     }
    //   })
  };

  useEffect(() => {
    // THIS IS NO T OPENING WALLET MODEL CHECK VALUES IN IF CONDITIOn
    const { cashPhoneOption } = JSON.parse(localStorage.getItem('cash'));

    if (
      cashReturnerStage === CASH_RETURNER_STAGE.DISPENSED &&
      totalAmountRequestedToDispensed > amountDispensedDispensed &&
      cashPhoneOption === 'coin_returner_with_phone_after_payment'
    ) {
      openPushRemainderToWallet();
    } else if (cashReturnerStage === CASH_RETURNER_STAGE.DISPENSED) {
      // close();
      feedbackTimer = setTimeout(() => close(), 1000 * feedbackModalTimeout);
      const requestData = {
        order_id: requestIdForAfterVend,
        machineId,
        amount: amountDispensedDispensed || 0,
      };

      updateCashDisposed(requestData);
      setRequestIdAfterVend(null);
    }

    if (
      currencyReturnerStage === CURRENCY_RETURNER_STAGE.DISPENSED &&
      currencyModuleCreditLeft > 0 &&
      cashPhoneOption === 'currency_return_after_payment'
    ) {
      // console.log('here in order complete', currencyReturnerStage, currencyModuleCreditLeft);
      openPushRemainderToWallet();
    } else if (currencyReturnerStage === CURRENCY_RETURNER_STAGE.DISPENSED) {
      // close();
      feedbackTimer = setTimeout(() => close(), 1000 * feedbackModalTimeout);
      // console.log('here in setting req id null', currencyReturnerStage, currencyModuleCreditLeft);
      setRequestIdAfterVend(null);
    }
  }, [cashReturnerStage, totalAmountRequestedToDispensed, amountDispensedDispensed, currencyReturnerStage]);

  useEffect(() => {
    // Start a timer
    const { cashPhoneOption } = JSON.parse(localStorage.getItem('cash'));
    let coinReturnerInitTimer;
    let currencyReturnerInitTimer;

    // console.log('cashPhoneOption', cashPhoneOption);
    // console.log('cashReturnerStage', cashReturnerStage);
    // console.log('isEnteredAmountMore', isEnteredAmountMore);

    if (allowOrderBeforeCollection) {
      // console.log('Feedback Timer Timeout:', feedbackModalTimeout);
      if (
        paymentMethodUsed === 'cash' &&
        cashPhoneOption === 'phone_after_payment' &&
        (vendedItems.length === 0 || totalItemsFailed !== 0 || isEnteredAmountMore)
      ) {
        setRequestIdAfterVend(requestId);
        openPushRemainderToWallet();
      } else if (
        paymentMethodUsed === 'cash' &&
        (cashPhoneOption === 'coin_returner_with_phone_after_payment' ||
          cashPhoneOption === 'coin_returner_with_skip') &&
        (vendedItems.length === 0 || totalItemsFailed !== 0 || isEnteredAmountMore)
      ) {
        setRequestIdAfterVend(requestId);
        setisCoinReturner(true);
        initCashReturner();
        // console.log('TIMER STARTED -11', cashReturnerStage);
        coinReturnerInitTimer = setTimeout(() => handleCoinChangerInitTimeOut(), 1000 * 30);
      } else if (
        paymentMethodUsed === 'cash' &&
        cashPhoneOption === 'currency_return_after_payment' &&
        (vendedItems.length === 0 || totalItemsFailed !== 0 || isEnteredAmountMore)
      ) {
        setRequestIdAfterVend(requestId);
        setisCurrencyReturner(true);
        initCurrencyReturner();
        currencyReturnerInitTimer = setTimeout(() => handleCurrencyReturnerInitTimeOut(), 1000 * 30);
      } else {
        // This is will show the status of vending and
        // if feedback is enabled then it will take feedback too

        feedbackTimer = setTimeout(() => close(), 1000 * feedbackModalTimeout);
      }
    }
    return () => {
      if (allowOrderBeforeCollection) {
        // console.log('TIMER STOPPED -2');
        window.clearTimeout(feedbackTimer, TIMER.FEEDBACK_TIMER);
      }
      // console.log('TIMER STOPPED -3');
      window.clearTimeout(currencyReturnerInitTimer, TIMER.CURRENCY_RETURNER_INIT);
      window.clearTimeout(coinReturnerInitTimer, TIMER.COIN_RETURNER_INIT);
      resetCurrencyReturner();
    };
  }, []);

  const giveFeedback = value => {
    if (!feedbackSent && machineStatus === MACHINE_STATUS.ONLINE) {
      sendFeedback({ value, requestId });
    } else if (machineStatus === MACHINE_STATUS.OFFLINE) {
      // Set Feedback in Local DB
      updateRequestFeedback(requestId, value);
    }
    setfeedbackSent(true);
    close();
  };
  // console.log('cashReturnerStage', cashReturnerStage);
  // console.log('totalCoinsDispensed', totalCoinsDispensed);
  // console.log('coinResponseDetails', coinResponseDetails);
  return (
    <OrderCompleteUI
      vendedItems={vendedItems}
      allowOrderBeforeCollection={allowOrderBeforeCollection}
      getFeedback={getFeedback}
      feedbackSent={feedbackSent}
      totalItemsFailed={totalItemsFailed}
      maxItems={maxItems}
      giveFeedback={giveFeedback}
      paymentMethodUsed={paymentMethodUsed}
      isCoinReturner={isCoinReturner}
      isCurrencyReturner={isCurrencyReturner}
      coinResponseDetails={coinResponseDetails}
      cashReturnerStage={cashReturnerStage}
      currencyReturnerStage={currencyReturnerStage}
    />
  );
};

const mapStateToProps = ({ machine, cashReducer, requestReducer, currencyReducer }) => ({
  requestIdForAfterVend: requestReducer.requestIdForAfterVend,
  feedbackModalTimeout: machine.machineDetails.feedbackModalTimeout,
  allowOrderBeforeCollection: machine.vendModal.allowOrderBeforeCollection,
  getFeedback: machine.vendModal.getFeedback,
  machineStatus: machine.machineStatus,
  machineId: machine.machineId,
  cashReturnerStage: cashReducer.cashReturnerStage,
  currencyReturnerStage: currencyReducer.currencyReturnerStage,
  currencyModuleCreditLeft: currencyReducer.creditLeft,
  totalAmountRequestedToDispensed: cashReducer.amountRequestedToDispense,
  totalCoinsDispensed: cashReducer.totalCoinsDispensed,
  coinResponseDetails: cashReducer.coinResponseDetails,
  amountDispensedDispensed: cashReducer.amountDispensed,
});

const mapDispatchToProps = dispatch => ({
  initCashReturner: () => dispatch({ type: CASH_RETURNER_STAGE.SET_STATUS, payload: CASH_RETURNER_STAGE.INIT }),
  setCurrencyReturnerBroken: () =>
    dispatch({ type: CURRENCY_RETURNER_STAGE.SET_STATUS, payload: CURRENCY_RETURNER_STAGE.BROKEN }),
  setCurrencyReturnerDispensing: () =>
    dispatch({ type: CURRENCY_RETURNER_STAGE.SET_STATUS, payload: CURRENCY_RETURNER_STAGE.DISPENSING }),
  initCurrencyReturnerStatus: () =>
    dispatch({ type: CURRENCY_RETURNER_STAGE.SET_STATUS, payload: CURRENCY_RETURNER_STAGE.INIT }),
  setRequestIdAfterVend: payload => dispatch({ type: REQUEST_REDUCER_TYPES.SET_REQUEST_ID_AFTER_VEND, payload }),
  resetCurrencyReturner: () => dispatch({ type: CURRENCY_RETURNER_STAGE.RESET, payload: {} }),
  openPushRemainderToWallet: () => dispatch({ type: MODAL_REDUCER.OPEN_PUSH_REMAINDER_TO_WALLET_MODAL, payload: true }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderComplete);
