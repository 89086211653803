/* eslint-disable no-return-assign */
import React, { Fragment, memo } from 'react';
import uniqid from 'uniqid';
import { WithImageRowItemCards, ThreeBubbleLoader } from './index';

// export default ({ productsData, updateCart, checkout, resetCart, isProductLoading }) => {
class AtlasWithLockerVariant extends React.Component {
  shouldComponentUpdate(np) {
    const { productsData, isProductLoading } = { ...this.props };
    return JSON.stringify(productsData) !== JSON.stringify(np.productsData) || np.isProductLoading !== isProductLoading;
  }

  render() {
    const { productsData, updateCart, isProductLoading, machineType, productDisplayPage, maxItems } = {
      ...this.props,
    };
    let style = {};

    style = { height: '40vh', overflow: 'scroll' };

    this.atlasMachineProductsData = {};
    this.lockerMachineProductsData = {};
    if (productsData) {
      this.lockerMachineProductsData = productsData.reduce((acc, obj) => {
        let key;
        if (obj.show_pos.substring(0, 1).toUpperCase() !== 'L') return acc;
        if (obj.row) {
          key = obj.row;
        } else {
          key = obj.pos.substring(0, 1).toUpperCase();
        }
        if (!(key in acc)) {
          acc[key] = [];
        }
        const tempObj = {};
        Object.keys(obj).forEach(d => {
          if (d !== 'cart_quantity' && d !== 'total_units') {
            tempObj[d] = obj[d];
          }
          return true;
        });
        acc[key].push(tempObj);
        return acc;
      }, {});

      this.atlasMachineProductsData = productsData.reduce((acc, obj) => {
        let key;
        if (obj.show_pos.substring(0, 1).toUpperCase() === 'L') return acc;
        if (obj.row) {
          key = obj.row;
        } else {
          key = obj.pos.substring(0, 1).toUpperCase();
        }
        if (!(key in acc)) {
          acc[key] = [];
        }
        const tempObj = {};
        Object.keys(obj).forEach(d => {
          if (d !== 'cart_quantity' && d !== 'total_units') {
            tempObj[d] = obj[d];
          }
          return true;
        });
        acc[key].push(tempObj);
        return acc;
      }, {});
    }

    if (isProductLoading) {
      return (
        <div className="flex-container">
          <ThreeBubbleLoader />
        </div>
      );
    }
    if (!productsData || productsData.length === 0) {
      return (
        <div>
          <h1>Machine is empty</h1>
          <h5>Please contact support</h5>
        </div>
      );
    }
    // console.log('isProductLoading', isProductLoading);
    // console.log('productsData.length', productsData.length);

    if (!isProductLoading || productsData.length > 0) {
      return (
        <>
          <div className="products-separator">
            <h2>Vending Machine</h2>
          </div>
          <div data-test="products-box" style={style}>
            {Object.keys(this.atlasMachineProductsData).map(data => {
              return (
                <Fragment key={uniqid()}>
                  <WithImageRowItemCards
                    id={uniqid()}
                    machineType={machineType}
                    productsData={this.atlasMachineProductsData[data]}
                    updateCart={updateCart}
                    isProductLoading={isProductLoading}
                    productDisplayPage={productDisplayPage}
                    maxItems={maxItems}
                  />
                </Fragment>
              );
            })}
          </div>
          <div className="products-separator">
            <h2>Locker Machine</h2>
          </div>
          <div data-test="products-box" style={style}>
            {Object.keys(this.lockerMachineProductsData).map(data => {
              return (
                <Fragment key={uniqid()}>
                  <WithImageRowItemCards
                    id={uniqid()}
                    machineType={machineType}
                    productsData={this.lockerMachineProductsData[data]}
                    updateCart={updateCart}
                    isProductLoading={isProductLoading}
                    productDisplayPage={productDisplayPage}
                    maxItems={maxItems}
                  />
                </Fragment>
              );
            })}
          </div>
        </>
      );
    }
    return <h5>Page not loaded</h5>;
  }
}
export default memo(AtlasWithLockerVariant);
