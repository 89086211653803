class MqttMessageManager {
  constructor() {
    this.messageMap = new Map();
    this.timer = setInterval(() => {
      this.removeOldMessages();
    }, 2000);
  }

  addMessage(topic, message) {
    const existingMessages = this.messageMap.get(topic) || [];

    // Check for duplicates
    const isDuplicate = existingMessages.some(msg => msg === message);
    if (isDuplicate) {
      return false;
    }

    // Add message to the map
    existingMessages.push({
      message,
      timestamp: Date.now(),
    });
    this.messageMap.set(topic, existingMessages);

    return true;
  }

  removeMessage(topic, message) {
    const existingMessages = this.messageMap.get(topic) || [];

    // Remove message from the array
    const index = existingMessages.findIndex(msg => msg.message === message);
    if (index !== -1) {
      existingMessages.splice(index, 1);
      this.messageMap.set(topic, existingMessages);
    }
  }

  removeOldMessages() {
    const currentTime = Date.now();
    this.messageMap.forEach((messages, topic) => {
      const filteredMessages = messages.filter(msg => currentTime - msg.timestamp <= 60000);
      if (filteredMessages.length === 0) {
        this.messageMap.delete(topic);
      } else {
        this.messageMap.set(topic, filteredMessages);
      }
    });
  }

  destroy() {
    clearInterval(this.timer);
  }
}

export default MqttMessageManager;
