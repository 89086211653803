export const alertMail = 'machine1002@wendor.in';

export const ERROR_CODE = {
  VMC_ERROR: 110,
  VMC_BROKEN: 120,
  NOT_ERROR: 42,
  MQTT_MESSAGE_PARSE_ERROR: 220,
  REFILL_MODE: 210,
};

export const TIMER_VALUES = {
  // In seconds
  RETRY_ESP_CONNECT: 1 * 15,
  RFID_INIT_WAIT: 25,
};

export const CART_REDUCER = {
  SET_PRODUCTS_WITH_CART_QUANTITY: 'SET_PRODUCTS_WITH_CART_QUANTITY',
  UPDATE_CART: 'UPDATE_CART',
  UPDATE_PRODUCT_QTY: 'UPDATE_PRODUCT_QTY',
  RESET_CART: 'RESET_CART',
  DISABLE_SELECT: 'DISABLE_SELECT',
};

export const STATE_OF_MACHINE = {
  WHEN_IN_PAYMENT_PAGE: {
    SOCKET_EVENTS: {
      KEYPAD_KEYPRESS: 'KEYPAD_SERVICE',
    },
    STATE_OF_MACHINE_AT_PAGE: {
      INPUT_SERVICE_MODE_OPTION: 'INPUT_SERVICE_MODE_OPTION',
    },
  },
};

export const VEND_STATUS = {
  NOT_VENDING: 'VEND_STATUS_NOT VENDING',
  VENDING: 'VEND_STATUS_VENDING',
  VEND_COMPLETE: 'VEND_STATUS_VEND COMPLETE',
  BROKEN: 'VEND_STATUS_NOT WORKING',
  INITIATING: 'VEND_STATUS_INITIATING',
};
export const LOG = {
  ADD_CLICK_TO_LOG: 'ADD_CLICK_TO_LOG',
  SCREEN_SAVER_WAS_ACTIVE: 'SCREEN_SAVER_WAS_ACTIVE',
  SCREEN_SAVER_WAS_NOT_ACTIVE: 'SCREEN_SAVER_WAS_NOT_ACTIVE',
};
export const MACHINE = {
  SET_LOGGED_IN: 'MACHINE_SET_LOGGED_IN',
  SET_LOGGED_OUT: 'MACHINE_SET_LOGGED_OUT',
  REFRESH_MACHINE: 'MACHINE_REFRESH_MACHINE',
  SET_PAYMENT_METHODS_FOR_MACHINE: 'MACHINE_SET_PAYMENT_METHODS_FOR_MACHINE',
  MACHINE_TYPE_DID_NOT_MATCH: 'MACHINE_MACHINE_TYPE_DID_NOT_MATCH',
  MACHINE_TYPE_DID_MATCH: 'MACHINE_MACHINE_TYPE_DID_MATCH',
  CLOSE_MACHINE_TYPE_DID_NOT_MATCH_MODAL: 'MACHINE_CLOSE_MACHINE_TYPE_DID_NOT_MATCH_MODAL',
  SET_MACHINE_ONLINE: 'MACHINE_SET_MACHINE_ONLINE',
  SET_MACHINE_OFFLINE: 'MACHINE_SET_MACHINE_OFFLINE',
  SET_MACHINE_TOGGLE: 'SET_MACHINE_TOGGLE',
  SET_TOGGLE_FLAG: 'SET_TOGGLE_FLAG',
  SET_REFRESH_FLAG: 'SET_REFRESH_FLAG',
  SET_MQTT_STATUS: 'MACHINE_SET_MQTT_STATUS',
  SET_ESP_STATUS: 'MACHINE_SET_ESP_STATUS',
  SET_IS_CONFIGURING: 'MACHINE_SET_IS_CONFIGURING',
  SET_MACHINE_BALANCE: 'MACHINE_SET_MACHINE_BALANCE',
  SET_KIOSK_UPDATE: 'SET_KIOSK_UPDATE',
};

export const MODAL_REDUCER = {
  RESET_MODALS: 'MODAL_REDUCER_RESET_MODALS',
  OPEN_QR_MODAL: 'MODAL_REDUCER_OPEN_QR_MODAL',
  OPEN_VEND_MODAL: 'MODAL_REDUCER_OPEN_VEND_MODAL',
  OPEN_FEEDBACK_MODAL: 'MODAL_REDUCER_OPEN_FEEDBACK_MODAL',
  OPEN_PAYMENT_TIMEDOUT_MODAL: 'MODAL_REDUCER_OPEN_PAYMENT_TIMEDOUT_MODAL',
  OPEN_ERROR_MODAL: 'MODAL_REDUCER_OPEN_ERROR_MODAL',
  CLOSE_ALL_MODAL: 'MODAL_REDUCER_CLOSE_ALL_MODAL',
  OPEN_REFUND_MODAL: 'MODAL_REDUCER_OPEN_REFUND_MODAL',
  OPEN_PUSH_REMAINDER_TO_WALLET_MODAL: 'MODAL_REDUCER_OPEN_PUSH_REMAINDER_TO_WALLET_MODAL',
};
export const PI_REDUCER = {
  INITIATE_SYSTEM: 'PI_REDUCER_INITIATE_SYSTEM',
};
export const VEND_REDUCER = {
  START: 'VEND_REDUCER_START VENDING',
  COMPLETED: 'VEND_REDUCER_VENDING COMPLETE',
  UPDATE: 'VEND_REDUCER_UPDATE',
  STATUS_UPDATE: 'VEND_REDUCER_STATUS UPDATE',
  RESET: 'VEND_REDUCER_RESET',
  ITEMS_TO_VEND: 'VEND_REDUCER_ITEMS TO VEND',
  ORDER_PICKED: 'VEND_REDUCER_AFTER_VEND_ORDER_PICKED',
  STATUS_BROKEN: 'VEND_REDUCE_VENDING BROKEN',
  UPDATE_CUSTOM_MESSAGE: 'VEND_REDUCER_UPDATE_CUSTOM_MESSAGE',
};

export const CASH_REDUCER = {
  UPDATE_CASH_MODULE: 'CASH_REDUCER_UPDATE_CASH_MODULE',
};

export const CASH_MODULE_STATUS = {
  GETTING_NUMBER: 'CASH_MODULE_STATUS_GETTING_NUMBER',
  ERROR_NUMBER_SAVED: 'CASH_MODULE_STATUS_ERROR_NUMBER_SAVED',
  NUMBER_SAVED: 'CASH_MODULE_STATUS_NUMBER_SAVED',
  LOADING: 'CASH_MODULE_STATUS_CASH_MODULE_STATUS_LOADING',
  NOT_ACCEPTING: 'CASH_MODULE_STATUS_NOT ACCEPTING CASH',
  INITIATED: 'CASH_MODULE_STATUS_INITIATED',
  INITIATING: 'CASH_MODULE_STATUS_INITIATING CASH MACHINE',
  ACCEPTING: 'CASH_MODULE_STATUS_ACCEPTING CASH',
  BROKEN: 'CASH_MODULE_STATUS_SORRY CASH MACHINE NOT WORKING',
  SAVE_PHONE_NUMBER: 'CASH_MODULE_STATUS_SAVE_PHONE_NUMBER',
  REMOVE_PHONE_NUMBER: 'CASH_MODULE_STATUS_REMOVE_PHONE_NUMBER',
};
export const RFID_MODULE = {
  UPDATE_RFID_MODULE: 'RFID_MODULE_UPDATE_RFID_MODULE',
  ACCEPTING_PIN: 'RFID_MODULE_ACCEPTING_PIN',
  SET_CARD_DETAILS: 'RFID_MODULE_SET_CARD_DETAILS',
  SET_USER_DETAILS: 'RFID_MODULE_SET_USER_DETAILS',
};
export const RFID_MODULE_STATUS = {
  INITIATING: 'RFID_MODULE_STATUS_INITIATING',
  INITIATED: 'RFID_MODULE_STATUS_INITIATED',
  BROKEN: 'RFID_MODULE_STATUS_SORRY CASH MACHINE',
  GETTING_CARD: 'RFID_MODULE_STATUS_GETTING_CARD',
  ERROR_CARD: 'RFID_MODULE_STATUS_ERROR_CARD',
  ERROR_NUMBER_SAVED: 'RFID_MODULE_STATUS_ERROR_NUMBER_SAVED',
  LOADING: 'RFID_MODULE_STATUS_CASH_MODULE_STATUS_LOADING',
  NOT_ACCEPTING: 'RFID_MODULE_STATUS_NOT ACCEPTING',
  ACCEPTING: 'RFID_MODULE_STATUS_ACCEPTING CASH',
  ACCEPTING_PIN: 'RFID_MODULE_STATUS_ACCEPTING_PIN',
  SAVE_PHONE_NUMBER: 'RFID_MODULE_STATUS_SAVE_PHONE_NUMBER',
  REMOVE_PHONE_NUMBER: 'RFID_MODULE_STATUS_REMOVE_PHONE_NUMBER',
};

export const MQTT_STATUS = {
  CONNECTED: 'MQTT_STATUS_MQTT_CONNECTED',
  CONNECTING: 'MQTT_STATUS_MQTT_CONNECTING',
  DISCONNECTED: 'MQTT_STATUS_MQTT_DISCONNECTED',
};

export const ESP_STATUS = {
  CONNECTED: 'ESP_STATUS_ESP_CONNECTED',
  CONNECTING: 'ESP_STATUS_ESP_CONNECTING',
  DISCONNECTED: 'ESP_STATUS_ESP_DISCONNECTED',
};

export const MACHINE_STATUS = {
  ONLINE: 'MACHINE_STATUS_ONLINE',
  OFFLINE: 'MACHINE_STATUS_OFFLINE',
  ON: 'MACHINE_STATUS_ON',
  OFF: 'MACHINE_STATUS_OFF',
};

export const PAYMENT_STATUS = {
  COMPLETED: 'PAYMENT_STATUS_COMPLETED',
  NOT_INITIATED: 'PAYMENT_STATUS_NOT_INITIATED',
  INITIATED: 'PAYMENT_STATUS_INITIATED',
  PARTIAL: 'PAYMENT_STATUS_PARTIAL',
  FAILED: 'PAYMENT_STATUS_FAILED',
};

export const REQUEST_REDUCER_TYPES = {
  RESET_REQUEST_ID: 'REQUEST_REDUCER_RESET_REQUEST_ID',
  SET_REQUEST_ID: 'REQUEST_REDUCER_TYPES_SET_REQUEST_ID',
  UPDATE_TOTAL_AMOUNT: 'REQUEST_REDUCER_TYPES_UPDATE_TOTAL_AMOUNT',
  FINAL_PAYMENT: 'REQUEST_REDUCER_TYPES_FINAL_PAYMENT',
  SET_REQUEST_ID_AFTER_VEND: 'REQUEST_REDUCER_TYPES_SET_REQUEST_ID_AFTER_VEND',
  SET_IS_PAYMENT_CANCEL: 'REQUEST_REDUCER_SET_IS_PAYMENT_CANCEL',
  UNSET_REQUEST_ID_AFTER_VEND: 'REQUEST_REDUCER_TYPES_UNSET_REQUEST_ID_AFTER_VEND',
};

export const SENDING_MONEY_STATUS = {
  NOT_INITIATED: 'SENDING_MONEY_STATUS_NOT_INITIATED',
  INITIATED: 'SENDING_MONEY_STATUS_INITIATED',
  COMPLETED: 'SENDING_MONEY_STATUS_COMPLETED',
  FAILED: 'SENDING_MONEY_STATUS_FAILED',
};

export const SMS_SENT_STATUS = {
  NOT_INITIATED: 'SMS_SENT_STATUS_NOT_INITIATED',
  INITIATED: 'SMS_SENT_STATUS_INITIATED',
  COMPLETED: 'SMS_SENT_STATUS_COMPLETED',
  FAILED: 'SMS_SENT_STATUS_FAILED',
};

export const SWIFT_WALLET_OTP_STATUS = {
  NOT_INITIATED: 'SWIFT_WALLET_OTP_STATUS_NOT_INITIATED',
  SENDING: 'SWIFT_WALLET_OTP_STATUS_SENDING',
  SENT: 'SWIFT_WALLET_OTP_STATUS_SENT',
  FAILED: 'SWIFT_WALLET_OTP_STATUS_FAILED',
};

export const FETCH_SODEXO_QR_STATES = {
  IDLE: 'IDLE',
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const TIMER = {
  PING_30_MIN: 'TIMER_PING_30_MIN',
  VMC_15_SEC_TIMMER: 'TIMER_VMC_15_SEC_TIMMER',
  RFID_INIT_TIMMER: 'TIMER_RFID_INIT_TIMMER ',
  RETRY_5_SEC_TIMMER: 'TIMER_RETRY_5_SEC_TIMMER',
  ESP_RESPONSE_TIMEOUT_10_SEC: 'TIMER_ESP_RESPONSE_TIMEOUT_10_SEC',
  INITIATING_CASH_MACHINE_30_SEC: 'TIMER_INITIATING_CASH_MACHINE_30_SEC',
  VENDING_INTERVAL_TIMER_20_SEC: 'TIMER_VENDING_INTERVAL_TIMER_20_SEC',
  VENDING_TIME_OUT_30_SEC: 'TIMER_VENDING_TIME_OUT_30_SEC',
  SCREEN_SAVER_TIMMER: 'TIMER_SCREEN_SAVER_TIMMER',
  FEEDBACK_TIMER: 'TIMER_FEEDBACK_TIMER',
  PAY_COMPLETE_TIMEOUT: 'TIMER_PAY_COMPLETE_TIMEOUT',
  CHECK_PAYMENT_STATUS_TIMMER: 'TIMER_CHECK_PAYMENT_STATUS_TIMMER_15',
  COIN_RETURNER_INIT: 'TIMER_COIN_RETURNER_INIT',
  CURRENCY_RETURNER_INIT: 'TIMER_CURRENCY_RETURNER_INIT',
};

export const CASH_RETURNER_STAGE = {
  SET_STATUS: 'CASH_RETURNER_STAGE_CASH_DISPANCER_SET_STATUS',
  INIT: 'CASH_RETURNER_STAGE_INIT',
  IDLE: 'CASH_RETURNER_STAGE_IDLE',
  RESET: 'CASH_RETURNER_STAGE_RESET',
  BROKEN: 'CASH_RETURNER_STAGE_BROKEN',
  DISPENSING: 'CASH_RETURNER_STAGE_DISPENSING',
  DISPENSED: 'CASH_RETURNER_STAGE_DISPENSED',
};

export const CURRENCY_REDUCER = {
  UPDATE_CASH_MODULE: 'CURRENCY_REDUCER_UPDATE_CASH_MODULE',
};

export const CURRENCY_MODULE_STATUS = {
  GETTING_NUMBER: 'CURRENCY_MODULE_STATUS_GETTING_NUMBER',
  ERROR_NUMBER_SAVED: 'CURRENCY_MODULE_STATUS_ERROR_NUMBER_SAVED',
  NUMBER_SAVED: 'CURRENCY_MODULE_STATUS_NUMBER_SAVED',
  LOADING: 'CURRENCY_MODULE_STATUS_CURRENCY_MODULE_STATUS_LOADING',
  NOT_ACCEPTING: 'CURRENCY_MODULE_STATUS_NOT ACCEPTING CASH',
  INITIATED: 'CURRENCY_MODULE_STATUS_INITIATED',
  INITIATING: 'CURRENCY_MODULE_STATUS_INITIATING CASH MACHINE',
  ACCEPTING: 'CURRENCY_MODULE_STATUS_ACCEPTING CASH',
  BROKEN: 'CURRENCY_MODULE_STATUS_SORRY CASH MACHINE NOT WORKING',
  SAVE_PHONE_NUMBER: 'CURRENCY_MODULE_STATUS_SAVE_PHONE_NUMBER',
  REMOVE_PHONE_NUMBER: 'CURRENCY_MODULE_STATUS_REMOVE_PHONE_NUMBER',
};

export const CURRENCY_RETURNER_STAGE = {
  SET_STATUS: 'CURRENCY_RETURNER_STAGE_CURRENCY_DISPANCER_SET_STATUS',
  INIT: 'CURRENCY_RETURNER_STAGE_INIT',
  IDLE: 'CURRENCY_RETURNER_STAGE_IDLE',
  RESET: 'CURRENCY_RETURNER_STAGE_RESET',
  BROKEN: 'CURRENCY_RETURNER_STAGE_BROKEN',
  DISPENSING: 'CURRENCY_RETURNER_STAGE_DISPENSING',
  DISPENSED: 'CURRENCY_RETURNER_STAGE_DISPENSED',
};

export const LANGUAGE_REDUCER = {
  SET_VALUES: 'LANGUAGE_REDUCER_SET_VALUES',
};

export const COLORS = {
  GREY: '#888',
  YELLOW: '#f7b32b',
  RED: '#d80000',
  GREEN: '#02842a',
  PRIMARY: '#5793fd',
};

export const MACHINE_TYPE_SETTINGS = {
  big: {
    productDisplayPage: {
      screensaverURL: 'https://wendor-kiosk-images.s3.ap-south-1.amazonaws.com/images/screensaver.png',
      showScreensaver: false,
      cartTimeout: 30000,
      screensaverTimeout: 60000,

      showName: false,
      showImages: false,
      showPrice: true,
      showDiscount: false,
      showFooterSubText: true,
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel']
      showPosition: true,
      showPaymentMethods: false,
      showPaymentButton: true,
      showStartOrderButton: false,
      showInfo: false,
      showLabel: false,
      hideTopHeadImage: false,
      showExpiryDate: false,
      errorImageURL: '/images/drawing_error_page.svg',
      topHeadImageURL: null,
      topHeadImageSize: 'sm', // sm, lg
      slidesToShow: 5,
      slidesToScroll: 3,
      backgroundImage: 'background.jpg',
      backgroundColor: '#999',
      useCarouselHeader: false,
      customHeaderVariant: '',
    },
    machineDetails: {
      maxItems: 5,
      vmcPingTimeout: 20, // In seconds
      vmcPingInterval: 900, // In seconds
      feedbackModalTimeout: 10, // In seconds
      defaultProductURL: null,
      modalSize: 'md', // 'sm', 'md', 'lg', 'xl'
    },
    paymentModal: {
      paymentTimeout: 800, // In seconds
      cartDetails: {
        cartViewType: 'list', // 'list', 'cards'
        showCartData: true,
        cartPosition: 'bottom', // ['top', 'bottom']
        productDetails: {
          // if showCartData is true and cartViewType is 'cards', only then use this
          showImage: true,
          showName: true,
          showPosition: true,
          showPrice: true,
          showQuantity: true,
          showDescription: true,
        },
      },
      showOrderId: true,
      playSound: false,
      paymentPos: 'vertical-left', // ["vertical-left", "horizontal-top", "horizontal-bottom", "vertical-right"]
    },
    vendModal: {
      vendInitMaxTime: 100, // In Seconds
      vendItemMaxTime: 100, // In seconds
      vendingItemCardDetails: {
        showImage: false,
        showName: false,
        showPosition: true,
        showPrice: false,
      },
      showOrderId: true,
      allowOrderBeforeCollection: true,
      getFeedback: true,
      showVendingCardDetails: true,
      showVendingItemDetails: false,
    },
    refillPage: {
      loginTimeout: 300, // In seconds
      refillLoginImageURL: '/full_logo.png', // refill Login Image URL
      productDetails: {
        showImage: true,
        showName: true,
        showPosition: true,
        showPrice: true,
        showQuantity: true,
      },
      showTestRowButton: false,
      showHomeMotorButton: false,
      showOpenSettingsButton: false,
      backgroundColor: '#999',
      backgroundImage: 'background.jpg',
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel' ]
    },
    rfidModule: {
      showRfidUserDetails: true,
    },
  },
  rowproductselect: {
    productDisplayPage: {
      screensaverURL: 'https://wendor-kiosk-images.s3.ap-south-1.amazonaws.com/images/screensaver.png',
      showScreensaver: false,
      cartTimeout: 30000,
      screensaverTimeout: 60000,
      showName: true,
      showImages: true,
      showHeaderImage: true,
      showPrice: true,
      showDiscount: false,
      showFooterSubText: false,
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel']
      showPosition: true,
      showPaymentMethods: false,
      showPaymentButton: true,
      showStartOrderButton: false,
      showInfo: false,
      showLabel: false,
      showExpiryDate: false,
      errorImageURL: '/images/drawing_error_page.svg',
      topHeadImageURL: null,
      hideTopHeadImage: true,
      showTopHeadText: true,
      fullLenghtPayResetButton: true,
      topHeadImageSize: 'sm', // sm, lg
      slidesToShow: 5,
      slidesToScroll: 3,
      backgroundImage: '',
      backgroundColor: '#fff',
      useCarouselHeader: false,
      customHeaderVariant: '',
    },
    machineDetails: {
      maxItems: 5,
      vmcPingTimeout: 20, // In seconds
      vmcPingInterval: 900, // In seconds
      feedbackModalTimeout: 10, // In seconds
      defaultProductURL: null,
      modalSize: 'md', // 'sm', 'md', 'lg', 'xl'
    },
    paymentModal: {
      paymentTimeout: 800, // In seconds
      cartDetails: {
        cartViewType: 'list', // 'list', 'cards'
        showCartData: true,
        cartPosition: 'bottom', // ['top', 'bottom']
        productDetails: {
          // if showCartData is true and cartViewType is 'cards', only then use this
          showImage: true,
          showName: true,
          showPosition: true,
          showPrice: true,
          showQuantity: true,
          showDescription: true,
        },
      },
      showOrderId: true,
      playSound: false,
      paymentPos: 'vertical-left', // ["vertical-left", "horizontal-top", "horizontal-bottom", "vertical-right"]
    },
    vendModal: {
      vendInitMaxTime: 100, // In Seconds
      vendItemMaxTime: 100, // In seconds
      vendingItemCardDetails: {
        showImage: false,
        showName: false,
        showPosition: true,
        showPrice: false,
      },
      showOrderId: true,
      allowOrderBeforeCollection: true,
      getFeedback: true,
      showVendingCardDetails: true,
      showVendingItemDetails: false,
    },
    refillPage: {
      loginTimeout: 300, // In seconds
      refillLoginImageURL: '/full_logo.png', // refill Login Image URL
      productDetails: {
        showImage: true,
        showName: true,
        showPosition: true,
        showPrice: true,
        showQuantity: true,
      },
      showTestRowButton: false,
      showHomeMotorButton: false,
      showOpenSettingsButton: false,
      backgroundColor: '#999',
      backgroundImage: 'background.jpg',
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel' ]
    },
    rfidModule: {
      showRfidUserDetails: true,
    },
  },
  selectionbyproductid: {
    productDisplayPage: {
      screensaverURL: 'https://wendor-kiosk-images.s3.ap-south-1.amazonaws.com/images/screensaver.png',
      showScreensaver: false,
      cartTimeout: 30000,
      screensaverTimeout: 60000,
      showName: true,
      showImages: true,
      showHeaderImage: true,
      showPrice: true,
      showDiscount: false,
      showFooterSubText: false,
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel']
      showPosition: true,
      showPaymentMethods: false,
      showPaymentButton: true,
      showStartOrderButton: false,
      showInfo: false,
      showLabel: false,
      showExpiryDate: false,
      errorImageURL: '/images/drawing_error_page.svg',
      topHeadImageURL: null,
      hideTopHeadImage: true,
      showTopHeadText: true,
      fullLenghtPayResetButton: true,
      topHeadImageSize: 'sm', // sm, lg
      slidesToShow: 5,
      slidesToScroll: 3,
      backgroundImage: '',
      backgroundColor: '#fff',
      useCarouselHeader: false,
      customHeaderVariant: '',
    },
    machineDetails: {
      maxItems: 5,
      vmcPingTimeout: 20, // In seconds
      vmcPingInterval: 900, // In seconds
      feedbackModalTimeout: 10, // In seconds
      defaultProductURL: null,
      modalSize: 'md', // 'sm', 'md', 'lg', 'xl'
    },
    paymentModal: {
      paymentTimeout: 800, // In seconds
      cartDetails: {
        cartViewType: 'list', // 'list', 'cards'
        showCartData: true,
        cartPosition: 'bottom', // ['top', 'bottom']
        productDetails: {
          // if showCartData is true and cartViewType is 'cards', only then use this
          showImage: true,
          showName: true,
          showPosition: true,
          showPrice: true,
          showQuantity: true,
          showDescription: true,
        },
      },
      showOrderId: true,
      playSound: false,
      paymentPos: 'vertical-left', // ["vertical-left", "horizontal-top", "horizontal-bottom", "vertical-right"]
    },
    vendModal: {
      vendInitMaxTime: 100, // In Seconds
      vendItemMaxTime: 100, // In seconds
      vendingItemCardDetails: {
        showImage: false,
        showName: false,
        showPosition: true,
        showPrice: false,
      },
      showOrderId: true,
      allowOrderBeforeCollection: true,
      getFeedback: true,
      showVendingCardDetails: true,
      showVendingItemDetails: false,
    },
    refillPage: {
      loginTimeout: 300, // In seconds
      refillLoginImageURL: '/full_logo.png', // refill Login Image URL
      productDetails: {
        showImage: true,
        showName: true,
        showPosition: true,
        showPrice: true,
        showQuantity: true,
      },
      showTestRowButton: false,
      showHomeMotorButton: false,
      showOpenSettingsButton: false,
      backgroundColor: '#999',
      backgroundImage: 'background.jpg',
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel' ]
    },
    rfidModule: {
      showRfidUserDetails: true,
    },
  },
  imshow: {
    productDisplayPage: {
      screensaverURL: 'https://wendor-kiosk-images.s3.ap-south-1.amazonaws.com/images/screensaver.png',
      showScreensaver: false,
      cartTimeout: 30000,
      screensaverTimeout: 60000,
      showName: true,
      showImages: true,
      showPrice: true,
      showDiscount: true,
      showFooterSubText: true,
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel']
      showPosition: true,
      showPaymentMethods: false,
      showPaymentButton: true,
      showStartOrderButton: false,
      showInfo: false,
      showLabel: false,
      showExpiryDate: false,
      errorImageURL: '/images/drawing_error_page.svg',
      topHeadImageURL: '/easyFreshLogo.png',
      topHeadImageSize: 'lg', // sm, lg
      slidesToShow: 3,
      slidesToScroll: 1,
      backgroundImage: '',
      backgroundColor: '#fff',
      useCarouselHeader: false,
      customHeaderVariant: '',
    },
    machineDetails: {
      maxItems: 1,
      vmcPingTimeout: 20, // In seconds
      vmcPingInterval: 900, // In seconds
      feedbackModalTimeout: 10, // In seconds
      defaultProductURL: null,
      modalSize: 'lg', // 'sm', 'md', 'lg', 'xl'
    },
    paymentModal: {
      paymentTimeout: 800, // In seconds
      cartDetails: {
        cartViewType: 'cards', // 'list', 'cards'
        showCartData: true,
        cartPosition: 'top', // ['top', 'bottom']
        productDetails: {
          // if showCartData is true and cartViewType is 'cards', only then use this
          showImage: true,
          showName: true,
          showPosition: true,
          showPrice: true,
          showQuantity: true,
          showDescription: true,
        },
      },
      showOrderId: true,
      playSound: false,
      paymentPos: 'horizontal-top', // ["vertical-left", "horizontal-top", "horizontal-bottom", "vertical-right"]
    },
    vendModal: {
      vendInitMaxTime: 100, // In Seconds
      vendItemMaxTime: 100, // In seconds
      vendingItemCardDetails: {
        showImage: false,
        showName: true,
        showPosition: true,
        showPrice: true,
      },
      showOrderId: true,
      showVendingItemDetails: true,
      showVendingCardDetails: false,
      getFeedback: false,
      allowOrderBeforeCollection: false,
    },
    refillPage: {
      loginTimeout: 300, // In seconds
      refillLoginImageURL: '/full_logo.png', // refill Login Image URL
      productDetails: {
        showImage: true,
        showName: true,
        showPosition: true,
        showPrice: true,
        showQuantity: true,
      },
      showTestRowButton: false,
      showHomeMotorButton: false,
      showOpenSettingsButton: false,
      backgroundColor: '#999',
      backgroundImage: '',
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel' ]
    },
    rfidModule: {
      showRfidUserDetails: true,
    },
  },
  small: {
    productDisplayPage: {
      screensaverURL: 'https://wendor-kiosk-images.s3.ap-south-1.amazonaws.com/images/screensaver.png',
      showScreensaver: false,
      cartTimeout: 30000,
      screensaverTimeout: 60000,
      showName: true,
      showImages: true,
      showPrice: true,
      showDiscount: true,
      showFooterSubText: true,
      viewType: 'carousel', // ['2d-grid', 'list', 'carousel']
      showPosition: false,
      showPaymentMethods: false,
      showPaymentButton: true,
      showStartOrderButton: false,
      showInfo: false,
      showLabel: false,
      showExpiryDate: false,
      topHeadImageURL: '/full_logo.png',
      errorImageURL: '/images/drawing_error_page.svg',
      topHeadImageSize: 'sm', // sm, lg
      slidesToShow: 1,
      slidesToScroll: 1,
      backgroundImage: 'background.jpg',
      backgroundColor: '#999',
      useCarouselHeader: false,
      customHeaderVariant: '',
    },
    machineDetails: {
      maxItems: 1,
      vmcPingTimeout: 20, // In seconds
      vmcPingInterval: 900, // In seconds
      feedbackModalTimeout: 10, // In seconds
      defaultProductURL: null,
      modalSize: 'md', // 'sm', 'md', 'lg', 'xl'
    },
    paymentModal: {
      paymentTimeout: 800, // In seconds
      cartDetails: {
        cartViewType: 'list', // 'list', 'cards'
        showCartData: false,
        cartPosition: 'bottom', // ['top', 'bottom']
        productDetails: {
          // if showCartData is true and cartViewType is 'cards', only then use this
          showImage: true,
          showName: true,
          showPosition: true,
          showPrice: true,
          showQuantity: true,
          showDescription: true,
        },
      },
      showOrderId: true,
      playSound: false,
      paymentPos: 'vertical-left', // ["vertical-left", "horizontal-top", "horizontal-bottom", "vertical-right"]
    },
    vendModal: {
      vendInitMaxTime: 100, // In Seconds
      vendItemMaxTime: 100, // In seconds
      vendingItemCardDetails: {
        showImage: false,
        showName: false,
        showPosition: true,
        showPrice: false,
      },
      showOrderId: true,
      allowOrderBeforeCollection: true,
      showVendingItemDetails: true,
      getFeedback: true,
      showVendingCardDetails: false,
    },
    refillPage: {
      loginTimeout: 300, // In seconds
      refillLoginImageURL: '/full_logo.png', // refill Login Image URL
      productDetails: {
        showImage: false,
        showName: false,
        showPosition: true,
        showPrice: true,
        showQuantity: true,
      },
      showTestRowButton: false,
      showHomeMotorButton: false,
      showOpenSettingsButton: false,
      backgroundColor: '#999',
      backgroundImage: 'background.jpg',
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel' ]
    },
    rfidModule: {
      showRfidUserDetails: true,
    },
  },
  newuiwithoutphoto: {
    productDisplayPage: {
      screensaverURL: 'https://wendor-kiosk-images.s3.ap-south-1.amazonaws.com/images/screensaver.png',
      showScreensaver: false,
      cartTimeout: 30000,
      screensaverTimeout: 60000,
      showName: false,
      showImages: false,
      showPrice: true,
      showDiscount: false,
      showFooterSubText: true,
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel']
      showPosition: true,
      showPaymentMethods: false,
      showPaymentButton: true,
      showStartOrderButton: false,
      showInfo: false,
      showLabel: false,
      hideTopHeadImage: false,
      showExpiryDate: false,
      errorImageURL: '/images/drawing_error_page.svg',
      topHeadImageURL: null,
      topHeadImageSize: 'sm', // sm, lg
      slidesToShow: 5,
      slidesToScroll: 3,
      backgroundImage: '',
      backgroundColor: '#DFE3E8',
      useCarouselHeader: false,
      customHeaderVariant: '',
    },
    machineDetails: {
      maxItems: 5,
      vmcPingTimeout: 20, // In seconds
      vmcPingInterval: 900, // In seconds
      feedbackModalTimeout: 10, // In seconds
      defaultProductURL: null,
      modalSize: 'md', // 'sm', 'md', 'lg', 'xl'
    },
    paymentModal: {
      paymentTimeout: 800, // In seconds
      cartDetails: {
        cartViewType: 'list', // 'list', 'cards'
        showCartData: true,
        cartPosition: 'bottom', // ['top', 'bottom']
        productDetails: {
          // if showCartData is true and cartViewType is 'cards', only then use this
          showImage: true,
          showName: true,
          showPosition: true,
          showPrice: true,
          showQuantity: true,
          showDescription: true,
        },
      },
      showOrderId: true,
      playSound: false,
      paymentPos: 'vertical-left', // ["vertical-left", "horizontal-top", "horizontal-bottom", "vertical-right"]
    },
    vendModal: {
      vendInitMaxTime: 100, // In Seconds
      vendItemMaxTime: 100, // In seconds
      vendingItemCardDetails: {
        showImage: false,
        showName: false,
        showPosition: true,
        showPrice: false,
      },
      showOrderId: true,
      allowOrderBeforeCollection: true,
      getFeedback: true,
      showVendingCardDetails: true,
      showVendingItemDetails: false,
    },
    refillPage: {
      loginTimeout: 300, // In seconds
      refillLoginImageURL: '/full_logo.png', // refill Login Image URL
      productDetails: {
        showImage: true,
        showName: true,
        showPosition: true,
        showPrice: true,
        showQuantity: true,
      },
      showTestRowButton: false,
      showHomeMotorButton: false,
      showOpenSettingsButton: false,
      backgroundColor: '#999',
      backgroundImage: 'background.jpg',
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel' ]
    },
    rfidModule: {
      showRfidUserDetails: true,
    },
  },
  newuiwithoutphotov2: {
    productDisplayPage: {
      screensaverURL: 'https://wendor-kiosk-images.s3.ap-south-1.amazonaws.com/images/screensaver.png',
      showScreensaver: false,
      cartTimeout: 30000,
      screensaverTimeout: 60000,
      showName: false,
      showImages: false,
      showPrice: true,
      showDiscount: false,
      showFooterSubText: true,
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel']
      showPosition: true,
      showPaymentMethods: false,
      showPaymentButton: true,
      showStartOrderButton: false,
      showInfo: false,
      showLabel: false,
      hideTopHeadImage: false,
      showExpiryDate: false,
      errorImageURL: '/images/drawing_error_page.svg',
      topHeadImageURL: null,
      topHeadImageSize: 'sm', // sm, lg
      slidesToShow: 5,
      slidesToScroll: 3,
      backgroundImage: '',
      backgroundColor: '#DFE3E8',
      useCarouselHeader: false,
      customHeaderVariant: '',
    },
    machineDetails: {
      maxItems: 5,
      vmcPingTimeout: 20, // In seconds
      vmcPingInterval: 900, // In seconds
      feedbackModalTimeout: 10, // In seconds
      defaultProductURL: null,
      modalSize: 'md', // 'sm', 'md', 'lg', 'xl'
    },
    paymentModal: {
      paymentTimeout: 800, // In seconds
      cartDetails: {
        cartViewType: 'list', // 'list', 'cards'
        showCartData: true,
        cartPosition: 'bottom', // ['top', 'bottom']
        productDetails: {
          // if showCartData is true and cartViewType is 'cards', only then use this
          showImage: true,
          showName: true,
          showPosition: true,
          showPrice: true,
          showQuantity: true,
          showDescription: true,
        },
      },
      showOrderId: true,
      playSound: false,
      paymentPos: 'vertical-left', // ["vertical-left", "horizontal-top", "horizontal-bottom", "vertical-right"]
    },
    vendModal: {
      vendInitMaxTime: 100, // In Seconds
      vendItemMaxTime: 100, // In seconds
      vendingItemCardDetails: {
        showImage: false,
        showName: false,
        showPosition: true,
        showPrice: false,
      },
      showOrderId: true,
      allowOrderBeforeCollection: true,
      getFeedback: true,
      showVendingCardDetails: true,
      showVendingItemDetails: false,
    },
    refillPage: {
      loginTimeout: 300, // In seconds
      refillLoginImageURL: '/full_logo.png', // refill Login Image URL
      productDetails: {
        showImage: true,
        showName: true,
        showPosition: true,
        showPrice: true,
        showQuantity: true,
      },
      showTestRowButton: false,
      showHomeMotorButton: false,
      showOpenSettingsButton: false,
      backgroundColor: '#999',
      backgroundImage: 'background.jpg',
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel' ]
    },
    rfidModule: {
      showRfidUserDetails: true,
    },
  },
  newuiwithoutphotowithoutscroll: {
    productDisplayPage: {
      screensaverURL: 'https://wendor-kiosk-images.s3.ap-south-1.amazonaws.com/images/screensaver.png',
      showScreensaver: false,
      cartTimeout: 30000,
      screensaverTimeout: 60000,
      showName: false,
      showImages: false,
      showPrice: true,
      showDiscount: false,
      showFooterSubText: true,
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel']
      showPosition: true,
      showPaymentMethods: false,
      showPaymentButton: true,
      showStartOrderButton: false,
      showInfo: false,
      showLabel: false,
      hideTopHeadImage: false,
      showExpiryDate: false,
      errorImageURL: '/images/drawing_error_page.svg',
      topHeadImageURL: null,
      topHeadImageSize: 'sm', // sm, lg
      slidesToShow: 5,
      slidesToScroll: 3,
      backgroundImage: '',
      backgroundColor: '#DFE3E8',
      useCarouselHeader: false,
      customHeaderVariant: '',
    },
    machineDetails: {
      maxItems: 5,
      vmcPingTimeout: 20, // In seconds
      vmcPingInterval: 900, // In seconds
      feedbackModalTimeout: 10, // In seconds
      defaultProductURL: null,
      modalSize: 'md', // 'sm', 'md', 'lg', 'xl'
    },
    paymentModal: {
      paymentTimeout: 800, // In seconds
      cartDetails: {
        cartViewType: 'list', // 'list', 'cards'
        showCartData: true,
        cartPosition: 'bottom', // ['top', 'bottom']
        productDetails: {
          // if showCartData is true and cartViewType is 'cards', only then use this
          showImage: true,
          showName: true,
          showPosition: true,
          showPrice: true,
          showQuantity: true,
          showDescription: true,
        },
      },
      showOrderId: true,
      playSound: false,
      paymentPos: 'vertical-left', // ["vertical-left", "horizontal-top", "horizontal-bottom", "vertical-right"]
    },
    vendModal: {
      vendInitMaxTime: 100, // In Seconds
      vendItemMaxTime: 100, // In seconds
      vendingItemCardDetails: {
        showImage: false,
        showName: false,
        showPosition: true,
        showPrice: false,
      },
      showOrderId: true,
      allowOrderBeforeCollection: true,
      getFeedback: true,
      showVendingCardDetails: true,
      showVendingItemDetails: false,
    },
    refillPage: {
      loginTimeout: 300, // In seconds
      refillLoginImageURL: '/full_logo.png', // refill Login Image URL
      productDetails: {
        showImage: true,
        showName: true,
        showPosition: true,
        showPrice: true,
        showQuantity: true,
      },
      showTestRowButton: false,
      showHomeMotorButton: false,
      showOpenSettingsButton: false,
      backgroundColor: '#999',
      backgroundImage: 'background.jpg',
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel' ]
    },
    rfidModule: {
      showRfidUserDetails: true,
    },
  },
  newuiwithphoto: {
    productDisplayPage: {
      screensaverURL: 'https://wendor-kiosk-images.s3.ap-south-1.amazonaws.com/images/screensaver.png',
      showScreensaver: false,
      cartTimeout: 30000,
      screensaverTimeout: 60000,
      showName: true,
      showImages: true,
      showPrice: true,
      showDiscount: true,
      showFooterSubText: true,
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel']
      showPosition: true,
      showPaymentMethods: false,
      showPaymentButton: true,
      showStartOrderButton: false,
      showInfo: false,
      showLabel: false,
      showExpiryDate: false,
      errorImageURL: '/images/drawing_error_page.svg',
      topHeadImageURL: null,
      topHeadImageSize: 'sm', // sm, lg
      slidesToShow: 5,
      slidesToScroll: 1,
      backgroundImage: '',
      backgroundColor: '#DFE3E8',
      useCarouselHeader: false,
      customHeaderVariant: '',
    },
    machineDetails: {
      maxItems: 1,
      vmcPingTimeout: 20, // In seconds
      vmcPingInterval: 900, // In seconds
      feedbackModalTimeout: 10, // In seconds
      defaultProductURL: null,
      modalSize: 'lg', // 'sm', 'md', 'lg', 'xl'
    },
    paymentModal: {
      paymentTimeout: 800, // In seconds
      cartDetails: {
        cartViewType: 'cards', // 'list', 'cards'
        showCartData: true,
        cartPosition: 'top', // ['top', 'bottom']
        productDetails: {
          // if showCartData is true and cartViewType is 'cards', only then use this
          showImage: true,
          showName: true,
          showPosition: true,
          showPrice: true,
          showQuantity: true,
          showDescription: true,
        },
      },
      showOrderId: true,
      playSound: false,
      paymentPos: 'horizontal-top', // ["vertical-left", "horizontal-top", "horizontal-bottom", "vertical-right"]
    },
    vendModal: {
      vendInitMaxTime: 100, // In Seconds
      vendItemMaxTime: 100, // In seconds
      vendingItemCardDetails: {
        showImage: false,
        showName: true,
        showPosition: true,
        showPrice: true,
      },
      showOrderId: true,
      showVendingItemDetails: true,
      showVendingCardDetails: true,
      getFeedback: false,
      allowOrderBeforeCollection: true,
    },
    refillPage: {
      loginTimeout: 300, // In seconds
      refillLoginImageURL: '/full_logo.png', // refill Login Image URL
      productDetails: {
        showImage: true,
        showName: true,
        showPosition: true,
        showPrice: true,
        showQuantity: true,
      },
      showTestRowButton: false,
      showHomeMotorButton: false,
      showOpenSettingsButton: false,
      backgroundImage: '',
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel' ]
      backgroundColor: '#999',
    },
    rfidModule: {
      showRfidUserDetails: true,
    },
  },
  newuiwithphotodubai: {
    productDisplayPage: {
      screensaverURL: 'https://wendor-kiosk-images.s3.ap-south-1.amazonaws.com/images/screensaver.png',
      showScreensaver: false,
      cartTimeout: 30000,
      screensaverTimeout: 60000,
      showName: true,
      showImages: true,
      showPrice: true,
      showDiscount: true,
      showFooterSubText: true,
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel']
      showPosition: true,
      showPaymentMethods: false,
      showPaymentButton: true,
      showStartOrderButton: false,
      showInfo: false,
      showLabel: false,
      showExpiryDate: false,
      errorImageURL: '/images/drawing_error_page.svg',
      topHeadImageURL: null,
      topHeadImageSize: 'sm', // sm, lg
      slidesToShow: 5,
      slidesToScroll: 1,
      backgroundImage: '',
      backgroundColor: '#DFE3E8',
      useCarouselHeader: false,
      customHeaderVariant: '',
    },

    machineDetails: {
      maxItems: 1,
      vmcPingTimeout: 20, // In seconds
      vmcPingInterval: 900, // In seconds
      feedbackModalTimeout: 10, // In seconds
      defaultProductURL: null,
      modalSize: 'lg', // 'sm', 'md', 'lg', 'xl'
    },
    paymentModal: {
      paymentTimeout: 800, // In seconds
      cartDetails: {
        cartViewType: 'cards', // 'list', 'cards'
        showCartData: true,
        cartPosition: 'top', // ['top', 'bottom']
        productDetails: {
          // if showCartData is true and cartViewType is 'cards', only then use this
          showImage: true,
          showName: true,
          showPosition: true,
          showPrice: true,
          showQuantity: true,
          showDescription: true,
        },
      },
      showOrderId: true,
      playSound: false,
      paymentPos: 'horizontal-top', // ["vertical-left", "horizontal-top", "horizontal-bottom", "vertical-right"]
    },
    vendModal: {
      vendInitMaxTime: 100, // In Seconds
      vendItemMaxTime: 100, // In seconds
      vendingItemCardDetails: {
        showImage: false,
        showName: true,
        showPosition: true,
        showPrice: true,
      },
      showOrderId: true,
      showVendingItemDetails: true,
      showVendingCardDetails: true,
      getFeedback: false,
      allowOrderBeforeCollection: true,
    },
    refillPage: {
      loginTimeout: 300, // In seconds
      refillLoginImageURL: '/full_logo.png', // refill Login Image URL
      productDetails: {
        showImage: true,
        showName: true,
        showPosition: true,
        showPrice: true,
        showQuantity: true,
      },
      showTestRowButton: false,
      showHomeMotorButton: false,
      showOpenSettingsButton: false,
      backgroundImage: '',
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel' ]
      backgroundColor: '#999',
    },
    rfidModule: {
      showRfidUserDetails: true,
    },
  },
  uinotraywithbanner: {
    productDisplayPage: {
      showName: true,
      showImages: true,
      showPrice: true,
      showDiscount: true,
      showFooterSubText: true,
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel']
      showPosition: true,
      showPaymentMethods: false,
      showInfo: false,
      showLabel: false,
      showExpiryDate: false,
      errorImageURL: '/images/drawing_error_page.svg',
      topHeadImageURL: null,
      topHeadImageSize: 'sm', // sm, lg
      slidesToShow: 5,
      slidesToScroll: 1,
      backgroundImage: '',
      backgroundColor: '#DFE3E8',
    },
    machineDetails: {
      maxItems: 1,
      vmcPingTimeout: 20, // In seconds
      vmcPingInterval: 900, // In seconds
      feedbackModalTimeout: 10, // In seconds
      defaultProductURL: null,
      modalSize: 'lg', // 'sm', 'md', 'lg', 'xl'
    },
    paymentModal: {
      paymentTimeout: 800, // In seconds
      cartDetails: {
        cartViewType: 'cards', // 'list', 'cards'
        showCartData: true,
        cartPosition: 'top', // ['top', 'bottom']
        productDetails: {
          // if showCartData is true and cartViewType is 'cards', only then use this
          showImage: true,
          showName: true,
          showPosition: true,
          showPrice: true,
          showQuantity: true,
          showDescription: true,
        },
      },
      showOrderId: true,
      playSound: false,
      paymentPos: 'horizontal-top', // ["vertical-left", "horizontal-top", "horizontal-bottom", "vertical-right"]
    },
    vendModal: {
      vendInitMaxTime: 100, // In Seconds
      vendItemMaxTime: 100, // In seconds
      vendingItemCardDetails: {
        showImage: false,
        showName: true,
        showPosition: true,
        showPrice: true,
      },
      showOrderId: true,
      showVendingItemDetails: true,
      showVendingCardDetails: true,
      getFeedback: false,
      allowOrderBeforeCollection: true,
    },
    refillPage: {
      loginTimeout: 300, // In seconds
      refillLoginImageURL: '/full_logo.png', // refill Login Image URL
      productDetails: {
        showImage: true,
        showName: true,
        showPosition: true,
        showPrice: true,
        showQuantity: true,
      },
      showTestRowButton: false,
      showHomeMotorButton: false,
      showOpenSettingsButton: false,
      backgroundImage: '',
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel' ]
      backgroundColor: '#999',
    },
    rfidModule: {
      showRfidUserDetails: true,
    },
  },
  atlaswithlocker: {
    productDisplayPage: {
      showName: true,
      showImages: true,
      showPrice: false,
      showDiscount: true,
      showFooterSubText: true,
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel']
      showPosition: true,
      showPaymentMethods: false,
      showPaymentButton: false,
      showStartOrderButton: true,
      showInfo: false,
      showLabel: false,
      showExpiryDate: false,
      errorImageURL: '/images/drawing_error_page.svg',
      topHeadImageURL: null,
      topHeadImageSize: 'sm', // sm, lg
      slidesToShow: 6,
      slidesToScroll: 4,
      backgroundImage: '',
      backgroundColor: '#DFE3E8',
      useCarouselHeader: true,
      customHeaderVariant: 'atlaswithlocker',
    },
    machineDetails: {
      maxItems: 1,
      vmcPingTimeout: 20, // In seconds
      vmcPingInterval: 900, // In seconds
      feedbackModalTimeout: 10, // In seconds
      defaultProductURL: null,
      modalSize: 'lg', // 'sm', 'md', 'lg', 'xl'
    },
    paymentModal: {
      paymentTimeout: 800, // In seconds
      cartDetails: {
        cartViewType: 'cards', // 'list', 'cards'
        showCartData: true,
        cartPosition: 'top', // ['top', 'bottom']
        productDetails: {
          // if showCartData is true and cartViewType is 'cards', only then use this
          showImage: true,
          showName: true,
          showPosition: true,
          showPrice: false,
          showQuantity: true,
          showDescription: true,
        },
      },
      showOrderId: true,
      playSound: false,
      paymentPos: 'horizontal-top', // ["vertical-left", "horizontal-top", "horizontal-bottom", "vertical-right"]
    },
    vendModal: {
      vendInitMaxTime: 100, // In Seconds
      vendItemMaxTime: 100, // In seconds
      vendingItemCardDetails: {
        showImage: false,
        showName: true,
        showPosition: true,
        showPrice: false,
      },
      showOrderId: true,
      showVendingItemDetails: true,
      showVendingCardDetails: true,
      getFeedback: true,
      allowOrderBeforeCollection: true,
    },
    refillPage: {
      loginTimeout: 300, // In seconds
      refillLoginImageURL: '/full_logo.png', // refill Login Image URL
      productDetails: {
        showImage: true,
        showName: true,
        showPosition: true,
        showPrice: true,
        showQuantity: true,
      },
      showTestRowButton: false,
      showHomeMotorButton: false,
      showOpenSettingsButton: false,
      backgroundImage: '',
      viewType: '2d-grid', // ['2d-grid', 'list', 'carousel' ]
      backgroundColor: '#999',
    },
    rfidModule: {
      showRfidUserDetails: true,
    },
  },
};
