import React from 'react';
import { Row, Col } from 'reactstrap';

const NoPaymentMethodNotSelectedUI = () => {
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <div className="">
              <img alt="select-payment-method-img" src="/assets/selectpayment.svg" />
              <h5>Please select a payment method</h5>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default NoPaymentMethodNotSelectedUI;
