// -
import axios from 'axios';

import { MACHINE } from './constants';

export const getMachineData = async () => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_KIOSK_CRITICAL_SERVICE_URL}/api/machineItems`,
      method: 'GET',
    });

    return response.data;
  } catch (err) {
    return {
      status: false,
      error: true,
      data: [],
    };
  }
};

export const checkIfServiceModeIsOn = async () => {
  return { error: false };
  // try {
  //   const something = await axios.get(`http://192.168.1.7:4000`);

  //   // console.log('something', something);
  //   return { error: false };
  // } catch (err) {
  //   return {
  //     error: true,
  //   };
  // }
};

export const getMachineLanguage = async () => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_KIOSK_CRITICAL_SERVICE_URL}/api/internationalisation`,
      method: 'GET',
    });

    return response.data;
  } catch (err) {
    return {
      status: false,
      error: true,
      data: [],
    };
  }
};

export const setMachinePaymentMethods = data => {
  return {
    type: MACHINE.SET_PAYMENT_METHODS_FOR_MACHINE,
    payload: data,
  };
};

export const updateMachineStatus = ({ statusCode, message, mode }) => {
  const machineDetails = {
    statusCode,
    message,
    mode: mode || 'periodic',
    source: 'tab',
  };
  try {
    axios({
      url: `${process.env.REACT_APP_KIOSK_CRITICAL_SERVICE_URL}/api/machineStatus`,
      method: 'post',
      data: machineDetails,
    });

    return true;
  } catch (err) {
    return false;
  }
};

export const closeMachine = () => {
  try {
    axios({
      url: `${process.env.REACT_APP_REFILLER_SERVICE_URL}/api/closeFromMachine`,
      method: 'get',
    });

    return true;
  } catch (err) {
    return false;
  }
};
