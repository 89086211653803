import { VEND_REDUCER, VEND_STATUS } from '../../actions/constants';

const vendReducer = (state = {}, { type, payload }) => {
  const newState = Object.assign({}, state);
  switch (type) {
    case VEND_REDUCER.START:
      return {
        ...state,
        vendStatus: VEND_STATUS.VENDING,
        vendedItems: [],
        currentVendingItemIndex: 0,
        totalItemsFailed: 0,
      };
    case VEND_REDUCER.ORDER_PICKED:
      return {
        ...state,
        isOrderPicked: true,
      };
    case VEND_REDUCER.ITEMS_TO_VEND:
      return {
        ...state,
        itemsToVend: payload,
        isOrderPicked: false,
      };
    case VEND_REDUCER.UPDATE: {
      const newVendedItems = newState.vendedItems || [];
      newVendedItems.push(payload);
      return {
        ...state,
        vendedItems: newVendedItems,
        currentVendingItemIndex: newState.currentVendingItemIndex + 1,
        totalItemsFailed: payload.isSuccess ? newState.totalItemsFailed : newState.totalItemsFailed + 1,
      };
    }
    case VEND_REDUCER.STATUS_UPDATE:
      return { ...state, vendStatus: payload.status, currentVendingItemIndex: 0 };
    case VEND_REDUCER.RESET:
      return {
        ...state,
        vendStatus: VEND_STATUS.NOT_VENDING,
        vendedItems: [],
        currentVendingItemIndex: 0,
        totalItemsFailed: 0,
        itemsToVend: [],
        isOrderPicked: false,
      };
    case VEND_REDUCER.UPDATE_CUSTOM_MESSAGE:
      return { ...state, customDisplayMessage: payload };
    case VEND_REDUCER.STATUS_BROKEN: {
      let totalItemsFailed = 0;
      const { currentVendingItemIndex, itemsToVend } = { ...state };
      let currentIndex = currentVendingItemIndex;
      const vendedItems = itemsToVend.map(item => {
        totalItemsFailed += 1;
        currentIndex += 1;
        return {
          isSuccess: false,
          itemId: item.bill_item_id,
          pos: item.position,
        };
      });
      return {
        ...state,
        vendStatus: VEND_STATUS.VEND_COMPLETE,
        totalItemsFailed,
        vendedItems,
        currentVendingItemIndex: currentIndex,
      };
    }
    default:
      return state;
  }
};

export default vendReducer;
