import axios from 'axios';

export const updateMachine = async ({ totalAmount, refilledProducts, refillerToken }) => {
  // Here totalAmount is total amount of products in the machine when refill is done.
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_REFILLER_SERVICE_URL}/api/updateMachineProductsV2`,
      method: 'post',
      data: { items: refilledProducts, refillerToken, totalAmount },
    });
    return { ...response.data, error: false };
  } catch (e) {
    return {
      error: true,
    };
  }
};

export const refillStateCheck = async () => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_REFILLER_SERVICE_URL}/api/refillStatus`,
      method: 'get',
      // timeout: 1000,
    });
    return response.data;
  } catch (e) {
    return {
      error: true,
    };
  }
};

export const searchProductByName = async name => {
  // console.log('Search query', name);
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_REFILLER_SERVICE_URL}/api/products/${name}`,
      method: 'GET',
    });
    return response.data;
  } catch (e) {
    return {
      error: true,
    };
  }
};

export const openSetting = async () => {
  //
  // Here totalAmount is total amount of products in the machine when refill is done.
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_REFILLER_SERVICE_URL}/api/openSetting`,
      method: 'post',
    });
    return response.data;
  } catch (e) {
    return {
      error: true,
    };
  }
};

export const refillLogin = async (userId, pin) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_REFILLER_SERVICE_URL}/api/refillLogin`,
      method: 'POST',
      data: { userId, pin },
    });
    return response.data;
  } catch (e) {
    return {
      error: true,
      message: e.response && e.response.data && e.response.data.message,
    };
  }
};

export const refillerValidate = async refillerToken => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_REFILLER_SERVICE_URL}/api/refillerValidate`,
      method: 'POST',
      data: { refillerToken },
    });
    return response.data;
  } catch (e) {
    return {
      error: true,
      message: '',
    };
  }
};

export const homeMotor = async rows => {
  // console.log('homeMotor', rows);
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_REFILL_VMC_SERVICE_URL}/v1/refill/setHomeMotor`,
      method: 'POST',
      data: {
        refillerToken: window.localStorage.getItem('refillerToken'),
        rows: rows || [],
      },
    });
    return response.data;
  } catch (e) {
    return {
      error: true,
      message: '',
    };
  }
};

export const testRow = async rows => {
  // console.log('testRow', rows);
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_REFILL_VMC_SERVICE_URL}/v1/refill/testAllRows`,
      // url: `http://localhost:3004/v1/refill/testAllRows`,
      method: 'POST',
      data: {
        refillerToken: window.localStorage.getItem('refillerToken'),
        rows: rows || [],
      },
    });
    return response.data;
  } catch (e) {
    return {
      error: true,
      message: '',
    };
  }
};

export const getCommandButtons = async () => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_BASE}/api/customKeypadCommands`,
      method: 'GET',
    });
    return response.data;
  } catch (e) {
    return {
      error: true,
      message: '',
    };
  }
};

export const sendServiceModeButton = async btnId => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_BASE}/api/customKeypadCommand/${btnId}`,
      method: 'GET',
    });
    return response.data;
  } catch (e) {
    return {
      error: true,
      message: '',
    };
  }
};

export const sendServiceModeCommands = async cmd => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_BASE}/api/sendcustomKeypadCommand/${cmd}`,
      method: 'GET',
    });
    return response.data;
  } catch (e) {
    return {
      error: true,
      message: '',
    };
  }
};
