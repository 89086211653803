import React from 'react';
import { connect } from 'react-redux';

import { ThreeBubbleLoader } from '../../components';
import { getPaymentDetails } from '../../actions/payment';
import PayModeLinkUI from '../../components/PaymentModes/PaymentModeLink';
import { SMS_SENT_STATUS } from '../../actions/constants';

class LinkFlow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      smsSentStatus: SMS_SENT_STATUS.NOT_INITIATED,
    };

    this.getSmsLinkStatus = this.getSmsLinkStatus.bind(this);
    this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);
  }

  getSmsLinkStatus() {
    const { phoneNumber, isPhoneNumberValid, requestId } = { ...this.props };

    window.heap.track('Button SMS_link', {
      phoneNumber,
      time: Date.now(),
    });

    this.setState({
      smsSentStatus: SMS_SENT_STATUS.INITIATED,
    });
    if (isPhoneNumberValid) {
      const requestBody = {
        request_id: requestId,
        phone: phoneNumber,
        payment_method: 'link',
      };
      getPaymentDetails(requestBody)
        .then(data => {
          window.heap.track('get SMS_link response ', {
            phoneNumber,
            data,
            time: Date.now(),
          });
          if (!data.error) {
            this.setState({
              smsSentStatus: SMS_SENT_STATUS.COMPLETED,
            });
          } else {
            this.setState({
              smsSentStatus: SMS_SENT_STATUS.FAILED,
            });
          }
        })
        .catch(e => {
          if (e) {
            window.heap.track('get SMS_link response error ', {
              phoneNumber,
              error: e,
              time: Date.now(),
            });
          }
        });
    }
  }

  handleChangePaymentMethod() {
    const { changePaymentMethod } = {
      ...this.props,
    };
    changePaymentMethod('link');
    this.setState({
      smsSentStatus: SMS_SENT_STATUS.NOT_INITIATED,
    });
  }

  render() {
    const { requestId, phoneNumber, handlePhoneNumberChange, isPhoneNumberValid, linkPaymentLanguage } = this.props;

    const {
      txtEnterPhoneNumber,
      txtPostEnterPhoneNumber,
      txtSmsSentLoading,
      txtPostSmsSent,
      txtErrorMsg,
      btnOnPhonenumberEnter,
      btnChangeNumber,
    } = linkPaymentLanguage;

    const { smsSentStatus } = { ...this.state };
    if (!requestId) {
      return <ThreeBubbleLoader />;
    }

    return (
      <PayModeLinkUI
        phoneNumber={phoneNumber}
        handlePhoneNumberChange={handlePhoneNumberChange}
        isPhoneNumberValid={isPhoneNumberValid}
        changePaymentMethod={this.handleChangePaymentMethod}
        smsSentStatus={smsSentStatus}
        getSmsLinkStatus={this.getSmsLinkStatus}
        txtEnterPhoneNumber={txtEnterPhoneNumber}
        txtPostEnterPhoneNumber={txtPostEnterPhoneNumber}
        txtSmsSentLoading={txtSmsSentLoading}
        txtPostSmsSent={txtPostSmsSent}
        txtErrorMsg={txtErrorMsg}
        btnOnPhonenumberEnter={btnOnPhonenumberEnter}
        btnChangeNumber={btnChangeNumber}
      />
    );
  }
}

const mapStateToProps = ({ languageReducer }) => {
  return {
    linkPaymentLanguage: languageReducer.linkPaymentLanguage,
  };
};

export default connect(mapStateToProps)(LinkFlow);
