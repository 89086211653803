import React from 'react';
import { Row, Col, Button } from 'reactstrap';

import { ThreeBubbleLoader } from '..';
import { RFID_MODULE_STATUS } from '../../actions/constants';
import GetUserPin from '../GetUserPin';

const RfidUI = ({
  errorInPinCheck,
  errorMsg,
  pinTriesPending,
  rfidModuleStatus,
  cardVerifyError,
  message,
  handlePinNumberChange,
  rfidPin,
  otpSentError,
  pinMessage,
  doRfidPayment,
  handleResetRfidPayMethod,
  userDetails,
  isOtpSubmiting,
  isBalanceInsufficient,
  txtTriesPending,
  txtWaitTitle,
  txtWaitInfo,
  txtToucSmartCardInfo,
  txtCardErrorMsg,
  txtCardErrorInstruction,
  txtTouchSmartCardLowBalance,
  btnRetryCardMachine,
  txtRfidBrokenMsg,
  btnRfidBrokenRetry,
  btnCardVerifyRetry,
  txtPostOtpErrorMsg,
  btnPostOtpErrorRetry,
  btnGetUserPin,
}) => {
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            {errorInPinCheck && (
              <h5>
                {txtTriesPending} {pinTriesPending}
              </h5>
            )}
            {errorInPinCheck && <h5>{errorMsg}</h5>}
            {rfidModuleStatus === RFID_MODULE_STATUS.INITIATING && (
              <div>
                <h3>{txtWaitTitle}</h3>
                <h5>{txtWaitInfo}</h5>
              </div>
            )}
            {rfidModuleStatus === RFID_MODULE_STATUS.ACCEPTING && !cardVerifyError && !isBalanceInsufficient && (
              <h5>{txtToucSmartCardInfo}</h5>
            )}
            {rfidModuleStatus === RFID_MODULE_STATUS.ERROR_CARD && (
              <div className="sorry-message">
                <ion-icon name="sad" />
                <h3>{txtCardErrorMsg}</h3>
                <h5>{txtCardErrorInstruction}</h5>
                <Button className="wendor-bg-green wendor-small-button " onClick={handleResetRfidPayMethod}>
                  {btnRetryCardMachine}
                </Button>
              </div>
            )}
            {/* if user doesn't exist or insufficient balance */}
            {rfidModuleStatus === RFID_MODULE_STATUS.ACCEPTING &&
              cardVerifyError &&
              !isBalanceInsufficient &&
              !userDetails && (
                <div className="sorry-message">
                  <ion-icon name="sad" />
                  {/* <h3>{errorMsg}</h3> */}
                  <h3>{message}</h3>
                  {/* <Button className="wendor-bg-green wendor-small-button " onClick={handleResetRfidPayMethod}>
                  {btnRetryCardMachine}
                </Button> */}
                </div>
              )}
            {rfidModuleStatus === RFID_MODULE_STATUS.ACCEPTING && !cardVerifyError && isBalanceInsufficient && (
              <h4>{txtTouchSmartCardLowBalance}</h4>
            )}
            {rfidModuleStatus === RFID_MODULE_STATUS.ACCEPTING && !cardVerifyError && userDetails && (
              <div>
                <h5>Rfid Id: {userDetails.rfid_id}</h5>
                <h5>Available Balance: {userDetails.available_balance}</h5>
              </div>
            )}
            {rfidModuleStatus === RFID_MODULE_STATUS.BROKEN && (
              <div className="sorry-message">
                <ion-icon name="sad" />
                <h5>{txtRfidBrokenMsg}</h5>
                <Button className="wendor-bg-green wendor-small-button " onClick={handleResetRfidPayMethod}>
                  {btnRfidBrokenRetry}
                </Button>
              </div>
            )}

            {rfidModuleStatus === RFID_MODULE_STATUS.INITIATING && <ThreeBubbleLoader />}
          </Col>
        </Row>
        {rfidModuleStatus === RFID_MODULE_STATUS.ACCEPTING_PIN && cardVerifyError && (
          <div>
            <div>
              <h2>Oops..</h2>
              <span>{message}</span>
            </div>
            <Button className="wendor-bg-green wendor-small-button " onClick={handleResetRfidPayMethod}>
              {btnCardVerifyRetry}
            </Button>
          </div>
        )}
        {rfidModuleStatus === RFID_MODULE_STATUS.ACCEPTING_PIN && !cardVerifyError && otpSentError && (
          <div>
            <div>
              <h2>Oops..</h2>
              <span>{txtPostOtpErrorMsg}</span>
            </div>

            <Button className="wendor-bg-green wendor-small-button " onClick={handleResetRfidPayMethod}>
              {btnPostOtpErrorRetry}
            </Button>
          </div>
        )}
        {rfidModuleStatus === RFID_MODULE_STATUS.ACCEPTING_PIN && !cardVerifyError && !otpSentError && (
          <GetUserPin
            mainHeading={pinMessage}
            isValid={rfidPin && rfidPin.length === 4}
            handleChange={handlePinNumberChange}
            isLoading={isOtpSubmiting}
            value={rfidPin}
            onButtonClick={doRfidPayment}
            buttonText={btnGetUserPin}
          />
        )}
        <Row />
      </Col>
    </Row>
  );
};

export default RfidUI;
