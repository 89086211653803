import React, { useState, useEffect } from 'react';
import { getPaymentDetailsEzeTap } from '../../actions/payment';
//  import { ThreeBubbleLoader } from '../../components';
import EzeTapUI from '../../components/PaymentModes/ezetap';
import { disablingEzetapModule } from '../../actions/ezetapActions';

const EzeTapPayFlow = ({ requestId, checkPaymentStatus }) => {
  const [isEzeTapInitiated, setisEzeTapInitiated] = useState(false);
  const [isLoadingEzeTap, setisLoadingEzeTap] = useState(true);
  const [isEzeTapInitError, setisEzeTapInitError] = useState(false);
  // const [EzeTapResponseData, setEzeTapResponseData] = useState('');

  const details = {
    payment_method: 'ezetap',
    request_id: requestId,
  };

  const disableEzetapModule = () => {
    // updateEzetapModuleStatus
    // Disable ezetap Module
    window.heap.track('EzeTap component unmount', {
      time: Date.now(),
    });
    const requestBody = {
      requestId,
      comment: 'Disable Ezetap Module',
    };
    disablingEzetapModule(requestBody);
  };

  const getEzeTapDetails = () => {
    setisEzeTapInitError(false);
    // setEzeTapResponseData('');
    setisEzeTapInitiated(false);
    setisLoadingEzeTap(true);
    window.heap.track('Payment Method', {
      paymentMethod: 'EzeTap',
      time: Date.now(),
      requestId,
    });

    if (!requestId) {
      window.heap.track('no request id found ', {
        time: Date.now(),
      });
      setisEzeTapInitiated(false);
      setisLoadingEzeTap(false);
      setisEzeTapInitError(true);
      return;
    }

    getPaymentDetailsEzeTap(details).then(data => {
      if (!data.error) {
        window.heap.track('Ezetap details Response', {
          paymentMethod: 'EzeTap',
          time: Date.now(),
          requestId,
          data,
        });
        // setEzeTapResponseData(data);
        setisEzeTapInitiated(true);
        setisLoadingEzeTap(false);
        checkPaymentStatus(); // Check payment status for EzeTap
      } else {
        window.heap.track('EzeTap details Error', {
          paymentMethod: 'EzeTap',
          time: Date.now(),
          requestId,
        });
        setisEzeTapInitError(true);
        // setEzeTapResponseData('');
        setisEzeTapInitiated(false);
        setisLoadingEzeTap(false);
      }
    });
  };
  useEffect(() => {
    //  console.log('gettingEzeTapDetails ----');
    window.heap.track('EzeTap component renderd', {
      time: Date.now(),
    });
    getEzeTapDetails();
    return () => {
      disableEzetapModule();
    };
  }, []);

  return (
    <EzeTapUI
      isLoadingEzeTap={isLoadingEzeTap}
      isEzeTapInitError={isEzeTapInitError}
      isEzeTapInitiated={isEzeTapInitiated}
      retryEzeTap={getEzeTapDetails}
    />
  );
};

export default EzeTapPayFlow;
