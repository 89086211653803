/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { Alert, Row, Col, Button } from 'reactstrap';
import { ReactKeyboard, LoaderCircular } from '../index';

const RefillLoginUI = ({
  keyboardRequired,
  allFields,
  activeFieldIndex,
  loginFailed,
  logout,
  refillLoginFailedReason,
  isSubmitting,
  refillPageLoginURL,
  handleSubmit,
  onKeyboardInput,
  openKeyboard,
  openServiceMode,
  isError,
  errorMsg,
}) => {
  let key = 0;

  return (
    <div>
      <div className="login-page">
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <img alt="Wendor" width="70%" src={refillPageLoginURL || '/full_logo.png'} />
          </Col>
          <Col xs={12} sm={12} md={12}>
            <h5 className="text-red ">Refill Mode</h5>

            {isError && (
              <Alert color="warning" className="fluid text-red">
                <span data-testid="refill-login-failAlert">{errorMsg}</span>
              </Alert>
            )}

            {!isError && loginFailed && (
              <Alert color="warning" className="fluid text-red">
                <span data-testid="refill-login-failAlert">
                  {refillLoginFailedReason || 'Credentials not correct.'}
                </span>
              </Alert>
            )}
            {isSubmitting && <LoaderCircular />}
          </Col>
        </Row>
        <Row>
          {allFields.map((data, index) => {
            key += 1;
            return (
              <Col key={key} xs={12} sm={12} md={12} lg={12}>
                <input
                  readOnly
                  data-testid={data['data-testid']}
                  className={`form-control form-input-field login_page_inputs 
                        ${activeFieldIndex === index ? 'active-input-field' : ''}`}
                  placeholder={data.name}
                  name={data.name}
                  type={data.type}
                  value={data.value}
                  onFocus={() => openKeyboard(index)}
                />
              </Col>
            );
          })}
        </Row>
        <Row className="product-display-buttton-container">
          <Col xs={12} sm={12} md={12} lg={12}>
            <Button
              className="wendor-bg-red wendor-small-button wendor-big-button "
              size="small"
              onClick={() => logout()}>
              <span>Cancel Refill Mode</span>
            </Button>
            {/* <Button className="wendor-bg-red" round size="md" type="submit" disabled={submitting}> */}
            {/* Log In */}
            {/* </Button> */}
            <Button
              className="wendor-bg-green wendor-small-button wendor-big-button "
              size="small"
              data-testid="refill-login-loginBtn"
              onClick={() => handleSubmit()}>
              Log In
            </Button>
          </Col>
        </Row>

        <h5 className="text-red version-name">
          {process.env.REACT_APP_VERSION} {process.env.REACT_APP_RUN_ENV}
        </h5>
        <Row>
          <div className={keyboardRequired ? 'keyboard_show keyboard_bottom' : 'keyboard_hide'}>
            {keyboardRequired && (
              <Col xs={12}>
                <ReactKeyboard isNumberOnly onKeyPress={onKeyboardInput} />
              </Col>
            )}
          </div>
        </Row>
      </div>
      <span
        size="small"
        role="img"
        style={{ position: 'absolute', bottom: '10px', right: '20px' }}
        aria-label="setting"
        onKeyDown={() => {}}
        onClick={() => openServiceMode()}>
        ⚙️ {/*  Open Service mode */}
      </span>
    </div>
  );
};

export default RefillLoginUI;
