/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import uniqid from 'uniqid';
import '../assets/css/RowItemCard.css';

class WithoutImageItemCardDisplayV2 extends React.Component {
  // export default ({ productsData, updateCart, totalItems }) => {\
  constructor(props) {
    super(props);
    const { productItem } = this.props;
    this.state = {
      quantity: 0,
      isEmpty: productItem.left_units <= 0,
      className: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { numberOfReset, productItem } = { ...this.props };

    if (prevProps.numberOfReset !== numberOfReset || prevProps.productItem.left_units !== productItem.left_units) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  getSnapshotBeforeUpdate(prevProps) {
    const { numberOfReset, productItem } = { ...this.props };
    if (prevProps.numberOfReset !== numberOfReset || prevProps.productItem.left_units !== productItem.left_units) {
      this.setState({ quantity: 0, isEmpty: productItem.left_units === 0 });
    }
    return null;
  }

  handleClick = (e, newQuantity) => {
    e.stopPropagation();
    const { quantity } = { ...this.state };
    const { productItem, updateCart, disableSelect } = this.props;
    if (newQuantity < 0) {
      // this.setState({
      //   quantity: 0,
      //   isEmpty: false,
      // });
      // updateCart(productItem.id, newQuantity);
      this.setState({ quantity: quantity + newQuantity });
      updateCart(productItem.id, newQuantity + quantity);
    } else if (!disableSelect) {
      if (productItem.left_units >= quantity + newQuantity) {
        this.setState({ quantity: quantity + newQuantity });
        updateCart(productItem.id, newQuantity + quantity);
      } else {
        this.setState({ isEmpty: true });
      }
    }
  };

  // handleClickAnimation = () => {
  //   this.setState({ className: 'touch-animation-card ' });
  //   this.timer = setTimeout(() => {
  //     this.setState({ className: '' });
  //   }, 225);
  // };

  render() {
    const { disableSelect, productItem, machineType, productDisplayPage, maxItems, symbol, suffix } = {
      ...this.props,
    };
    const { quantity, isEmpty, className } = { ...this.state };
    const productDisplayClass = machineType !== 'imshow' ? 'product-card-animated-v2' : 'product-card-imshow';

    const isSlotDisable = isEmpty || productItem.left_units <= 0 || !productItem.enabled;
    const style = productItem.custom_css ? JSON.parse(productItem.custom_css) : {};
    return (
      <div
        // onTouchEnd={this.handleClickAnimation}
        className={`${className} text-align-center ${
          isSlotDisable && !quantity > 0 ? 'p-card-animated-v2 disable-touch' : 'p-card-animated-v2'
        } ${quantity > 0 ? 'p-card-animated-selected-v2' : ''}`}>
        <div
          style={style}
          role="button"
          key={uniqid()}
          onClick={e => this.handleClick(e, 1)}
          data-testid="product-display-box"
          className={`display-inline-block 'unselected-product-card product-card-animated-v2' ${productDisplayClass} ${
            isSlotDisable ? 'product-card-disable-v2' : ''
          }`}
          disabled={disableSelect}>
          <div className={` ${isSlotDisable ? 'empty-card ' : ''} card-body-animated`}>
            <div className="item-pos-display">
              <div className="item-pos-name-without-photo-v2">
                <div
                  style={{
                    fontSize: '16px',
                    lineHeight: '19px',
                    textAlign: 'center',
                    fontWeight: '700',
                    color: '#454F5B',
                  }}
                  // className={`${isSlotDisable ? 'product-position-animated-disable' : 'product-position-animated'}`}
                  data-test="product-position">
                  {productItem.show_pos.toUpperCase()}
                </div>
                {maxItems > 1 && quantity > 0 && (
                  <h5
                    data-testid="product-display-box-quantity"
                    className="product-cart-quantity"
                    data-test="product-cart-quantity">
                    {quantity}
                  </h5>
                )}
                {productDisplayPage.showName && <p className="product-name">{productItem.name.substring(0, 20)}</p>}
              </div>
            </div>
            {/* Disable when no. of left units are less than 0 and cart_quantity >= left_units */}{' '}
            <button
              type="button"
              disabled={disableSelect}
              data-test="product-select-button"
              className="wendor-price-animated-wihtout-photo-v2">
              {productDisplayPage.showDiscount && productItem.actual_price !== productItem.price && (
                <span className="discount-price">
                  <NumberFormat
                    value={` ${productItem.actual_price} `}
                    displayType="text"
                    thousandSeparator
                    prefix={suffix ? null : symbol}
                    suffix={suffix ? symbol : null}
                  />
                </span>
              )}
              {/* <span> ₹{productItem.price}</span> */}
              <NumberFormat
                value={` ${productItem.price} `}
                displayType="text"
                thousandSeparator
                prefix={suffix ? null : `${symbol} `}
                suffix={suffix ? symbol : null}
              />
            </button>
            {quantity === 0 && <div className="add-button-wihtout-photo">{isSlotDisable ? 'EMPTY' : 'ADD'}</div>}
            {quantity > 0 && (
              <div className="add-button-wihtout-photo-selected">
                <div tabIndex={0} role="button" onClick={e => this.handleClick(e, -1)}>
                  -
                </div>
                <div>{quantity}</div>
                <div>+</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ cartReducer, machine }) => ({
  disableSelect: cartReducer.disableSelect,
  numberOfReset: cartReducer.numberOfReset,
  defaultProductURL: machine.machineDetails.defaultProductURL,
});

export default connect(mapStateToProps)(memo(WithoutImageItemCardDisplayV2));
