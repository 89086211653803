import React from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

import './style.css';

export default ({ onKeyPress, isNumberOnly }) => {
  const defaultKeyboard = [
    '1 2 3 4 &#8592;',
    '5 6 7 8 9 0',
    'q w e r t y u i o p',
    'a s d f g h j k l',
    'z x c v b n m',
  ];
  const onlyNumber = ['1 2 3', '4 5 6', '7 8 9', ' 0 &#8592;'];
  if (!isNumberOnly) {
    return (
      <div className="width-100-percent small-keys">
        <Keyboard
          height="85px"
          style={{ flex: 1, height: '85px', width: '100%', display: 'block', minHeight: '500px', fontSize: '30px' }}
          className="something"
          onKeyPress={button => onKeyPress(button)}
          layout={{
            default: isNumberOnly ? onlyNumber : defaultKeyboard,
          }}
        />
      </div>
    );
  }
  return (
    <div className="width-100-percent big-keys">
      <Keyboard
        height="85px"
        style={{ flex: 1, height: '85px', width: '100%', display: 'block', minHeight: '500px', fontSize: '30px' }}
        onKeyPress={button => onKeyPress(button)}
        layout={{
          default: isNumberOnly ? onlyNumber : defaultKeyboard,
        }}
      />
    </div>
  );
};
