import React, { useState, useEffect } from 'react';
import { getPaymentDetails } from '../../actions/payment';
import { ThreeBubbleLoader } from '../../components';
import QRDisplay from '../../components/QrDisplay';

const PaymentModeStripeQR = ({ requestId, checkPaymentStatus }) => {
  const [isStripeLinkFetched, setisStripeLinkFetched] = useState(false);
  const [isLoadingStripeQR, setisLoadingStripeQR] = useState(true);
  const [isStripeLinkFetchedError, setisStripeLinkFetchedError] = useState(false);
  const [StripeQRData, setStripeQRData] = useState('');
  const getStripeDetails = () => {
    window.heap.track('Payment Method', {
      paymentMethod: 'stripe',
      time: Date.now(),
      requestId,
    });

    if (StripeQRData !== '') {
      // console.log('have qr data');
      return;
    }

    if (!requestId) {
      window.heap.track('no request id found ', {
        time: Date.now(),
      });
      setisStripeLinkFetched(false);
      setisLoadingStripeQR(false);
      setisStripeLinkFetchedError(true);
      return;
    }

    const details = {
      payment_method: 'stripe',
      request_id: requestId,
      separate_endpoint: true,
    };

    setisStripeLinkFetched(false);
    setisLoadingStripeQR(true);
    setisStripeLinkFetchedError(false);

    getPaymentDetails(details).then(data => {
      if (!data.error) {
        window.heap.track('Stripe details Response', {
          paymentMethod: 'Stripe',
          time: Date.now(),
          requestId,
          data,
        });
        setisStripeLinkFetched(true);
        setisLoadingStripeQR(false);
        setisStripeLinkFetchedError(false);
        setStripeQRData(data.data.qr_data);

        checkPaymentStatus('stripe'); // Check payment status for paytm
      } else {
        window.heap.track('Stripe details Error', {
          paymentMethod: 'Stripe',
          time: Date.now(),
          requestId,
        });

        setisStripeLinkFetched(false);
        setisLoadingStripeQR(false);
        setisStripeLinkFetchedError(true);
        setStripeQRData('');
      }
    });
  };

  useEffect(() => {
    // console.log('getStripeDetails ----');
    window.heap.track('Stripe component renderd', {
      time: Date.now(),
    });
    getStripeDetails();
  }, []);

  if (!requestId) {
    return <ThreeBubbleLoader />;
  }

  return (
    <QRDisplay
      isQRLoading={isLoadingStripeQR}
      isQRFetched={isStripeLinkFetched}
      isQRFetchError={isStripeLinkFetchedError}
      QRData={StripeQRData}
      requestId={requestId}
      showRetry
      retryButtonClickFunction={getStripeDetails}
    />
  );
};

export default PaymentModeStripeQR;
