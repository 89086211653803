import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { REQUEST_REDUCER_TYPES, MODAL_REDUCER, CASH_RETURNER_STAGE } from '../../actions/constants';
import { updateRequestStatus } from '../../actions/payment';
import GetUserPhoneNumber from '../../components/GetUserPhoneNumber';
import { addMoneyToWalletV2, updateCashDisposed, pushPartialAddedCash } from '../../actions/cashActions';
import TickAnimationAudio from '../../components/TickAnimationAudio';

const PhoneNumberInput = ({
  requestIdForAfterVend,
  machineId,
  cashReturnerStage,
  amountDispensed,
  phoneNumberInputLanguage,
  unsetRequestIdAfterVend,
  closePushRemainderToWallet,
  isPaymentCancel,
}) => {
  const {
    txtSubHeading,
    txtMainMsg,
    txtSubHeadingCoinErr,
    txtSubHeadingErr,
    btnSubmit,
    txtMainMsgSuccess,
  } = phoneNumberInputLanguage;

  const [phoneNumber, setphoneNumber] = useState('');
  const [isSubmitButtonLoading, setisSubmitButtonLoading] = useState(false);
  const [subHeading, setsubHeading] = useState(txtSubHeading);
  const [mainMessage, setmainMessage] = useState(txtMainMsg);
  const [isMoneyReturned, setisMoneyReturned] = useState(false);

  const close = () => {
    unsetRequestIdAfterVend();
    closePushRemainderToWallet();
  };

  // useEffect(() => {
  //   const { cashPhoneOption } = JSON.parse(localStorage.getItem('cash'));
  //   if (cashPhoneOption === 'coin_returner_with_phone_after_payment') {
  //     const requestData = {
  //       order_id: requestIdForAfterVend,
  //     };
  //     addMoneyToWallet(requestData);
  //   }
  // }, []);

  useEffect(() => {
    if (cashReturnerStage === CASH_RETURNER_STAGE.BROKEN) {
      setsubHeading(txtSubHeadingCoinErr);
    }

    return () => {
      close();
      updateRequestStatus(requestIdForAfterVend, 'cancelled');
    };
  }, []);

  const handlePushRemainderToWallet = async requestData => {
    try {
      const response = await addMoneyToWalletV2(requestData);
      // console.log(response);
      if (!response.error) {
        setisMoneyReturned(true);
        setmainMessage(txtMainMsgSuccess);
        setTimeout(() => close(), 4000);
      } else {
        setsubHeading(txtSubHeadingErr);
      }
      setisSubmitButtonLoading(false);
    } catch (e) {
      setsubHeading(txtSubHeadingErr);
      // console.log(e);
      setisSubmitButtonLoading(false);
    }
  };

  const handleUpdateCashDisposed = async requestData => {
    try {
      const response = await updateCashDisposed(requestData);
      // console.log(response);
      if (!response.error) {
        setisMoneyReturned(true);
        setmainMessage(txtMainMsgSuccess);
        setTimeout(() => close(), 4000);
      } else {
        setsubHeading(txtSubHeadingErr);
      }
      setisSubmitButtonLoading(false);
    } catch (e) {
      setsubHeading(txtSubHeadingErr);
      // console.log(e);
      setisSubmitButtonLoading(false);
    }
  };
  const handlePushPartialAddedCash = async requestData => {
    try {
      const response = await pushPartialAddedCash(requestData);
      // console.log(response);
      if (!response.error) {
        setisMoneyReturned(true);
        setmainMessage(txtMainMsgSuccess);
        setTimeout(() => close(), 4000);
      } else {
        setsubHeading(txtSubHeadingErr);
      }
      setisSubmitButtonLoading(false);
    } catch (e) {
      setsubHeading(txtSubHeadingErr);
      // console.log(e);
      setisSubmitButtonLoading(false);
    }
  };

  const buttonClick = async () => {
    const { cashPhoneOption } = JSON.parse(localStorage.getItem('cash'));

    // console.log('Phone number is ', phoneNumber);
    // console.log('RequestId is ', requestIdForAfterVend);

    setisSubmitButtonLoading(true);
    if (isPaymentCancel) {
      const requestData = {
        order_id: requestIdForAfterVend,
        phone_number: phoneNumber,
      };
      handlePushPartialAddedCash(requestData);
    } else if (cashPhoneOption === 'coin_returner_with_phone_after_payment') {
      const requestData = {
        request_id: requestIdForAfterVend,
        machine_id: machineId,
        phone_number: phoneNumber,
        amount: amountDispensed || 0,
      };

      handleUpdateCashDisposed(requestData);
    } else {
      const requestData = {
        order_id: requestIdForAfterVend,
        machineId,
        phone_number: phoneNumber,
      };

      handlePushRemainderToWallet(requestData);
    }
  };

  const handlePhoneNumberChange = key => {
    // console.log('key ', key);
    if (key === '<') {
      setphoneNumber(phoneNumber.slice(0, -1));
    } else {
      setphoneNumber(phoneNumber + key);
    }
  };

  return (
    <Modal isOpen dimmer="inverted" size="md">
      <ModalHeader>{mainMessage}</ModalHeader>
      <ModalBody>
        {isMoneyReturned && (
          <div style={{ height: '200px', padding: '50px' }}>
            <TickAnimationAudio />
          </div>
        )}
        {!isMoneyReturned && (
          <div>
            <GetUserPhoneNumber
              subHeading={subHeading}
              buttonText={btnSubmit}
              isSubmitButtonLoading={isSubmitButtonLoading}
              phoneNumber={phoneNumber}
              handlePhoneNumberChange={handlePhoneNumberChange}
              onButtonClick={buttonClick}
              isPhoneNumberValid={phoneNumber.length === 10 || phoneNumber.length === 9}
              showCloseButton
              onCloseButtonClick={close}
            />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = ({ machine, requestReducer, cashReducer, languageReducer }) => ({
  machineId: machine.machineId,
  mainMessage: machine.mainMessage,
  secondaryMessage: machine.secondaryMessage,
  requestIdForAfterVend: requestReducer.requestIdForAfterVend,
  isPaymentCancel: requestReducer.isPaymentCancel,
  cashReturnerStage: cashReducer.cashReturnerStage,
  amountDispensed: cashReducer.amountDispensed,
  phoneNumberInputLanguage: languageReducer.phoneNumberInputLanguage,
});

const mapDispatchToProps = dispatch => ({
  unsetRequestIdAfterVend: () => dispatch({ type: REQUEST_REDUCER_TYPES.UNSET_REQUEST_ID_AFTER_VEND }),
  closePushRemainderToWallet: () =>
    dispatch({ type: MODAL_REDUCER.OPEN_PUSH_REMAINDER_TO_WALLET_MODAL, payload: false }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberInput);
