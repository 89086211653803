/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';
import { Row, Col, Alert, Button } from 'reactstrap';
import uniqid from 'uniqid';
import { ReactKeyboard } from '../index';

export default ({
  loginFailed,
  loginFailedReason,
  allFields,
  submitting,
  handleSubmit,
  onKeyboardInput,
  openKeyboard,
  activeFieldIndex,
  onInput,
  manualReload,
  openServiceMode,
  isError,
  errorMsg,
}) => (
  <div className="login-page">
    <Row>
      <Col xs={12} sm={12} md={12} lg={12}>
        {/* <img
          onKeyDown={() => manualReload()}
          data-test="logoImg"
          alt="Wendor"
          src="/logo.png"
          className="logo_icon"
          onClick={() => manualReload()}
        /> */}
      </Col>
    </Row>

    <Row>
      <Col xs={12} sm={12} md={12}>
        <h5 onClick={() => manualReload()} className="text-red ">
          Setup Your Machine
        </h5>
        {isError && (
          <Alert color="warning" className="login-page-alert">
            <span data-testid="refill-login-failAlert">{errorMsg}</span>
          </Alert>
        )}
        {!isError && loginFailed && (
          <Alert color="warning" data-testid="login-fail-message" className="login-page-alert">
            <span> {loginFailedReason || 'Credentials not correct.'}</span>
          </Alert>
        )}
      </Col>
    </Row>
    <Row>
      <Col key={uniqid()} xs={12} sm={12} md={12} lg={12}>
        <input
          readOnly
          data-testid="login-input-MachineId"
          className={`form-control form-input-field login_page_inputs 
                      ${activeFieldIndex === 0 ? 'active-input-field' : ''}`}
          placeholder="Machine ID"
          name="MachineId"
          type="text"
          onKeyDown={e => onInput(e)}
          value={allFields[0].value}
          onFocus={() => openKeyboard(0)}
        />
      </Col>
      <Col key={uniqid()} xs={12} sm={12} md={12} lg={12}>
        <input
          readOnly
          data-testid="login-input-Password"
          className={`form-control form-input-field login_page_inputs 
                      ${activeFieldIndex === 1 ? 'active-input-field' : ''}`}
          placeholder="Password"
          name="Password"
          type="password"
          onKeyDown={e => onInput(e)}
          value={allFields[1].value}
          onFocus={() => openKeyboard(1)}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <span>
          Version - {process.env.REACT_APP_VERSION} {process.env.REACT_APP_RUN_ENV}
        </span>
      </Col>
    </Row>
    <Row className="login-buttton-container">
      <Col xs={12} sm={12} md={12} lg={12}>
        <Button
          data-testid="loginButton"
          className="wendor-bg-green wendor-big-button"
          size="small"
          onClick={() => handleSubmit()}>
          <span>{submitting ? 'Logging in...' : 'Log In'}</span>
        </Button>
      </Col>
    </Row>
    <div className="keyboard_show keyboard_bottom">
      <ReactKeyboard isNumberOnly data-test="keyboard" onKeyPress={onKeyboardInput} />
    </div>
    <span
      role="img"
      aria-label="setting"
      // className="wendor-bg-green wendor-small-button wendor-big-button "
      size="small"
      className="service_mode_icon"
      onClick={() => openServiceMode()}>
      ⚙️ {/*  Open Service mode */}
    </span>
  </div>
);
