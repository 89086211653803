import React from 'react';
import { connect } from 'react-redux';
import { LANGUAGE_REDUCER } from '../actions/constants';
import { getMachineLanguage } from '../actions/machine';

const Language = ChildComponent => {
  // const [paymentModeRfid, setPaymentModeRfid] = useState({});
  class LanguageHOC extends React.Component {
    constructor(props) {
      super(props);

      this.getSetLanguage = this.getSetLanguage.bind(this);
    }

    componentDidMount() {
      this.getSetLanguage();
    }

    getSetLanguage = async () => {
      const { updateLanguage } = this.props;
      try {
        const { error, lang_string: langString } = await getMachineLanguage();
        if (!error) {
          updateLanguage(langString);
        }
      } catch (e) {
        // console.log('ERROR in getMachineLanguage', e);
      }
    };

    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  const mapStateToProps = ({ machine }) => {
    return {
      machineId: machine.machineId,
    };
  };

  const mapDispatchToProps = dispatch => ({
    updateLanguage: payload => dispatch({ type: LANGUAGE_REDUCER.SET_VALUES, payload }),
  });

  return connect(mapStateToProps, mapDispatchToProps)(LanguageHOC);
};

//
export default Language;
