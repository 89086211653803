/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import RefillUI from '../../components/Refill';

import { testRow } from '../../actions/refill';

const Refill = ({
  products,
  changeProduct,
  homeMotorTest,
  objectKey,
  updateProductQuantity,
  allowPriceChange,
  allowProductChange,
}) => {
  const testMotor = row => {
    // console.log('TESTING ', row);
    const rows = [];
    rows.push(...row);
    try {
      testRow(rows);
    } catch (e) {
      // console.log('ERROR', e);
    }
  };

  return (
    <RefillUI
      products={products}
      changeProduct={changeProduct}
      homeMotorTest={homeMotorTest}
      objectKey={objectKey}
      updateProductQuantity={updateProductQuantity}
      allowPriceChange={allowPriceChange}
      allowProductChange={allowProductChange}
      testMotor={testMotor}
    />
  );
};

export default Refill;
