import React from 'react';
import { Button } from 'reactstrap';

const ButtonMediumRed = ({ buttonText, buttonAction, dataTestid }) => {
  return (
    <Button
      data-testid={dataTestid}
      data-test="reset-cart-button"
      className="wendor-big-button wendor-bg-red"
      onClick={() => buttonAction()}>
      {buttonText}
    </Button>
  );
};

export default ButtonMediumRed;
