/*
  This will render component that will process the selected  payment method
*/

import React from 'react';
import { Row, Col } from 'reactstrap';

import PaymentModeCash from '../../containers/PaymentModal/paymentModeCash';
import PaymentModePaytm from '../../containers/PaymentModal/paymentModePaytm';
import PaymentModeUpi from '../../containers/PaymentModal/paymentModeUpi';
import PaymentModeLink from '../../containers/PaymentModal/paymentModeLink';
import OTPbasedPayment from '../../containers/PaymentModal/OTPbasedPayment';
import OTPbaseFreeVend from '../../containers/PaymentModal/OTPbaseFreeVend';
import RfidPay from '../../containers/PaymentModal/paymentModeRfid';
import Sodexo from '../../containers/PaymentModal/paymentModes/sodexo';
import OfflineCash from '../../containers/PaymentModal/paymentModes/offlineCash';
import PaymentModeStripeQR from '../../containers/PaymentModal/paymentModeStripeQR';
import PaymentModeRazorQR from '../../containers/PaymentModal/paymentModeRazorQR';
import FonePayFlow from '../../containers/PaymentModal/paymentModeFonePay';
import EzeTapPayFlow from '../../containers/PaymentModal/paymentModeEzeTap';
import NoPaymentMethodNotSelectedUI from '../PaymentModes/PaymentModeNotSelected';
import PaymentModeEmailOtp from '../../containers/PaymentModal/paymentModeEmailOtp';

const RenderPaymentData = ({
  enabledPaymentGateways,
  paymentMethod,
  requestId,
  checkPaymentStatus,
  smsSentStatus,
  handlePhoneNumberChange,
  phoneNumber,
  changePaymentMethod,
  isPhoneNumberValid,
  toggleConformPaymentCancelModal,
  handlePinNumberChange,
  rfidPin,

  swiftWalletOTPStatusUpdate,
}) => {
  return (
    <div
      style={{
        // border:"1px solid green",
        width: '100%',
      }}>
      {enabledPaymentGateways.indexOf('paytm') >= 0 && paymentMethod === 'paytm' && (
        <PaymentModePaytm requestId={requestId} checkPaymentStatus={checkPaymentStatus} />
      )}
      {enabledPaymentGateways.indexOf('upi') >= 0 && paymentMethod === 'upi' && (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <PaymentModeUpi requestId={requestId} checkPaymentStatus={checkPaymentStatus} />
          </Col>
        </Row>
      )}
      {enabledPaymentGateways.indexOf('link') >= 0 && paymentMethod === 'stripe_link_qr' && (
        <PaymentModeStripeQR requestId={requestId} checkPaymentStatus={checkPaymentStatus} />
      )}
      {enabledPaymentGateways.indexOf('link') >= 0 && paymentMethod === 'razorpay_link_qr' && (
        <PaymentModeRazorQR requestId={requestId} checkPaymentStatus={checkPaymentStatus} />
      )}
      {enabledPaymentGateways.indexOf('link') >= 0 && paymentMethod === 'link' && (
        <Row>
          <PaymentModeLink
            smsSentStatus={smsSentStatus}
            requestId={requestId}
            handlePhoneNumberChange={handlePhoneNumberChange}
            phoneNumber={phoneNumber}
            changePaymentMethod={changePaymentMethod}
            isPhoneNumberValid={isPhoneNumberValid}
          />
        </Row>
      )}
      {enabledPaymentGateways.indexOf('cash') >= 0 && paymentMethod === 'cash' && (
        <PaymentModeCash
          requestId={requestId}
          isPhoneNumberValid={isPhoneNumberValid}
          handlePhoneNumberChange={handlePhoneNumberChange}
          phoneNumber={phoneNumber}
          toggleConformPaymentCancelModal={toggleConformPaymentCancelModal}
        />
      )}
      {enabledPaymentGateways.indexOf('rfid') >= 0 && paymentMethod === 'rfid' && (
        <RfidPay
          handlePinNumberChange={handlePinNumberChange}
          requestId={requestId}
          rfidPin={rfidPin}
          changePaymentMethod={changePaymentMethod}
        />
      )}
      {enabledPaymentGateways.indexOf('swift') >= 0 && paymentMethod === 'swift' && (
        <OTPbasedPayment otpPaymentType="swift" phoneNumber={phoneNumber} updateOTPSent={swiftWalletOTPStatusUpdate} />
      )}

      {enabledPaymentGateways.indexOf('free_vend') >= 0 && paymentMethod === 'free_vend' && (
        <OTPbaseFreeVend
          otpPaymentType="free_vend"
          phoneNumber={phoneNumber}
          updateOTPSent={swiftWalletOTPStatusUpdate}
        />
      )}

      {enabledPaymentGateways.indexOf('lps_bossard') >= 0 && paymentMethod === 'lps_bossard' && (
        <OTPbasedPayment
          otpPaymentType="lps_bossard"
          phoneNumber={phoneNumber}
          updateOTPSent={swiftWalletOTPStatusUpdate}
        />
      )}
      {enabledPaymentGateways.indexOf('prasuk') >= 0 && paymentMethod === 'prasuk' && (
        <OTPbasedPayment otpPaymentType="prasuk" phoneNumber={phoneNumber} updateOTPSent={swiftWalletOTPStatusUpdate} />
      )}
      {enabledPaymentGateways.indexOf('paytm') >= 0 && paymentMethod === 'prasuk-paytm' && (
        <OTPbasedPayment
          otpPaymentType="prasuk-paytm"
          phoneNumber={phoneNumber}
          updateOTPSent={swiftWalletOTPStatusUpdate}
          changePaymentMethod={changePaymentMethod}
        />
      )}
      {enabledPaymentGateways.indexOf('sodexo') >= 0 && paymentMethod === 'sodexo' && (
        <Sodexo
          checkPaymentStatus={checkPaymentStatus}
          handlePhoneNumberChange={handlePhoneNumberChange}
          isPhoneNumberValid={isPhoneNumberValid}
          phoneNumber={phoneNumber}
          requestId={requestId}
        />
      )}
      {enabledPaymentGateways.indexOf('offlinecash') >= 0 && paymentMethod === 'offlinecash' && (
        <OfflineCash
          handlePhoneNumberChange={handlePhoneNumberChange}
          isPhoneNumberValid={isPhoneNumberValid}
          phoneNumber={phoneNumber}
          requestId={requestId}
          toggleConformPaymentCancelModal={toggleConformPaymentCancelModal}
        />
      )}
      {enabledPaymentGateways.indexOf('fonepay') >= 0 && paymentMethod === 'fonepay' && (
        <FonePayFlow requestId={requestId} checkPaymentStatus={checkPaymentStatus} />
      )}
      {enabledPaymentGateways.indexOf('ezetap') >= 0 && paymentMethod === 'ezetap' && (
        <EzeTapPayFlow requestId={requestId} checkPaymentStatus={checkPaymentStatus} />
      )}
      {enabledPaymentGateways.indexOf('email_otp') >= 0 && paymentMethod === 'email_otp' && (
        <PaymentModeEmailOtp
          requestId={requestId}
          otpPaymentType="email_otp"
          phoneNumber={phoneNumber}
          updateOTPSent={swiftWalletOTPStatusUpdate}
        />
      )}
      {paymentMethod === undefined && <NoPaymentMethodNotSelectedUI />}
    </div>
  );
};

export default RenderPaymentData;
