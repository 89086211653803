import { createStore, applyMiddleware, compose } from 'redux';
import Thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import Raven from 'raven-js';
import createRavenMiddleware from 'raven-for-redux';
import Reducer from './reducers';
import {
  CASH_MODULE_STATUS,
  ESP_STATUS,
  MACHINE_STATUS,
  MQTT_STATUS,
  PAYMENT_STATUS,
  VEND_STATUS,
  RFID_MODULE_STATUS,
  CURRENCY_MODULE_STATUS,
} from '../actions/constants';

const initialState = {
  machine: {
    machineStatus: MACHINE_STATUS.ONLINE,
    mqttStatus: MQTT_STATUS.DISCONNECTED,
    espStatus: ESP_STATUS.DISCONNECTED,
    isConfiguring: true, // Checking for first time
    loggedIn: false,
    multivacDetails: {
      version: 'V3',
      host: null,
      username: null,
      password: null,
      port: null,
      clientId: null,
      useSSL: false,
    },
    machineType: window.localStorage.getItem('machineType'),
    token: window.localStorage.getItem('token'),
    machineId: window.localStorage.getItem('machineId'),
    enabledPaymentGateways: [],
    offlineEnabledMethods: [],
    canUseOffline: false,
    socketURL: null,
    machineDetails: {
      maxItems: 5,
      vmcPingTimeout: 30, // In seconds
      feedbackModalTimeout: 60, // In seconds
      enabledPaymentGateways: [],
      defaultProductURL: null,
      modalSize: 'md', // 'sm', 'md', 'lg', 'xl'
    },
  },
  cartReducer: {
    productsWithCartQuantity: [],
    cartData: [], // Data in the cart
    totalAmount: 0.0, // Total amount of cart products
    totalItems: 0, // Total items in cart
    newCustomer: true, // If a new Customer is using the machine
    disableSelect: false,
  },
  vendReducer: {
    vendedItems: [], // Vended Items
    vendStatus: VEND_STATUS.VENDING, // is Order Completed
    currentVendingItemIndex: 0,
    totalItemsFailed: 0,
    itemsToVend: [],
    customDisplayMessage: '',
  },
  modalReducer: {
    isOpenQRModal: false, // Open Payment Modal or not
    isOpenVendModal: false, // Open Vending Modal or not
    isOpenErrorModal: false, // Open Error Modal or not
  },
  requestReducer: {
    requestId: null, // requestId
    paymentStatus: PAYMENT_STATUS.NOT_INITIATED, // To show the payment status
    totalAmountPaid: 0, // Total Amount Paid
    totalAmountUpdated: 0,
    paymentMethodUsed: null,
  },
  rfidModule: {
    cashModuleStatus: RFID_MODULE_STATUS.NOT_ACCEPTING, // Cash Module Status
    userDetails: null,
  },
  cashReducer: {
    cashModuleStatus: CASH_MODULE_STATUS.NOT_ACCEPTING, // Cash Module Status
  },
  currencyReducer: {
    currencyModuleStatus: CURRENCY_MODULE_STATUS.NOT_ACCEPTING, // Cash Module Status
  },
  log: {
    pastClicks: [],
    isScreenSacerActive: false,
  },
};

Raven.config(process.env.REACT_APP_REACT_APP_RAVEN_DSN).install();
const composeEnhancers = composeWithDevTools({
  compose: window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose,
  realtime: true,
  trace: true,
  traceLimit: 25,
});
const store = createStore(
  Reducer,
  initialState,
  /* preloadedState, */
  composeEnhancers(applyMiddleware(Thunk, createRavenMiddleware(Raven))),
);
// const store = createStore(Reducer, initialState, applyMiddleware(Thunk));

export { initialState };
export default store;
