import axios from 'axios';

export const cashModuleStatusUpdate = async (status, requestId, comment, code) => {
  const requestBody = {
    status,
    requestId,
    comment,
    code,
  };

  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/initResponseCashModule`;

    const response = await axios({
      url,
      method: 'post',
      data: requestBody,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const restartCashMachine = () => {
  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/resetCashModule`;

    const response = axios({
      url,
      method: 'get',
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const cashReceivedUpdate = async data => {
  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/cashTxnUpdateV2`;

    const response = await axios({
      url,
      method: 'post',
      data,
    });
    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const pushPartialAddedCash = async data => {
  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/pushAddedCash`;

    const response = await axios({
      url,
      method: 'post',
      data,
    });
    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const addMoneyToWallet = async requestData => {
  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/pushRemainderToWallet`;

    const response = await axios({
      url,
      method: 'post',
      data: requestData,
    });
    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const addMoneyToWalletV2 = async requestData => {
  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/pushRemainderToWalletV2`;

    const response = await axios({
      url,
      method: 'post',
      data: requestData,
    });
    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const updateCashDisposed = async requestData => {
  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/updateCashDisposed`;

    const response = await axios({
      url,
      method: 'post',
      data: requestData,
    });
    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const disablingCashModule = async requestData => {
  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/disableCashModule`;

    const response = await axios({
      url,
      method: 'post',
      data: requestData,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const getCashInMachine = async () => {
  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/cashInMachine`;

    const response = await axios({
      url,
      method: 'get',
    });
    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const currencyModuleResponse = async data => {
  try {
    const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/api/currencyModuleResponse`;
    const requestBody = {
      ...data,
    };

    const response = await axios({
      url,
      method: 'post',
      data: requestBody,
    });

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};
