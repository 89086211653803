import React, { useState, useEffect } from 'react';
import { getPaymentDetails } from '../../actions/payment';
import { ThreeBubbleLoader } from '../../components';
import QRDisplay from '../../components/QrDisplay';

const PaymentModeRazorQR = ({ requestId, checkPaymentStatus }) => {
  const [isPayTmQRFetched, setisPayTmQRFetched] = useState(false);
  const [isLoadingRazorQR, setisLoadingRazorQR] = useState(true);
  const [isRazorQRFetchedError, setisRazorQRFetchedError] = useState(false);
  const [RazorQRData, setRazorQRData] = useState('');
  const getRazorQRDetails = async () => {
    // GET PayTM QR

    window.heap.track('Payment Method', {
      paymentMethod: 'RazorLinkQR',
      time: Date.now(),
      requestId,
    });

    if (RazorQRData !== '') {
      // console.log('have qr data');
      return;
    }

    if (!requestId) {
      window.heap.track('no request id found ', {
        time: Date.now(),
      });
      setisPayTmQRFetched(false);
      setisLoadingRazorQR(false);
      setisRazorQRFetchedError(true);
      return;
    }

    const details = {
      phone: 696969696,
      payment_method: 'link',
      request_id: requestId,
    };

    setisPayTmQRFetched(false);
    setisLoadingRazorQR(true);
    setisRazorQRFetchedError(false);

    const data = await getPaymentDetails(details);

    if (!data.error) {
      window.heap.track('RazorLinkQR details Response', {
        paymentMethod: 'RazorLinkQR',
        time: Date.now(),
        requestId,
        data,
      });
      setisPayTmQRFetched(true);
      setisLoadingRazorQR(false);
      setisRazorQRFetchedError(false);
      setRazorQRData(data.data);

      checkPaymentStatus('link'); // Check payment status
    } else {
      window.heap.track('RazorLinkQR details Error', {
        paymentMethod: 'RazorLinkQR',
        time: Date.now(),
        requestId,
      });

      setisPayTmQRFetched(false);
      setisLoadingRazorQR(false);
      setisRazorQRFetchedError(true);
      setRazorQRData('');
    }
  };

  useEffect(() => {
    // console.log('getPayTMDetails ----');
    window.heap.track('RazorQR component renderd', {
      time: Date.now(),
    });
    getRazorQRDetails().catch(error => console.error(error));
  }, []);

  if (!requestId) {
    return <ThreeBubbleLoader />;
  }

  return (
    <QRDisplay
      isQRLoading={isLoadingRazorQR}
      isQRFetched={isPayTmQRFetched}
      isQRFetchError={isRazorQRFetchedError}
      QRData={RazorQRData}
      requestId={requestId}
      showRetry
      retryButtonClickFunction={getRazorQRDetails}
    />
  );
};

export default PaymentModeRazorQR;
