/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Row, Button } from 'reactstrap';
import RefillDisplayBox from './DisplayBox';

const RefillUI = ({
  products,
  changeProduct,
  homeMotorTest,
  objectKey,
  updateProductQuantity,
  allowPriceChange,
  allowProductChange,
  testMotor,
}) => {
  return (
    <Row className="padding-10 ">
      <div className="item-row padding-right-20">
        {products.map(product => (
          <RefillDisplayBox
            changeProduct={changeProduct}
            homeMotorTest={homeMotorTest}
            objectKey={objectKey}
            product={product}
            updateProductQuantity={updateProductQuantity}
            allowPriceChange={allowPriceChange}
            allowProductChange={allowProductChange}
          />
        ))}
        <Button
          className="wendor-flat-button-full width-100-px padding-10"
          onClick={() => testMotor([...products.map(p => p.pos)])}>
          Test ROW ?
        </Button>
      </div>
    </Row>
  );
};

export default RefillUI;
