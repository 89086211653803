import Dexie from 'dexie';
import axios from 'axios';

function uniqid(prefix = '', b = false) {
  const c = Date.now() / 1000;
  let d = c
    .toString(16)
    .split('.')
    .join('');
  while (d.length < 14) {
    d += '0';
  }
  let e = '';
  if (b) {
    e = '.';
    const f = Math.round(Math.random() * 100000000);
    e += f;
  }
  return prefix + d + e;
}

function errorHandler() {
  // console.log('Error in Query: ', e);
}

function getDB() {
  // Schema

  /*
    request: 'requestId, phoneNumber, requestAmount, paidAmount, paymentMethod, paymentStatus, vendStatus, feedback, createdAt, isSynced',
    requestItem: 'requestItemId, requestId, productId, price, position, vendStatus, reason, createdAt',
    payment: 'paymentId, requestId, paymentMethod, amount, createdAt'

  */
  try {
    const db = new Dexie('kiosk');
    db.version(1).stores({
      request: 'requestId',
      requestItem: 'requestItemId, requestId',
      payment: 'paymentId, requestId',
    });

    return db.open();
  } catch (e) {
    return new Promise(resolve => resolve(false));
  }
}

function createRequest(requestId, items, totalAmount) {
  // console.log('Creating request in websql');
  const linearItems = [];
  const requestData = {
    requestId,
    phoneNumber: null,
    requestAmount: totalAmount,
    paidAmount: 0,
    paymentMethod: null,
    paymentStatus: 'started',
    vendStatus: 'not started',
    feedback: null,
    createdAt: Date.now(),
    isSynced: false,
  };
  if (items && items.length < 1) {
    // No Items
    return;
  }
  let count = 1;
  items.forEach(item => {
    for (let i = 0; i < item.quantity; i += 1) {
      const requestItemId = `${requestId}_${count}`;

      const requestItemData = {
        requestItemId,
        requestId,
        productId: item.product_id,
        price: item.price,
        position: item.pos,
        vendStatus: 'not started',
        reason: null,
        createdAt: Date.now(),
      };
      linearItems.push(requestItemData);
      count += 1;
    }
  });
  getDB()
    .then(db => {
      if (!db) {
        errorHandler('Error Getting DB');
      } else {
        // Create Request and add requestItems
        try {
          db.request.add(requestData).then(() => {
            db.requestItem.bulkAdd(linearItems);
          });
        } catch (e) {
          errorHandler(e);
        }
      }
    })
    .catch(err => errorHandler(err));
}

function getRequestItems(requestId) {
  const promise = new Promise((resolve, reject) => {
    getDB().then(async db => {
      if (!db) {
        errorHandler('Error Getting DB');
        resolve([]);
      }
      try {
        let requestItems = [];
        const items = await db.requestItem.toArray();
        if (items.length > 0) {
          requestItems = items.filter(item => item.requestId === requestId);
        }
        resolve(requestItems);
      } catch (e) {
        errorHandler(e);
        reject(e);
      }
    });
  });

  return promise;
}

function updateRequestPaymentStatus(requestId, paymentStatus, paidAmount = 0, paymentMethod = null) {
  const updateRequestData = {
    paymentStatus,
  };
  if (paymentMethod) {
    updateRequestData.paymentMethod = paymentMethod;
  }
  if (paidAmount) {
    updateRequestData.paidAmount = paidAmount;
  }
  try {
    getDB()
      .then(db => {
        if (!db) {
          errorHandler('Error Getting DB');
        } else {
          db.request.update(requestId, updateRequestData).then(updated => {
            if (updated) {
              // console.log('Request updated');
            } else {
              // console.log('Error in updating Request');
            }
          });
        }
      })
      .catch(e => errorHandler(e));
  } catch (e) {
    // console.log(e);
  }
}

function updateRequestPhoneNumber(requestId, phoneNumber) {
  getDB()
    .then(db => {
      if (!db) {
        errorHandler('Error Getting DB');
      } else {
        db.request.update(requestId, { phoneNumber }).then(updated => {
          if (updated) {
            // console.log('Phone Number updated');
          } else {
            // console.log('Error in updating phone number');
          }
        });
      }
    })
    .catch(e => errorHandler(e));
}

function updateRequestFeedback(requestId, feedback) {
  getDB()
    .then(db => {
      if (!db) {
        errorHandler('Error Getting DB');
      } else {
        db.request.update(requestId, { feedback }).then(updated => {
          if (updated) {
            // console.log('Request Feedback updated');
          } else {
            // console.log('Error in updating Request Feedback');
          }
        });
      }
    })
    .catch(e => errorHandler(e));
}

function updateRequestVendStatus(requestId, vendStatus) {
  getDB()
    .then(db => {
      if (!db) {
        errorHandler('Error Getting DB');
      } else {
        db.request.update(requestId, { vendStatus }).then(updated => {
          if (updated) {
            // console.log('Vend Status of Request updated');
          } else {
            // console.log('Error in updating Vend Status of Request');
          }
        });
      }
    })
    .catch(e => errorHandler(e));
}

function deleteRequestData(requestId) {
  getDB()
    .then(db => {
      if (!db) {
        errorHandler('Error Getting DB');
      } else {
        // Delete the request
        try {
          db.request
            .where('requestId')
            .equals(requestId)
            .delete();
          db.requestItem
            .where('requestId')
            .equals(requestId)
            .delete();
          db.payment
            .where('requestId')
            .equals(requestId)
            .delete();
        } catch (e) {
          errorHandler(e);
        }

        // db.request.update(requestId, { isSynced }).then(updated => {
        //   if (updated) {
        //     // console.log('Sync Status of Request updated');
        //   } else {
        //     // console.log('Error in updating Sync Status of Request');
        //   }
        // });
      }
    })
    .catch(e => errorHandler(e));
}

function updateRequestItemVendStatus(requestItemId, vendStatus, reason) {
  // console.log('Updated Request Item Status: ', requestItemId);
  getDB()
    .then(db => {
      if (!db) {
        errorHandler('Error Getting DB');
      } else {
        db.requestItem.update(requestItemId, { vendStatus, reason }).then(updated => {
          if (updated) {
            // console.log('Vend Status of RequestItem updated', requestItemId);
          } else {
            // console.log('Error in updating Vend Status of RequestItem', updated);
          }
        });
      }
    })
    .catch(e => errorHandler(e));
}

function createPayment(requestId, paymentMethod, amount) {
  getDB().then(db => {
    if (!db) {
      errorHandler('Error Getting DB');
    } else {
      const paymentData = {
        paymentId: uniqid(),
        requestId,
        paymentMethod,
        amount,
        createdAt: Date.now(),
      };
      try {
        db.payment.add(paymentData);
      } catch (e) {
        errorHandler(e);
      }
    }
  });
}

function getPayments(requestId) {
  const promise = new Promise((resolve, reject) => {
    getDB().then(async db => {
      if (!db) {
        errorHandler('Error Getting DB');
        resolve([]);
      }
      try {
        let payments = [];
        const items = await db.payment.toArray();
        if (items.length > 0) {
          payments = items.filter(item => item.requestId === requestId);
        }
        resolve(payments);
      } catch (e) {
        errorHandler(e);
        reject(e);
      }
    });
  });

  return promise;
}

async function sendRequestData(requestPayload) {
  // Sync Local DB with Main DB

  const url = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/offline/sync`;
  // console.log('In Send Request Data: ', requestPayload, url);
  try {
    const response = await axios({
      url,
      method: 'POST',
      data: requestPayload,
    });

    return response.data;
  } catch (err) {
    return { error: true };
  }
}

function syncLocalDB() {
  const allPromises = [];
  // Get all requests and Payments
  getDB()
    .then(async db => {
      if (!db) {
        errorHandler('Cannot Get DB');
      } else {
        const requests = await db.request.toArray();
        // console.log('All Requests', requests);
        if (requests && requests.length > 0) {
          // Get request Items and payments
          requests.forEach(req => {
            if (!req.isSynced) {
              // console.log(req);
              const promise = new Promise(resolve => {
                const payload = {
                  requestId: req.requestId,
                  requestData: req,
                  requestItems: [],
                  payments: [],
                };
                const requestPromise = new Promise((requestResolve, requestReject) => {
                  getRequestItems(req.requestId)
                    .then(requestItems => {
                      if (requestItems && requestItems.length > 0) {
                        requestResolve(requestItems);
                      }
                    })
                    .catch(e => requestReject(e));
                });

                const paymentPromise = new Promise((paymentResolve, paymentReject) => {
                  getPayments(req.requestId)
                    .then(payments => {
                      if (payments && payments.length > 0) {
                        paymentResolve(payments);
                      }
                    })
                    .catch(e => paymentReject(e));
                });

                Promise.all([requestPromise, paymentPromise])
                  .then(resp => {
                    const [requestItems, payments] = resp;
                    payload.requestItems = requestItems;
                    payload.payments = payments;
                    sendRequestData(payload).then(data => {
                      if (!data.error) {
                        return resolve(payload.requestId);
                      }
                      return resolve(false);
                    });
                  })
                  .catch(e => {
                    errorHandler('Error getting Data');
                    errorHandler(e);
                    resolve(false);
                  });
              });
              allPromises.push(promise);
            }
          });
          Promise.all(allPromises)
            .then(responses => {
              // console.log('All Requests Promises response', responses);

              responses.forEach(requestId => {
                if (requestId) {
                  deleteRequestData(requestId);
                }
              });
            })
            .catch(e => {
              errorHandler('Error in Syncing');
              errorHandler(e);
            });
        }
      }
    })
    .catch(e => errorHandler(e));
}

export {
  createPayment,
  createRequest,
  updateRequestPaymentStatus,
  updateRequestPhoneNumber,
  updateRequestVendStatus,
  updateRequestItemVendStatus,
  updateRequestFeedback,
  getRequestItems,
  uniqid,
  syncLocalDB,
};
