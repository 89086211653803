// import React from 'react';
// import { Link } from 'react-router-dom';
// import { Button } from 'reactstrap';
// import './styles.css';

// class ScreenSaver extends React.Component {
//   shouldComponentUpdate() {
//     return false;
//   }

//   render() {
//     const { history } = { ...this.props };
//     return (
//       <div
//         className="screensaver-container"
//         role="button"
//         tabIndex={0}
//         onClick={() => history.push('/')}
//         onKeyPress={() => history.push('/')}>
//         <div className="screensaver">
//           <video width="100%" autoPlay="autoplay" muted loop>
//             <track src="" kind="captions" srcLang="en" label="English" />
//             <source src={localStorage.getItem('screensaverLink')} type="video/mp4" />
//             <Link to="/"> Order now </Link>
//           </video>
//         </div>
//         <div className="leave-screensaver">
//           <Button className="wendor-bg-green screensaver-button" onClick={() => history.push('/')}>
//             Order from machine
//           </Button>
//         </div>
//       </div>
//     );
//   }
// }

// export default ScreenSaver;

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './styles.css';

class ScreenSaver extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  handleGoBack = () => {
    const { history } = this.props;
    history.push('/', { from: '/Screensaver' });
  };

  render() {
    const { productDisplayPageUIOptions } = this.props;

    return (
      <div
        className="screensaver-container"
        role="button"
        tabIndex={0}
        onClick={this.handleGoBack}
        onKeyPress={this.handleGoBack}>
        <img src={productDisplayPageUIOptions.screensaverURL} className="screensaver" alt="screensaver" />
      </div>
    );
  }
}
const mapStateToProps = ({ machine }) => ({
  productDisplayPageUIOptions: machine.productDisplayPage,
});

export default withRouter(connect(mapStateToProps)(ScreenSaver));
