import React from 'react';

import '../assets/css/loaders.css';

export const Loader = () => (
  <div className="loader-bar">
    <div className="bar" />
  </div>
);
export const ThreeBubbleLoader = () => (
  <div className="spinner">
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </div>
);

export const LoaderInLine = () => (
  <div className="loader-rings full">
    <div />
    <div />
    <div />
    <div />
  </div>
);

export const LoaderCircular = () => (
  <div className="sk-chase">
    <div className="sk-chase-dot" />
    <div className="sk-chase-dot" />
    <div className="sk-chase-dot" />
    <div className="sk-chase-dot" />
    <div className="sk-chase-dot" />
    <div className="sk-chase-dot" />
  </div>
);
