import React from 'react';
import { Row, Col } from 'reactstrap';

import { SWIFT_WALLET_OTP_STATUS, PAYMENT_STATUS } from '../../actions/constants';
import GetUserPhoneNumber from '../GetUserPhoneNumber';
import GetUserPin from '../GetUserPin';

const SwiftUI = ({
  phoneNumber,
  isPhoneNumberValid,
  enteredOTP,
  otpStatus,
  walletState,
  walletPaymentStatus,
  isOTPValid,
  triesRemaining,
  errorCheckOTP,
  requestOTP,
  checkOTP,
  handleOTPChange,
  handlePhoneChange,
  goBack,
  txtSwiftTitle,
  txtPleaseWait,
  txtErrorMsg,
  txtEnterOtp,
  txtPayFail,
  txtRemainingTries,
  txtotpSentInfo,
  btnChangeNumber,
  btnSendOtp,
  otpPaymentType,
}) => {
  if (walletState === 1) {
    const txtIfPrasukWallet = 'Authenticate phone number to show UPI QR';
    return (
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Row>
            {otpStatus !== SWIFT_WALLET_OTP_STATUS.SENDING && (
              <Col xs={12} sm={12} md={12} lg={12}>
                {otpPaymentType === 'prasuk-paytm' ? (
                  <h5 style={{ minHeight: '3em' }}>{txtIfPrasukWallet}</h5>
                ) : (
                  <h5 style={{ minHeight: '3em' }}>{txtSwiftTitle}</h5>
                )}
              </Col>
            )}
          </Row>
          {otpStatus === SWIFT_WALLET_OTP_STATUS.SENDING && (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <h1>{txtPleaseWait}</h1>
                <div className="loader-linear" />
              </Col>
            </Row>
          )}
          {otpStatus === SWIFT_WALLET_OTP_STATUS.FAILED && (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <p>{txtErrorMsg}</p>
              </Col>
            </Row>
          )}
          {otpStatus !== SWIFT_WALLET_OTP_STATUS.SENDING && (
            <GetUserPhoneNumber
              isPhoneNumberValid={isPhoneNumberValid}
              handlePhoneNumberChange={handlePhoneChange}
              phoneNumber={phoneNumber}
              buttonText={btnSendOtp}
              onButtonClick={requestOTP}
            />
          )}
        </Col>
      </Row>
    );
  }
  if (walletState === 2) {
    if (walletPaymentStatus !== PAYMENT_STATUS.COMPLETED) {
      return (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <h5>
                  {txtEnterOtp} {phoneNumber}
                </h5>
              </Col>
            </Row>
            {walletPaymentStatus === PAYMENT_STATUS.INITIATED && (
              <Row>
                <div className="loader-linear" />
              </Row>
            )}
            {walletPaymentStatus === PAYMENT_STATUS.FAILED && (
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h5
                    style={{
                      background: '#ffb4b4',
                      borderRadius: '8px',
                      padding: '13px',
                    }}>
                    {txtPayFail} {errorCheckOTP}.
                  </h5>
                  <h6>
                    {txtRemainingTries} {triesRemaining}
                  </h6>
                </Col>
              </Row>
            )}
            <GetUserPin
              onButtonClick={checkOTP}
              value={enteredOTP}
              isValid={isOTPValid}
              handleChange={handleOTPChange}
              isLoading={walletPaymentStatus === PAYMENT_STATUS.INITIATED}
              buttonText={walletPaymentStatus === PAYMENT_STATUS.INITIATED ? 'Submiting' : 'Submit'}
              allowChangePhoneNumber
              changePhoneNumberText={txtotpSentInfo}
              changePhoneNumberButtonText={btnChangeNumber}
              changePhoneNumberButtonClick={goBack}
            />
          </Col>
        </Row>
      );
    }
    if (walletPaymentStatus === PAYMENT_STATUS.COMPLETED) {
      return (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </Col>
        </Row>
      );
    }
  }
  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12}>
        <h1>Something bad happend</h1>
      </Col>
    </Row>
  );
};

export default SwiftUI;
