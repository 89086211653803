import React from 'react';
import { connect } from 'react-redux';
import { searchProductByName } from '../../actions/refill';
import UpdateProductNameUI from '../../components/Refill/UpdateProductNameUI';

class UpdateProductNameModal extends React.Component {
  constructor(props) {
    super(props);

    const { selectedProduct } = { ...this.props };
    this.state = {
      isFetching: false,
      multiple: true,
      searchQuery: '',
      selectedInput: 'product',
      newProduct: '',
      price: selectedProduct.price,
      maxPrice: selectedProduct.actual_price,
      options: [],
      isShiftOn: false,
      isCapsOn: false,
      keyboardRequired: false,
    };

    this.onKeyboardInput = this.onKeyboardInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // this.getOptions();
  }

  shouldComponentUpdate() {

    return true;
  }

  componentDidUpdate() {
  }

  onKeyboardInput(button) {
    const { selectedInput, price, maxPrice } = { ...this.state };
    let { searchQuery } = { ...this.state };
    if (selectedInput === 'product') {
      if (button === '&#8592;') {
        searchQuery = searchQuery.substring(0, searchQuery.length - 1);
      } else {
        searchQuery += button;
      }

      this.setState({ searchQuery });
      this.getOptions();
    } else if (selectedInput === 'price') {
      let newPrice = price.toString();
      if (button === '&#8592;') {
        newPrice = newPrice.substring(0, newPrice.length - 1);
        if (!newPrice || newPrice > maxPrice) {
          newPrice = 0;
        }
      } else if (!Number.isNaN(Number(button))) {
        // Check if the entered value is a number or not
        if (newPrice) {
          newPrice += button;
        } else {
          newPrice = button;
        }
      }

      newPrice = parseFloat(newPrice);
      if (newPrice <= maxPrice) {
        this.setState({ price: newPrice });
      }
    }
  }

  getOptions = () => {
    const { searchQuery } = { ...this.state };
    this.setState({ isFetching: true });
    // console.log(searchQuery);
    // console.log('searchQuery');

    searchProductByName(searchQuery)
      .then(data => {
        // console.log(data);
        // console.log(data.data.length);
        // console.log('data.data.length');
        let options;
        // // console.log('data--1')
        // if (!data.error) {
        //

        if (!data.data) {
          options = [{ label: '', value: '' }];
        } else if (data.data.length === 0) {
          // console.log('data.data.length is 0');
          options = [{ label: '', value: '' }];
        } else if (data.data.length > 0) {
          // console.log('data.data.length is not 0');

          options = data.data.map(item => ({
            id: item.id,
            name: item.name,
            value: item,
            price: item.price,
            label: `${item.name}-₹${item.price}`,
          }));
        }
        //   // console.log(options)
        //   // console.log('options after')

        this.setState({
          isFetching: false,
          options,
        });
        // }
        // return this.setState({
        //   isFetching: false,
        //   options:[],
        // });
      })
      .catch(() => {
      });
  };

  closeKeyboard = () => {
    this.setState({ keyboardRequired: false });
  };

  openKeyboard = (isNumberOnly, input) => {
    this.setState({ keyboardRequired: true, isNumberOnly, selectedInput: input });
  };

  handlePriceChange = (e, { value }) => {
    if (!Number.isNaN(Number(value))) {
      // Check if the entered value is a number or not
      this.setState({ price: value });
    }
  };

  handleSearchChange = () => {
    // console.log('Please use on screen keyboard ');
  };

  handleChange(data) {
    // console.log('i am clicked');
    // console.log(data);
    this.setState({ newProduct: data, price: data.price, maxPrice: data.price });
  }

  render() {
    const { open, toggle, update, selectedProduct, changeType, updateProductLanguage } = this.props;
    const { options, isFetching, newProduct, price, searchQuery, maxPrice, keyboardRequired, isNumberOnly } = {
      ...this.state,
    };

    const {
      txtChangeProductDetails,
      txtSearchForProduct,
      txtMachinePrice,
      txtMoneySymbol,
      pHAmount,
      txtMrp,
      btnCancel,
      btnUpdate,
    } = updateProductLanguage;

    return (
      <UpdateProductNameUI
        open={open}
        toggle={toggle}
        update={update}
        selectedProduct={selectedProduct}
        changeType={changeType}
        options={options}
        isFetching={isFetching}
        newProduct={newProduct}
        price={price}
        searchQuery={searchQuery}
        maxPrice={maxPrice}
        keyboardRequired={keyboardRequired}
        isNumberOnly={isNumberOnly}
        handleSearchChange={this.handleSearchChange}
        openKeyboard={this.openKeyboard}
        handlePriceChange={this.handlePriceChange}
        handleChange={this.handleChange}
        onKeyboardInput={this.onKeyboardInput}
        txtChangeProductDetails={txtChangeProductDetails}
        txtSearchForProduct={txtSearchForProduct}
        txtMachinePrice={txtMachinePrice}
        txtMoneySymbol={txtMoneySymbol}
        pHAmount={pHAmount}
        txtMrp={txtMrp}
        btnCancel={btnCancel}
        btnUpdate={btnUpdate}
      />
    );
  }
}

const mapStateToProps = ({ languageReducer }) => {
  return {
    updateProductLanguage: languageReducer.updateProductLanguage,
  };
};

export default connect(mapStateToProps)(UpdateProductNameModal);
