/*
  2 views 
  - Getting phone number input for cash
  - cash input
    - Initializing Cash machine 
    - Error message if any 
    - Accepting cash
    
  This part of code will connect via socket and will work offline


*/

import React from 'react';
import { connect } from 'react-redux';
import openSocket from 'socket.io-client';
import { Row, Col } from 'reactstrap';
import { updateRequestPhoneNumber, updateRequestPaymentStatus, createPayment } from '../../../actions/db';

import { CASH_MODULE_STATUS, PAYMENT_STATUS, REQUEST_REDUCER_TYPES, CASH_REDUCER } from '../../../actions/constants';
import InitiatingCash from '../../../components/PaymentModes/offlineCash/cashInitiating';
import GetUserPhoneNumber from '../../../components/GetUserPhoneNumber';

class OfflineCash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: 1, // 1->Phone number, 2->Cash UI, 3-> Cancel Payment Screen
    };
  }

  componentWillUnmount() {
    // Close Socket
    try {
      this.socket.disconnect();
    } catch (e) {
      // console.log('Offline Cash:', e);
    }
  }

  connectToCash = () => {
    const {
      requestId,
      totalAmount,
      updateCashModuleStatus,
      updateCashAmount,
      cashCollectionComplete,
      socketURL,
      phoneNumber,
    } = this.props;

    updateRequestPhoneNumber(requestId, phoneNumber);

    this.setState({
      viewType: 2,
    });

    updateCashModuleStatus({
      status: CASH_MODULE_STATUS.INITIATING,
    });

    const url = socketURL || 'http://localhost:3001';
    this.socket = openSocket(url);
    this.socket.on('welcome', data => {
      if (data.e) {
        // Error in Socket System
        updateCashModuleStatus({ status: CASH_MODULE_STATUS.BROKEN });
      } else {
        this.socket.emit('IDENTIFY', { user: 'KIOSK' });
      }
    });

    const cashResponseTimeout = setTimeout(() => {
      this.socket.disconnect();
      updateCashModuleStatus({ status: CASH_MODULE_STATUS.BROKEN });
    }, 1000 * 15);

    const payload = {
      requestId,
      totalAmount,
    };

    this.socket.emit('CASH_INIT_SERVER', payload);
    this.socket.on('CASH_INIT_SERVER_RESPONSE', data => {
      // CASH INIT RESPONSE
      clearTimeout(cashResponseTimeout);
      // console.log('GOT Response from socket: CASH_INIT_SERVER_RESPONSE', data);
      if (data.isReady && data.status !== 'BROKENDOWN') {
        // To avoid race condition  added setTimeout
        setTimeout(() => updateCashModuleStatus({ status: CASH_MODULE_STATUS.ACCEPTING }), 1000 * 1);
      } else {
        setTimeout(() => updateCashModuleStatus({ status: CASH_MODULE_STATUS.BROKEN }), 1000 * 1);
      }
    });
    this.socket.on('CASH_RECEIVED_SERVER_RESPONSE', data => {
      // Increase Total Amount paid.
      updateRequestPaymentStatus(requestId, 'partial', null, 'offlinecash');
      createPayment(requestId, 'offlinecash', data.billCollected);
      updateCashAmount({ status: PAYMENT_STATUS.PARTIAL, addedAmount: data.billCollected });
    });
    this.socket.on('CASH_COMPLETION_SERVER_RESPONSE', data => {
      // Cash Completion. Start Vending
      // To avoid race condition  added setTimeout
      updateRequestPaymentStatus(requestId, 'completed', data.totalCashCollected, 'offlinecash');
      this.socket.disconnect();
      setTimeout(() => {
        cashCollectionComplete({
          status: PAYMENT_STATUS.COMPLETED,
          totalAmountPaid: data.totalCashCollected,
          method: 'offlinecash',
        });
      }, 1000 * 1);
    });
  };

  render() {
    const { viewType } = { ...this.state };
    const {
      cashModuleStatus,
      totalAmount,
      totalAmountPaid,
      toggleConformPaymentCancelModal,
      phoneNumber,
      isPhoneNumberValid,
      handlePhoneNumberChange,
    } = this.props;

    if (viewType === 1) {
      return (
        <GetUserPhoneNumber
          phoneNumber={phoneNumber}
          isPhoneNumberValid={isPhoneNumberValid}
          handlePhoneNumberChange={handlePhoneNumberChange}
          onButtonClick={this.connectToCash}
          mainHeading="Enter your phone number"
          subHeading="You will receive extra amount(if any) in your swift account."
          buttonText="Submit"
        />
      );
    }

    if (viewType === 2) {
      return (
        <InitiatingCash
          cashModuleStatus={cashModuleStatus}
          totalAmount={totalAmount}
          totalAmountPaid={totalAmountPaid}
          connectToCash={this.connectToCash}
          toggleConformPaymentCancelModal={toggleConformPaymentCancelModal}
        />
      );
    }

    return (
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <h1>Something bad happend</h1>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ cashReducer, cartReducer, requestReducer, machine }) => ({
  cashModuleStatus: cashReducer.cashModuleStatus,
  totalAmount: cartReducer.totalAmount,
  totalAmountPaid: requestReducer.totalAmountPaid,
  requestId: requestReducer.requestId,
  machineId: machine.machineId,
  socketURL: machine.socketURL,
});

const mapDispatchToProps = dispatch => ({
  updateCashModuleStatus: payload => dispatch({ type: CASH_REDUCER.UPDATE_CASH_MODULE, payload }),
  updateCashAmount: payload => dispatch({ type: REQUEST_REDUCER_TYPES.UPDATE_TOTAL_AMOUNT, payload }),
  cashCollectionComplete: payload => dispatch({ type: REQUEST_REDUCER_TYPES.FINAL_PAYMENT, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfflineCash);
