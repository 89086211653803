import React from 'react';
import uniqid from 'uniqid';
// import Slider from 'react-slick';

export default ({ productsData, updateCart }) => {
  return (
    <div className="station-card-display">
      {productsData.map(data => (
        <button type="button" key={uniqid()} className="station-card" onClick={() => updateCart(data.id, 1)}>
          <h1>20 ₹</h1>
          <p>Noida</p>
        </button>
      ))}
    </div>
  );
};
