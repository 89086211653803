import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Button, Row, Col } from 'reactstrap';

import { getCommandButtons, sendServiceModeButton, sendServiceModeCommands } from '../../actions/refill';
import NumberInput from '../KeypadInput/NumberInput';

const SettingsModal = ({ isOpen, toggleSettingModal }) => {
  const [Buttons, setButtons] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isError, setisError] = useState(false);

  const getSetCommandButtons = async () => {
    setisLoading(true);

    try {
      const { error, btn_details: buttons } = await getCommandButtons();
      if (error) {
        setisError(true);
        setButtons([]);
        setisLoading(false);
        return;
      }
      setisLoading(false);
      setisError(false);
      setButtons(buttons);
    } catch (e) {
      // console.log('Error');
    }
  };

  useEffect(() => {
    getSetCommandButtons();
  }, []);

  const handleOnClick = btnId => {
    // console.log(btnId);
    sendServiceModeButton(btnId);
  };

  const handleOnCommandSubmit = cmd => {
    // console.log('cmd', cmd);
    sendServiceModeCommands(cmd);
  };

  return (
    <div>
      <Modal size="xl" isOpen={isOpen} toggle={() => toggleSettingModal()}>
        <Row style={{ width: '100%' }}>
          <Col xs={10} sm={10} md={10} lg={10}>
            <h3>Enter Command</h3>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2}>
            <Button color="danger" onClick={() => toggleSettingModal()}>
              X
            </Button>
          </Col>
        </Row>
        <ModalBody style={{ minHeight: '700px', display: 'flex', flexDirection: 'column' }}>
          <NumberInput
            onSubmit={data => handleOnCommandSubmit(data)}
            showClearInput
            dontDisableSubmitButton
            isSubmitSuccess
            toggleModal={toggleSettingModal}
          />
          <hr />
          {!isError && !isLoading && Buttons && Buttons.length === 0 && <h4>No Button for this machine</h4>}
          <div className="settings-button-container">
            {!isError &&
              !isLoading &&
              Buttons.map(({ btn_id: btnId, btn_title: btnTitle }) => (
                <span
                  className="settings-button"
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  key={btnId}
                  onClick={() => handleOnClick(btnId)}>
                  {btnTitle}
                </span>
              ))}
          </div>
          {isLoading && <h4> Loading ... </h4>}
          {isError && <h4> Error in Loading Buttons </h4>}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SettingsModal;
