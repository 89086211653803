import React from 'react';

import './index.css';

export default () => (
  <div className="wifi-connecting-container">
    <div className="wifi-circle">
      <div className="wifi" />
    </div>
  </div>
);
