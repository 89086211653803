import React, { useState } from 'react';

import GetUserPhoneNumber from '../../components/GetUserPhoneNumber';

const NumberInput = ({ heading, onSubmit, isSubmitButtonLoading, toggleModal, dontDisableSubmitButton }) => {
  const [Number, setNumber] = useState('');

  const handleNumberChange = key => {
    // console.log('key ', key);
    if (key === '<') {
      setNumber('');
    } else {
      setNumber(Number + key);
    }
  };

  return (
    <div>
      {heading}
      <GetUserPhoneNumber
        buttonText="Submit"
        isSubmitButtonLoading={isSubmitButtonLoading}
        phoneNumber={Number}
        handlePhoneNumberChange={handleNumberChange}
        onButtonClick={() => onSubmit(Number)}
        showCloseButton
        dontDisableSubmitButton={dontDisableSubmitButton}
        onCloseButtonClick={toggleModal}
      />
    </div>
  );
};

export default NumberInput;
