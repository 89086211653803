import React from 'react';
// import { Button } from 'reactstrap';

import { LoaderInLine as Loader } from '../../components';

const RefillHeaderButtons = ({
  logout,
  exitButtonLoading,
  btnRefillModeExit,
  updateMachineProducts,
  totalAmount,
  homeMotorTest,
  saveButtonLoading,
  btnRefillModeSave,
  saveExitButtonLoading,
  btnRefillModeSaveExit,
  toggleSettingModal,
  isUpdating,
  btnRefillModeAllMotorsToHome,
}) => {
  return (
    <div className="save-button-row">
      <span role="button" tabIndex={0} onKeyDown={() => null} data-testid="refill-exit-btn" onClick={logout}>
        {!exitButtonLoading ? btnRefillModeExit : <Loader />}
      </span>
      <span
        onKeyDown={() => null}
        data-testid="refill-save-btn"
        disabled={isUpdating}
        role="button"
        onClick={() => updateMachineProducts(totalAmount, false)}>
        {!saveButtonLoading ? btnRefillModeSave : <Loader />}
      </span>
      <span
        onKeyDown={() => null}
        role="button"
        data-testid="refill-saveandexit-btn"
        disabled={isUpdating}
        onClick={() => updateMachineProducts(totalAmount, true)}>
        {!saveExitButtonLoading ? btnRefillModeSaveExit : <Loader />}
      </span>
      <span
        onKeyDown={() => null}
        role="button"
        data-testid="refill-homemoter-btn"
        disabled={isUpdating}
        onClick={homeMotorTest}>
        {btnRefillModeAllMotorsToHome}
      </span>

      <span
        onKeyDown={() => null}
        role="button"
        data-testid="refill-homemoter-btn"
        disabled={isUpdating}
        onClick={() => toggleSettingModal()}>
        Settings
      </span>
    </div>
  );
};

export default RefillHeaderButtons;
