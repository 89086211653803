/*
  Top heading 
  manualReload the full page
*/

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { Fragment, useState } from 'react';
import uniqid from 'uniqid';
import Carousel from 'nuka-carousel';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { MACHINE_STATUS } from '../../actions/constants';

function checkIfOnline() {
  if (navigator.onLine) {
    return window.location.reload();
  }
  return false;
}

const SelectionPageHeaderTop = ({ topHeadImageSize, machineStatus, txtMachineOffline, buttonCheckForInternet }) => {
  const [manualReloadTry, setmanualReloadTry] = useState(0);

  const imageUrls = [
    'https://wendor-kiosk-images.s3.ap-south-1.amazonaws.com/images/atlaswithlocker1.png',
    'https://wendor-kiosk-images.s3.ap-south-1.amazonaws.com/images/atlaswithlocker2.png',
    'https://wendor-kiosk-images.s3.ap-south-1.amazonaws.com/images/atlaswithlocker3.png',
  ];

  const manualReload = () => {
    if (manualReloadTry >= 1 && navigator.onLine) {
      return window.location.reload();
    }
    return setmanualReloadTry(manualReloadTry + 1);
  };

  const settings = {
    autoplay: true,
    autoplayInterval: 2000,
    speed: 1000,
    wrapAround: true,
  };
  const style = {
    height: '40px',
  };
  if (topHeadImageSize === 'lg') {
    style.height = '150px';
  }

  return (
    <div className="title-container">
      {machineStatus === MACHINE_STATUS.OFFLINE && (
        <Alert color="danger">
          {txtMachineOffline}{' '}
          <button type="button" onClick={() => checkIfOnline()}>
            {buttonCheckForInternet}
          </button>
        </Alert>
      )}
      <div onClick={() => manualReload()} role="none">
        <Carousel
          {...settings}
          renderCenterLeftControls={({ previousSlide, currentSlide }) => {
            if (currentSlide === 0) {
              return '';
            }
            return (
              <ion-icon style={{ color: 'red', fontSize: '34px' }} onClick={previousSlide} name="ios-arrow-back" />
            );
          }}
          renderBottomCenterControls={() => <div />}
          renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) => {
            if (currentSlide + 1 >= slideCount) {
              return '';
            }
            return <ion-icon style={{ color: 'red', fontSize: '34px' }} onClick={nextSlide} name="ios-arrow-forward" />;
          }}
          className="slider-control-bottomcenter paging-item">
          {imageUrls.map(url => {
            return (
              <Fragment key={uniqid()}>
                <img src={url} alt="Carousel" />
              </Fragment>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

const mapStateToProps = ({ machine }) => {
  return {
    machineBalance: machine.machineBalance,
  };
};

export default connect(mapStateToProps)(SelectionPageHeaderTop);
