function getChannels() {
  const machineId = window.localStorage.getItem('machineId') || { machineId: null };
  const multivac = JSON.parse(window.localStorage.getItem('multivac')) || { multivac: null };
  const { rfidVersion, coinChangerVersion } = JSON.parse(window.localStorage.getItem('peripheralVersions')) || {
    rfidVersion: null,
    coinChangerVersion: null,
  };

  const multivacVersion = multivac.version;
  const channels = {
    machineBusy: `/${multivacVersion}/${machineId}/machineBusy`,
    kioskRefresh: `KIOSK/${multivacVersion}/${machineId}/REFRESH`,
    kioskUpdate: `KIOSK/${multivacVersion}/${machineId}/KIOSKUPDATE`,
    machineToggle: `KIOSK/${multivacVersion}/${machineId}/MACHINETOGGLE`,
    kioskBusy: `KIOSK/${multivacVersion}/${machineId}/KIOSKBUSY`,
    initRespone: `VMC/${multivacVersion}/${machineId}/INIT_RESPONSE`,
    heartBeatResponse: `VMC/${multivacVersion}/${machineId}/HEARTBEAT_RESPONSE`,
    orderItemResponse: `VMC/${multivacVersion}/${machineId}/ORDER_ITEM_RESPONSE`,
    orderStatus: `VMC/${multivacVersion}/${machineId}/ORDER_STATUS`,
    cashInitResponse: `CASH/${multivacVersion}/${machineId}/INIT_RESPONSE`,
    cashReceived: `CASH/${multivacVersion}/${machineId}/CASH_RECEIVED`,
    cashCollectionComplete: `CASH/${multivacVersion}/${machineId}/CASH_COLLECTION_COMPLETED`,
    rfidEnableResponse: `RFID/${rfidVersion}/${machineId}/ENABLED`,
    rfidCardDetails: `RFID/${rfidVersion}/${machineId}/RFID_DATA`,
    paymentComplete: `SERVER/${multivacVersion}/${machineId}/PAYMENT_COMPLETE`,
    orderPicked: `VMC/${multivacVersion}/${machineId}/ORDER_PICKED`,
    coinReturnerDispensing: `COIN/${coinChangerVersion}/${machineId}/STARTING_DISPENSING`,
    coinReturnerDispensed: `COIN/${coinChangerVersion}/${machineId}/CASH_DISPENSED`,
    currencyReturnerDispensing: `CURRENCY/${multivacVersion}/${machineId}/CURRENCY_DISPENSING`,
    currencyReturnerDispensed: `CURRENCY/${multivacVersion}/${machineId}/PAYOUT_RESPONSE`, // multivacVersion
  };

  return channels;
}

export default getChannels;
