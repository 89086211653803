/* eslint-disable camelcase */

import React from 'react';
import { connect } from 'react-redux';
import {
  RFID_MODULE_STATUS,
  RFID_MODULE,
  REQUEST_REDUCER_TYPES,
  MODAL_REDUCER,
  PAYMENT_STATUS,
  TIMER_VALUES,
  TIMER,
} from '../../actions/constants';
import { authRfidTagId, authRfidPayment } from '../../actions/rfidPayment';
import { getPaymentDetails } from '../../actions/payment';
import RfidUI from '../../components/PaymentModes/Rfid';

class RfidModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pinTriesPending: 3,
      stage: 0, // 0 for wait RFID module , 1 for getting rfid data,2 for checking pin/otp
      heddings: [''],
      pinMessage: '',
      otpSentError: false,
      cardVerifyError: false,
      message: '',
      userDetails: null,
      isBalanceInsufficient: false,
      isSubmiting: false,
    };
    this.doRfidPayment = this.doRfidPayment.bind(this);
    this.autherizeCardData = this.autherizeCardData.bind(this);
    this.initRfid = this.initRfid.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.handleResetRfidPayMethod = this.handleResetRfidPayMethod.bind(this);
  }

  componentDidMount() {
    window.heap.track('Mounted rfid compoennt', {
      time: Date.now(),
    });
    this.initRfid();
    this.startTimer();
  }

  componentWillUnmount() {
    const { removeRfidCardData, requestId } = this.props;
    window.customClearSetTimeout(this.rfidInitWaitTimer, TIMER.RFID_INIT_TIMMER);
    removeRfidCardData(requestId);
  }

  getSnapshotBeforeUpdate(prevProps) {
    const { rfidCardData } = { ...this.props };
    const { cardVerifyError, isRetry } = { ...this.state };
    if (
      isRetry ||
      (!cardVerifyError && !prevProps.rfidCardData && rfidCardData) ||
      (prevProps.rfidCardData && prevProps.rfidCardData.requestId !== rfidCardData.requestId) ||
      (prevProps.rfidCardData && prevProps.rfidCardData.rfidData !== rfidCardData.rfidData)
    ) {
      this.setState({
        isRetry: false,
      });
      this.autherizeCardData(rfidCardData);
    } else {
      // console.log('Not Asking to autherize rfidData');
    }
    return null;
  }

  startTimer() {
    const { updateStateToBroken } = this.props;
    // console.log('Starting timer');
    this.rfidInitWaitTimer = window.customSetTimeOut(
      () => {
        const { rfidModuleStatus } = this.props;
        if (rfidModuleStatus === RFID_MODULE_STATUS.INITIATING) {
          updateStateToBroken();
        }
        // console.log('TIMER UP');
      },
      TIMER_VALUES.RFID_INIT_WAIT * 1000,
      TIMER.RFID_INIT_TIMMER,
    );
  }

  autherizeCardData({ rfidData, requestId }) {
    // console.log('autherizeCardData', rfidData, requestId)
    const { updateStateToAcceptingPin, updatePaymentStatus, openVendModal, setUserDetails, showRfidUserDetails } = {
      ...this.props,
    };

    if (!rfidData || !requestId) {
      // console.log('Missing rfidData or requestId');
      return;
    }
    authRfidTagId(rfidData, requestId).then(res => {
      /* eslint camelcase: ["error", {ignoreDestructuring: true}] */
      const { message, error, txn_completed, user_details, insufficient_balance } = res;
      // const msg = res.data.message
      const { type, otp_sent } = res;
      if (error) {
        // console.log('cardVerifyErrormessage', message, error);
        this.setState({
          cardVerifyError: true,
          message: message || `Insufficient balance or card is not linked to your account`,
        });
        setTimeout(() => {
          this.setState({
            cardVerifyError: false,
          });
          this.handleResetRfidPayMethod();
        }, 3000);
      } else if (insufficient_balance) {
        if (showRfidUserDetails) {
          this.setState({
            cardVerifyError: false,
            userDetails: user_details,
            isBalanceInsufficient: insufficient_balance,
          });
        } else {
          this.setState({
            cardVerifyError: false,
            isBalanceInsufficient: insufficient_balance,
          });
        }
        setTimeout(() => {
          this.setState({
            isBalanceInsufficient: false,
            userDetails: null,
          });
          this.handleResetRfidPayMethod();
        }, 5000);
      } else if (txn_completed) {
        setUserDetails(user_details);
        this.setState({
          pinTriesPending: 3,
          errorInPinCheck: false,
          errorMsg: '',
        });
        updatePaymentStatus({
          status: PAYMENT_STATUS.COMPLETED,
          method: 'rfid',
        });
        window.setTimeout(openVendModal(true), 3000); // Wait for 3 sec to start vending
      } else {
        if (type === 'pin') {
          this.setState({
            otpSentError: false,
            cardVerifyError: false,
            pinMessage: 'Enter PIN',
          });
        } else if (otp_sent) {
          this.setState({
            otpSentError: false,
            cardVerifyError: false,
            pinMessage: 'Enter OTP sent to linked number',
          });
        } else if (!otp_sent) {
          this.setState({
            cardVerifyError: false,
            otpSentError: true,
          });
        }
        updateStateToAcceptingPin();
      }
    });
  }

  initRfid() {
    const { requestId, initRfidModule } = { ...this.props };
    const details = {
      payment_method: 'rfid',
      request_id: requestId,
    };
    initRfidModule();
    getPaymentDetails(details).then(async data => {
      if (data.error) {
        this.setState({
          cardVerifyError: true,
          message: 'Error in getting payment details',
        });
      }
      // console.log(data);
    });
  }

  doRfidPayment() {
    const { pinTriesPending } = { ...this.state };
    const { rfidPin, requestId, updatePaymentStatus, openVendModal, setUserDetails, showRfidUserDetails } = {
      ...this.props,
    };
    this.setState({
      isOtpSubmiting: true,
    });
    window.heap.track('Payment Method', {
      paymentMethod: 'Rfid',
      time: Date.now(),
      requestId,
    });

    if (pinTriesPending <= 0) {
      this.setState({
        errorInPinCheck: true,
        errorMsg: 'Try limit exceed',
      });
      return;
    }

    authRfidPayment({ requestId, rfidPin })
      .then(res => {
        window.heap.track('auth Rfid payment response', {
          paymentMethod: 'PayTM',
          time: Date.now(),
          data: res,
        });

        if (!res.error) {
          if (showRfidUserDetails) {
            setUserDetails(res.user_details);
          }

          this.setState({
            pinTriesPending: 3,
            errorInPinCheck: false,
            errorMsg: '',
          });
          updatePaymentStatus({
            status: PAYMENT_STATUS.COMPLETED,
            method: 'rfid',
          });
          window.setTimeout(openVendModal(true), 3000); // Wait for 3 sec to start vending
        } else {
          this.setState({
            pinTriesPending: pinTriesPending - 1,
            errorInPinCheck: true,
            errorMsg: res.message,
          });
          this.setState({
            isOtpSubmiting: false,
          });
        }
        // Start Vending is transaction is successful.
      })
      .catch(e => {
        if (e) {
          this.setState({
            errorInPinCheck: true,
            errorMsg: 'Our server got wrong data.',
          });
        }
      });
  }

  handleResetRfidPayMethod() {
    const { changePaymentMethod, removeRfidCardData, requestId } = this.props;
    changePaymentMethod('rfid');
    this.initRfid();
    // this.autherizeCardData(rfidCardData);
    removeRfidCardData(requestId);
    window.customClearSetTimeout(this.rfidInitWaitTimer, TIMER.RFID_INIT_TIMMER);
    this.startTimer();
  }

  render() {
    const { rfidModuleStatus, handlePinNumberChange, rfidPin, rfidPaymentLanguage } = this.props;

    const {
      errorInPinCheck,
      errorMsg,
      pinTriesPending,
      pinMessage,
      otpSentError,
      message,
      cardVerifyError,
      userDetails,
      isBalanceInsufficient,
      isOtpSubmiting,
    } = {
      ...this.state,
    };

    const {
      txtTriesPending,
      txtWaitTitle,
      txtWaitInfo,
      txtToucSmartCardInfo,
      txtCardErrorMsg,
      txtCardErrorInstruction,
      btnRetryCardMachine,
      txtRfidBrokenMsg,
      btnRfidBrokenRetry,
      btnCardVerifyRetry,
      txtPostOtpErrorMsg,
      btnPostOtpErrorRetry,
      btnGetUserPin,
      txtTouchSmartCardLowBalance,
    } = rfidPaymentLanguage;
    return (
      <RfidUI
        errorInPinCheck={errorInPinCheck}
        errorMsg={errorMsg}
        pinTriesPending={pinTriesPending}
        rfidModuleStatus={rfidModuleStatus}
        cardVerifyError={cardVerifyError}
        message={message}
        handlePinNumberChange={handlePinNumberChange}
        rfidPin={rfidPin}
        otpSentError={otpSentError}
        pinMessage={pinMessage}
        userDetails={userDetails}
        isBalanceInsufficient={isBalanceInsufficient}
        txtTriesPending={txtTriesPending}
        txtWaitTitle={txtWaitTitle}
        txtWaitInfo={txtWaitInfo}
        txtToucSmartCardInfo={txtToucSmartCardInfo}
        txtTouchSmartCardLowBalance={txtTouchSmartCardLowBalance}
        txtCardErrorMsg={txtCardErrorMsg}
        txtCardErrorInstruction={txtCardErrorInstruction}
        btnRetryCardMachine={btnRetryCardMachine}
        isOtpSubmiting={isOtpSubmiting}
        txtRfidBrokenMsg={txtRfidBrokenMsg}
        btnRfidBrokenRetry={btnRfidBrokenRetry}
        btnCardVerifyRetry={btnCardVerifyRetry}
        txtPostOtpErrorMsg={txtPostOtpErrorMsg}
        btnPostOtpErrorRetry={btnPostOtpErrorRetry}
        btnGetUserPin={btnGetUserPin}
        doRfidPayment={this.doRfidPayment}
        handleResetRfidPayMethod={this.handleResetRfidPayMethod}
      />
    );
  }
}

const mapStateToProps = ({ machine, rfidModule, languageReducer }) => ({
  machineId: machine.machineId,
  rfidModuleStatus: rfidModule.rfidModuleStatus,
  rfidCardData: rfidModule.rfidCardData,
  rfidPaymentLanguage: languageReducer.rfidPaymentLanguage,
  showRfidUserDetails: machine.rfidModule.showRfidUserDetails,

  // requestId: requestReducer.requestId,
});

const mapDispatchToProps = dispatch => ({
  openVendModal: payload => dispatch({ type: MODAL_REDUCER.OPEN_VEND_MODAL, payload }),
  initRfidModule: () => dispatch({ type: RFID_MODULE.UPDATE_RFID_MODULE, payload: RFID_MODULE_STATUS.INITIATING }),
  updatePaymentStatus: payload => dispatch({ type: REQUEST_REDUCER_TYPES.FINAL_PAYMENT, payload }),
  updateStateToAcceptingPin: () =>
    dispatch({
      type: RFID_MODULE.UPDATE_RFID_MODULE,
      payload: RFID_MODULE_STATUS.ACCEPTING_PIN,
    }),
  removeRfidCardData: requestId =>
    dispatch({
      type: RFID_MODULE.SET_CARD_DETAILS,
      payload: { rfidData: null, requestId },
    }),
  updateStateToBroken: () =>
    dispatch({
      type: RFID_MODULE.UPDATE_RFID_MODULE,
      payload: RFID_MODULE_STATUS.BROKEN,
    }),
  setUserDetails: payload => dispatch({ type: RFID_MODULE.SET_USER_DETAILS, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RfidModule);
