import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
// import { Keyboard } from '.';
import { LoaderCircular } from './Loader';
import QwertyKeyboard from './QwertyKeyboard';

const GetUserEmail = ({
  onButtonClick,
  phoneNumber,
  emailDomain,
  isPhoneNumberValid,
  handlePhoneNumberChange,
  employeeId,
  handlecheckInput,
  handleEmailDomain,
  // mainHeading,
  // subHeading,
  buttonText,
  dontDisableSubmitButton,
  onCloseButtonClick,
  showCloseButton,
  isSubmitButtonLoading,
}) => {
  const [className, setClassName] = useState('');

  const machinetype = localStorage.getItem('machineType');
  const cash = JSON.parse(localStorage.getItem('cash'));

  useEffect(() => {
    const timer = setTimeout(() => {
      setClassName('');
    }, 155);

    return () => {
      clearTimeout(timer);
    };
  }, [className]);

  const handleClick = () => {
    setClassName('touch-animation-small ');
  };

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <QwertyKeyboard
            emailDomain={emailDomain}
            handlecheckInput={handlecheckInput}
            handleEmailDomain={handleEmailDomain}
            inputFeildLength={10}
            placeHolderString={cash.currencyId === 3 ? '0' : ''}
            isValid={isPhoneNumberValid}
            handleChange={handlePhoneNumberChange}
            employeeId={employeeId}
            value={phoneNumber}
            isSubmitButtonLoading={isSubmitButtonLoading}
            submitButton={
              <Button
                style={{ width: '100%' }}
                onTouchEnd={() => handleClick()}
                className={
                  machinetype !== 'big'
                    ? `${className} wendor-bg-green new-3d-button new-send-otp-btn new-red-submit-btn`
                    : 'wendor-bg-green wendor-small-button'
                }
                disabled={!dontDisableSubmitButton && !isPhoneNumberValid}
                onClick={() => onButtonClick()}>
                {buttonText}
              </Button>
            }
          />
          {isSubmitButtonLoading && <LoaderCircular />}
          {!isSubmitButtonLoading && showCloseButton && (
            <Button className="wendor-bg-red wendor-small-button" onClick={() => onCloseButtonClick()}>
              Close
            </Button>
          )}
          {/* {!isSubmitButtonLoading && (
          <Button
            onTouchEnd={() => handleClick()}
            className={`${className} wendor-bg-green new-3d-button new-red-submit-btn new-send-otp-btn `}
            disabled={!dontDisableSubmitButton && !isPhoneNumberValid}
            onClick={() => onButtonClick()}>
            {buttonText}
          </Button>
        )} */}
        </Col>
      </Row>
    </>
  );
};

export default GetUserEmail;
