import React, { useState, useEffect } from 'react';
import { getPaymentDetailsFonePay } from '../../actions/payment';
// import { ThreeBubbleLoader } from "../../components";
import QRDisplay from '../../components/QrDisplay';

const FonePayFlow = ({ requestId, checkPaymentStatus }) => {
  const [isFonePayQRFetched, setisFonePayQRFetched] = useState(false);
  const [isLoadingFonePayQR, setisLoadingFonePayQR] = useState(true);
  const [isFonePayQRFetchedError, setisFonePayQRFetchedError] = useState(false);
  const [FonePayQRData, setFonePayQRData] = useState('');

  const details = {
    payment_method: 'fonepay',
    request_id: requestId,
  };

  const getFonePayDetails = () => {
    window.heap.track('Payment Method', {
      paymentMethod: 'FonePay',
      time: Date.now(),
      requestId,
    });

    if (!requestId) {
      window.heap.track('no request id found ', {
        time: Date.now(),
      });
      setisFonePayQRFetched(false);
      setisLoadingFonePayQR(false);
      setisFonePayQRFetchedError(true);
      return;
    }

    getPaymentDetailsFonePay(details).then(data => {
      if (!data.error) {
        window.heap.track('FonePay details Response', {
          paymentMethod: 'FonePay',
          time: Date.now(),
          requestId,
          data,
        });
        setFonePayQRData(data.qrMessage);
        setisFonePayQRFetched(true);
        setisLoadingFonePayQR(false);
        checkPaymentStatus(); // Check payment status for fonePay
      } else {
        window.heap.track('FonePay details Error', {
          paymentMethod: 'FonePay',
          time: Date.now(),
          requestId,
        });
        setisFonePayQRFetchedError(true);
        setFonePayQRData('');
        setisFonePayQRFetched(false);
        setisLoadingFonePayQR(false);
      }
    });
  };
  useEffect(() => {
    // console.log('gettingFonePayDetails ----');
    window.heap.track('FonePay component renderd', {
      time: Date.now(),
    });
    getFonePayDetails();
  }, []);

  return (
    <QRDisplay
      isQRLoading={isLoadingFonePayQR}
      isQRFetched={isFonePayQRFetched}
      isQRFetchError={isFonePayQRFetchedError}
      QRData={FonePayQRData}
      requestId={requestId}
      showRetry
      retryButtonClickFunction={getFonePayDetails}
    />
  );
};

export default FonePayFlow;
