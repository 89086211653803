// -
import axios from 'axios';

export const getMachineToken = async () => {
  try {
    const response = await axios({
      url: `http://localhost:9000/api/v1/pi/getToken`,
      method: 'GET',
    });

    return response.data;
  } catch (err) {
    // console.log(err)
    return {
      status: false,
      error: true,
      data: [],
    };
  }
};
