import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Keyboard } from '.';

const GetUserPin = ({
  onButtonClick,
  value,
  isValid,
  handleChange,
  mainHeading,
  subHeading,
  buttonText,
  allowChangePhoneNumber,
  changePhoneNumberText,
  changePhoneNumberButtonText,
  changePhoneNumberButtonClick,
  isLoading = false,
}) => {
  const [className, setClassName] = useState('');

  const machinetype = localStorage.getItem('machineType');

  useEffect(() => {
    const timer = setTimeout(() => {
      setClassName('');
    }, 155);

    return () => {
      clearTimeout(timer);
    };
  }, [className]);

  const handleClick = () => {
    setClassName('touch-animation-small ');
  };

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12}>
        {mainHeading && <h5>{mainHeading}</h5>}
        {subHeading && <p>{subHeading}</p>}
        <Keyboard
          inputFeildLength={6}
          placeHolderString=""
          isValid={isValid}
          handleChange={handleChange}
          value={value}
          otpButton={
            <Button
              onTouchEnd={() => handleClick()}
              className={
                machinetype !== 'big'
                  ? `${className} ui button wendor-bg-green new-3d-button new-red-submit-btn new-otp-submit-btn`
                  : 'wendor-small-button ui button wendor-bg-green'
              }
              disabled={!isValid || isLoading}
              onClick={() => onButtonClick()}>
              {buttonText}
            </Button>
          }
        />
        {/* <Button
          onTouchEnd={() => handleClick()}
          className={`${className} ui button wendor-bg-green new-3d-button new-red-submit-btn new-otp-submit-btn`}
          disabled={!isValid || isLoading}
          onClick={() => onButtonClick()}>
          {buttonText}
        </Button> */}
      </Col>
      {allowChangePhoneNumber && (
        <Col xs={12} sm={12} md={12} lg={12}>
          <span>
            {changePhoneNumberText}
            <Button color="link" onClick={() => changePhoneNumberButtonClick()}>
              {changePhoneNumberButtonText}
            </Button>
          </span>
        </Col>
      )}
    </Row>
  );
};

export default GetUserPin;
