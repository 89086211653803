/*
  Top heading 
  manualReload the full page
*/

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState, useEffect } from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { MACHINE_STATUS } from '../../actions/constants';

function checkIfOnline() {
  if (navigator.onLine) {
    return window.location.reload();
  }
  return false;
}

const SelectionPageHeaderTop = ({ machineStatus, txtMachineOffline, buttonCheckForInternet }) => {
  const [manualReloadTry, setmanualReloadTry] = useState(0);
  const [currentStepText, setCurrentStepText] = useState(0);
  const [animationClass, setAnimationClass] = useState('');

  let timeout = null;
  let animationTimeout = null;

  const stepText = [
    'Select a product available on the machine',
    'Enter your email address and submit the otp',
    'Collect your product from the machine or locker',
  ];

  const manualReload = () => {
    if (manualReloadTry >= 1 && navigator.onLine) {
      return window.location.reload();
    }
    return setmanualReloadTry(manualReloadTry + 1);
  };

  const updateAnimationComponent = () => {
    setAnimationClass('home-position');
    switch (currentStepText) {
      case 0:
        setCurrentStepText(1);
        break;
      case 1:
        setCurrentStepText(2);
        break;
      case 2:
        setCurrentStepText(0);
        break;
      default:
        break;
    }
    animationTimeout = setTimeout(() => {
      setAnimationClass('');
    }, 4500);
  };

  useEffect(() => {
    timeout = setTimeout(() => {
      updateAnimationComponent();
    }, 5000);
  }, [currentStepText]);

  useEffect(() => {
    updateAnimationComponent();

    return clearTimeout(animationTimeout) && clearTimeout(timeout);
  }, []);

  return (
    <div className="title-container">
      {machineStatus === MACHINE_STATUS.OFFLINE && (
        <Alert color="danger">
          {txtMachineOffline}{' '}
          <button type="button" onClick={() => checkIfOnline()}>
            {buttonCheckForInternet}
          </button>
        </Alert>
      )}
      <div role="none" onClick={manualReload} className="blue-background">
        <div className="row atlaswithlocker-animation">
          <div className={animationClass}>
            <img src={`/images/atlaswithlockerhand${currentStepText + 1}.png`} alt="Hand 1" />
          </div>
          <div className={`atlaswithlocker-step-info ${animationClass}`}>
            <span>Step {currentStepText + 1}</span>
            <span>{stepText[currentStepText]}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ machine }) => {
  return {
    machineBalance: machine.machineBalance,
  };
};

export default connect(mapStateToProps)(SelectionPageHeaderTop);
