import React from 'react';
import { Row, Col } from 'reactstrap';
import NumberFormat from 'react-number-format';

const VendingUI = ({ defaultProductURL, currentItem }) => {
  const currencyDetails = JSON.parse(localStorage.getItem('cash'));

  return (
    <div>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className="cart-image">
          {currentItem.image_mini && (
            <img
              style={{ height: '200px', width: 'auto', margin: '10px', borderRadius: '15px' }}
              src={currentItem.image_mini}
              alt="product_image"
            />
          )}
          {!currentItem.image_mini && defaultProductURL && (
            <img
              style={{ height: '200px', width: 'auto', margin: '10px', borderRadius: '15px' }}
              src={defaultProductURL}
              alt="product_image"
            />
          )}
          {!currentItem.image_mini && !defaultProductURL && (
            <div
              style={{
                height: '200px',
                width: '200px',
                margin: '10px',
                borderRadius: '15px',
                display: 'flex',
                backgroundColor: 'black',
              }}>
              <p
                style={{
                  fontSize: '35px',
                  justifyContent: 'center',
                  alignSelf: 'center',
                  width: '100%',
                  color: '#fff',
                }}>
                NO IMAGE
              </p>
            </div>
          )}
        </Col>
      </Row>
      <NumberFormat
        value={` ${currentItem.price} `}
        displayType="text"
        thousandSeparator
        prefix={currencyDetails.isSuffix ? null : currencyDetails.symbol}
        suffix={currencyDetails.isSuffix ? currencyDetails.symbol : null}
        className="cart-price"
      />
      <div>
        <h3 style={{ marginBottom: '-3px', fontWeight: '800' }}>{currentItem.product_name}</h3>
        <span style={{ fontSize: '20px', display: 'inline-block' }}>
          {currentItem.product_description || 'No Description Provided.'}{' '}
        </span>
      </div>

      <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />
    </div>
  );
};

export default VendingUI;
