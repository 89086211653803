/* eslint-disable jsx-a11y/media-has-caption */

import React from 'react';

const TickAnimationAudio = () => {
  return (
    <div className="tick-container">
      <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
      <audio autoPlay>
        <source src="/audio/payment-success-2.mp3" type="audio/mpeg" />
        <source src="/audio/payment-success-2.wav" type="audio/wav" />
        <source src="/audio/payment-success-2.ogg" type="audio/ogg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default TickAnimationAudio;
