import React from 'react';
import { Row, Col } from 'reactstrap';
import uniqid from 'uniqid';
import NumberFormat from 'react-number-format';

export default ({ cartData }) => {
  const currencyDetails = JSON.parse(localStorage.getItem('cash'));
  return (
    <div>
      {cartData.length > 0 &&
        cartData.map(item => (
          <Row key={uniqid()}>
            <Col xs={6} sm={6} md={6} lg={6}>
              {item.showPos}
            </Col>
            <Col xs={4} sm={4} md={4} lg={4}>
              {item.quantity}
              x{' '}
              <NumberFormat
                value={` ${item.price} `}
                displayType="text"
                thousandSeparator
                prefix={currencyDetails.isSuffix ? null : currencyDetails.symbol}
                suffix={currencyDetails.isSuffix ? currencyDetails.symbol : null}
              />
            </Col>
            <Col xs={2} sm={2} md={2} lg={2}>
              <NumberFormat
                className="pull-right"
                value={` ${item.quantity * item.price} `}
                displayType="text"
                thousandSeparator
                prefix={currencyDetails.isSuffix ? null : currencyDetails.symbol}
                suffix={currencyDetails.isSuffix ? currencyDetails.symbol : null}
              />
            </Col>
          </Row>
        ))}

      {cartData.length === 0 && (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <h5>Cart is empty.</h5>
          </Col>
        </Row>
      )}
    </div>
  );
};
