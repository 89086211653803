import React from 'react';
import { connect } from 'react-redux';
import {
  PAYMENT_STATUS,
  REQUEST_REDUCER_TYPES,
  SWIFT_WALLET_OTP_STATUS,
  MODAL_REDUCER,
  VEND_REDUCER,
} from '../../actions/constants';
import {
  checkSwiftWalletOTP,
  getPaymentDetails,
  checkCustom3rdPartyOTPAuthOTP,
  checkPrasukOTP,
  checkPrasukOTPForSeparateMode,
  checkFreeVendOTP,
} from '../../actions/payment';
import SwiftUI from '../../components/PaymentModes/swift';
// import ConfirmWalletNumberModal from './confirmWalletNumberModal';

class Swift extends React.Component {
  constructor(props) {
    super(props);
    const { phoneNumber } = { ...this.props };
    this.state = {
      phoneNumber,
      isPhoneNumberValid: phoneNumber ? phoneNumber.length === 10 : false,
      isOTPValid: false,
      otpStatus: SWIFT_WALLET_OTP_STATUS.NOT_INITIATED,
      walletPaymentStatus: PAYMENT_STATUS.NOT_INITIATED,
      enteredOTP: '', // OTP entered by user.
      triesRemaining: 3, // Tries remaining
      confirmModal: false, // Is confirm modal is open or not
      errorCheckOTP: '',
      walletState: 1, // Represents on which state user is. 1-> Entering phone number. 2-> On OTP screen
    };
  }

  openConfirmModal = isOpen => {
    this.setState({
      confirmModal: isOpen,
    });
  };

  requestOTP = () => {
    const { phoneNumber, isPhoneNumberValid } = { ...this.state };
    const { requestId, updateOTPSent, otpPaymentType } = { ...this.props };
    window.heap.track('Wendor swift resend otp', {
      phoneNumber,
      time: Date.now(),
    });
    if (isPhoneNumberValid) {
      this.setState({
        otpStatus: SWIFT_WALLET_OTP_STATUS.SENDING,
        confirmModal: false,
      });
      let otpPaymentTypeToBeSent = otpPaymentType;
      if (otpPaymentType === 'prasuk-paytm') {
        otpPaymentTypeToBeSent = 'prasuk';
      }
      const requestData = {
        phone: phoneNumber,
        payment_method: otpPaymentTypeToBeSent,
        request_id: requestId,
      };
      getPaymentDetails(requestData).then(data => {
        if (!data.error) {
          updateOTPSent(true);
          this.setState({
            otpStatus: SWIFT_WALLET_OTP_STATUS.SENT,
            walletState: 2, // Show OTP screen
          });
        } else {
          updateOTPSent(false);
          this.setState({
            otpStatus: SWIFT_WALLET_OTP_STATUS.FAILED,
          });
        }
      });
    }
  };

  checkOTP = async () => {
    const { phoneNumber, enteredOTP, isOTPValid, triesRemaining } = { ...this.state };
    const { requestId, otpPaymentType } = this.props;

    window.heap.track('Check OTP pin', {
      phoneNumber,
      enteredOTP,
      triesRemaining,
      time: Date.now(),
    });

    if (isOTPValid && triesRemaining > 0) {
      this.setState({
        walletPaymentStatus: PAYMENT_STATUS.INITIATED,
      });
      const requestBody = {
        otp: enteredOTP,
        phone_number: phoneNumber,
        request_id: requestId,
      };
      if (otpPaymentType === 'swift') {
        try {
          const response = await checkSwiftWalletOTP(requestBody);
          this.handleAfterPayment(response, otpPaymentType);
        } catch (e) {
          // console.log('Error in CheckOTP');
        }
      } else if (otpPaymentType === 'free_vend') {
        try {
          const response = await checkFreeVendOTP(requestBody);
          this.handleAfterPayment(response, otpPaymentType);
        } catch (e) {
          // console.log('Error in CheckOTP');
        }
      } else if (otpPaymentType === 'lps_bossard') {
        try {
          const response = await checkCustom3rdPartyOTPAuthOTP(requestBody);
          this.handleAfterPayment(response, otpPaymentType);
        } catch (e) {
          // console.log('Error in CheckOTP');
        }
      } else if (otpPaymentType === 'prasuk') {
        try {
          const response = await checkPrasukOTP(requestBody);
          this.handleAfterPayment(response, otpPaymentType);
        } catch (e) {
          // console.log('Error in CheckOTP');
        }
      } else if (otpPaymentType === 'prasuk-paytm') {
        try {
          const response = await checkPrasukOTPForSeparateMode(requestBody);
          this.handleAfterPayment(response, otpPaymentType);
        } catch (e) {
          // console.log('Error in CheckOTP');
        }
      }
    }
  };

  handleAfterPayment = (data, method) => {
    const { triesRemaining } = { ...this.state };
    const { totalAmount, updatePaymentStatus, openVendModal, updateCustomMessage, changePaymentMethod } = this.props;
    if (!data.error) {
      if (method === 'prasuk-paytm') {
        changePaymentMethod('paytm');
      } else {
        this.setState({
          walletPaymentStatus: PAYMENT_STATUS.COMPLETED,
          errorCheckOTP: '',
        });
        updatePaymentStatus({
          status: PAYMENT_STATUS.COMPLETED,
          totalAmountPaid: totalAmount,
          method,
        });
        if (Object.prototype.hasOwnProperty.call(data, 'display_message') && data.display_message.length > 0) {
          updateCustomMessage(data.display_message);
        }
        // Start Vending is transaction is successful.
        window.setTimeout(() => openVendModal(true), 3000); // Wait for 3 sec to start vending
      }
    } else {
      this.setState({
        walletPaymentStatus: PAYMENT_STATUS.FAILED,
        triesRemaining: data.remaining_tries || triesRemaining - 1,
        errorCheckOTP: data.message,
      });
    }
  };

  handleOTPChange = e => {
    const { enteredOTP } = { ...this.state };
    // const { otpPaymentType } = this.props;
    let updatedOTP = enteredOTP;
    const otpMaxLength = 6;
    const otpMinLength = 4;
    // if (otpPaymentType === 'prasuk' || otpPaymentType === 'prasuk-paytm') {
    //   otpMaxLength = 6;
    // }
    if (e === '<') {
      updatedOTP = enteredOTP.slice(0, -1);
    } else if (enteredOTP.length < otpMaxLength) {
      updatedOTP = enteredOTP + e;
    }
    this.setState({ enteredOTP: updatedOTP });
    const otpRegex = new RegExp(`^(?=[0-9]*$)(?:.{${otpMinLength}}|.{${otpMaxLength}})$`);
    const isValidOtp = otpRegex.test(updatedOTP);
    if (isValidOtp) {
      this.setState({ isOTPValid: true });
    } else {
      this.setState({ isOTPValid: false });
    }
  };

  handlePhoneChange = e => {
    const { phoneNumber } = { ...this.state };
    let updatedPhoneNumber = phoneNumber;
    const phoneMaxLength = 10; // max length for phone number
    const phoneMinLength = 9; // min length for phone number
    if (e === '<') {
      updatedPhoneNumber = phoneNumber.slice(0, -1);
    } else if (phoneNumber.length < 10) {
      updatedPhoneNumber = phoneNumber + e;
      this.setState({ isPhoneNumberValid: false });
    }
    const phoneRegex = new RegExp(`^\\(?([0-9]{${phoneMinLength},${phoneMaxLength}})\\)?$`);
    const isValidPhoneNumber = phoneRegex.test(updatedPhoneNumber);
    if (isValidPhoneNumber) {
      this.setState({ isPhoneNumberValid: true });
    } else {
      this.setState({ isPhoneNumberValid: false });
    }
    this.setState({ phoneNumber: updatedPhoneNumber });
  };

  goBack = () => {
    // Change entered number
    this.setState({
      walletState: 1,
      enteredOTP: '',
      triesRemaining: 3,
      isOTPValid: false,
      errorCheckOTP: '',
      otpStatus: SWIFT_WALLET_OTP_STATUS.NOT_INITIATED,
      walletPaymentStatus: PAYMENT_STATUS.NOT_INITIATED,
    });
  };

  render() {
    const {
      phoneNumber,
      isPhoneNumberValid,
      enteredOTP,
      otpStatus,
      walletState,
      walletPaymentStatus,
      isOTPValid,
      triesRemaining,
      errorCheckOTP,
    } = {
      ...this.state,
    };
    const { FreeVendPaymentLanguage, otpPaymentType } = this.props;

    // console.log(FreeVendPaymentLanguage,"FreeVendPaymentLanguage")

    const {
      txtSwiftTitle,
      txtPleaseWait,
      txtErrorMsg,
      txtEnterOtp,
      txtPayFail,
      txtRemainingTries,
      txtotpSentInfo,
      btnChangeNumber,
      btnSendOtp,
    } = FreeVendPaymentLanguage;

    return (
      <SwiftUI
        txtSwiftTitle={txtSwiftTitle}
        txtPleaseWait={txtPleaseWait}
        txtErrorMsg={txtErrorMsg}
        txtEnterOtp={txtEnterOtp}
        txtPayFail={txtPayFail}
        btnSendOtp={btnSendOtp}
        txtRemainingTries={txtRemainingTries}
        txtotpSentInfo={txtotpSentInfo}
        btnChangeNumber={btnChangeNumber}
        phoneNumber={phoneNumber}
        isPhoneNumberValid={isPhoneNumberValid}
        enteredOTP={enteredOTP}
        otpStatus={otpStatus}
        walletState={walletState}
        walletPaymentStatus={walletPaymentStatus}
        isOTPValid={isOTPValid}
        triesRemaining={triesRemaining}
        errorCheckOTP={errorCheckOTP}
        requestOTP={this.requestOTP}
        checkOTP={this.checkOTP}
        handleOTPChange={this.handleOTPChange}
        handlePhoneChange={this.handlePhoneChange}
        goBack={this.goBack}
        otpPaymentType={otpPaymentType}
      />
    );
  }
}

const mapStateToProps = ({ cartReducer, requestReducer, languageReducer }) => ({
  totalAmount: cartReducer.totalAmount,
  requestId: requestReducer.requestId,
  paymentStatus: requestReducer.paymentStatus,
  FreeVendPaymentLanguage: languageReducer.FreeVendPaymentLanguage,
});

const mapDispatchToProps = dispatch => ({
  openVendModal: payload => dispatch({ type: MODAL_REDUCER.OPEN_VEND_MODAL, payload }),
  updatePaymentStatus: payload => dispatch({ type: REQUEST_REDUCER_TYPES.FINAL_PAYMENT, payload }),
  updateCustomMessage: payload => dispatch({ type: VEND_REDUCER.UPDATE_CUSTOM_MESSAGE, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Swift);
