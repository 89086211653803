/*
  Error modal 
  
  that will come on
   - Internet connection lost 
   - VMC disconnect 
  
  Action we can take 
   - Try again 
     - Will checkVMC
     - Will check mqtt connection
   - Refresh
     - Reload the app
   - Check Refill mode
     - If refill mode is on it will redirect to refill login page
   - Go offline 
     - cash offline 

*/

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, ModalFooter, ModalBody, ModalHeader } from 'reactstrap';
import { connectToMQTT, checkMultivac } from '../../actions/mqtt';
import { refillStateCheck } from '../../actions/refill';
import { updateMachineStatus } from '../../actions/machine';
import { TIMER_VALUES, ESP_STATUS, MQTT_STATUS, MODAL_REDUCER, ERROR_CODE, MACHINE } from '../../actions/constants';
import ErrorUI from '../../components/Error';

class Error extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isCheckingRefill: false,
      isConnectingMQTT: false,
      isEspCheckSent: false,
      isResfreshing: false,
    };

    this.handleRefresh = this.handleRefresh.bind(this);
    // this.stopTimer = this.stopTimer.bind(this);
  }

  componentDidMount() {
    // start Feedback timer
    this._isMounted = true;
    // console.log('starting timmer');
    this.startTimer();

    // this.startTimer();
  }

  componentWillUnmount() {
    // Stop Feedback Timer.
    this._isMounted = false;
    // console.log('Unmounting');
    this.stopTimer();
  }

  goOffline = () => {
    const { canUseOffline, setMachineStatus } = this.props;

    if (canUseOffline && !navigator.onLine) {
      setMachineStatus({ type: MACHINE.SET_MACHINE_OFFLINE });
    }
  };

  checkESP = () => {
    this.setState({
      isEspCheckSent: true,
    });
    checkMultivac().then(() => {
      if (this._isMounted) {
        this.setState({
          isEspCheckSent: false,
        });
      }
    });
  };

  checkRefillRequest = () => {
    // Check Refill Open Request
    this.setState({
      isCheckingRefill: true,
    });
    const { history } = { ...this.props };
    refillStateCheck().then(data => {
      if (this._isMounted) {
        this.setState({
          isCheckingRefill: false,
        });
        if (!data.error && data.success) {
          updateMachineStatus({
            statusCode: ERROR_CODE.REFILL_MODE,
            message: 'Opened for refill',
            mode: 'interaction',
          });
          history.push('/refill');
        }
      }
    });
  };

  checkMQTT = () => {
    const { connectToMQTTDispatch, multivacDetails } = { ...this.props };
    this.setState({
      isConnectingMQTT: true,
    });
    connectToMQTTDispatch(multivacDetails).then(() => {
      if (this._isMounted) {
        this.setState({
          isConnectingMQTT: false,
        });
      }
    });
  };

  openServiceMode = () => {
    window.location.href = process.env.REACT_APP_SERVICE_MODE_URL;
  };

  async startTimer() {
    // Dont remove this else timer will not run
    // await // console.log('starting');
    // await window.customSetInterval(()=>// console.log("done"),1000,"t")
    this.timerOutOfEsp = window.customSetInterval(
      () => {
        // console.log('Checking esp');
        this.checkESP();
        this.checkMQTT();
      },
      TIMER_VALUES.RETRY_ESP_CONNECT * 1000,
      'countDownEspTimmer',
    );
  }

  stopTimer() {
    window.customClearInterval(this.timerOutOfEsp, 'countDownEspTimmer');
  }

  handleRefresh() {
    this.setState({ isResfreshing: true });
    window.location.reload();
  }

  render() {
    const { isCheckingRefill, isConnectingMQTT, isEspCheckSent, isResfreshing } = { ...this.state };
    const {
      mqttStatus,
      espStatus,
      errorInComponent,
      showCancel,
      isErrorInProduct,
      isConfiguring,
      modalSize,
      errorImageURL,
    } = this.props;

    const isMQTTConnected = mqttStatus === MQTT_STATUS.CONNECTED;
    const isESPConnected = espStatus === ESP_STATUS.CONNECTED;
    const isLoading = isConnectingMQTT || isCheckingRefill || isEspCheckSent || isResfreshing;

    return (
      <Modal isOpen dimmer="inverted" size={modalSize || 'md'}>
        <ModalHeader data-testid="error-modal-heading">
          {isConfiguring ? 'Configuring Machine' : 'System Error'}
        </ModalHeader>
        <ModalBody>
          <ErrorUI
            isConfiguring={isConfiguring}
            isMQTTConnected={isMQTTConnected}
            isESPConnected={isESPConnected}
            showCancel={showCancel}
            errorInComponent={errorInComponent}
            checkESP={this.checkESP}
            checkMQTT={this.checkMQTT}
            isLoading={isLoading}
            errorImageURL={errorImageURL}
            isEspCheckSent={isEspCheckSent}
            isConnectingMQTT={isConnectingMQTT}
            isErrorInProduct={isErrorInProduct}
            goOffline={this.goOffline}
            checkRefillRequest={this.checkRefillRequest}
            isCheckingRefill={isCheckingRefill}
            onRefresh={this.handleRefresh}
            isResfreshing={isResfreshing}
            openServiceMode={this.openServiceMode}
          />
        </ModalBody>
        {!isConfiguring && (
          <ModalFooter data-testid="error-modal-contact-wendor-support">
            Contact Wendor Support if the issue persists.
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = ({ machine }) => ({
  token: machine.token,
  machineId: machine.machineId,
  canUseOffline: machine.canUseOffline,
  modalSize: machine.machineDetails.modalSize,
  errorImageURL: machine.productDisplayPage.errorImageURL,
  multivacDetails: machine.multivacDetails,
  mqttStatus: machine.mqttStatus,
  espStatus: machine.espStatus,
  isConfiguring: machine.isConfiguring,
});

const mapDispatchToProps = dispatch => ({
  closeErrorModal: () => dispatch({ type: MODAL_REDUCER.OPEN_ERROR_MODAL, payload: false }),
  connectToMQTTDispatch: multivacDetails => dispatch(connectToMQTT(multivacDetails)),
  setMachineStatus: payload => dispatch({ type: payload.type }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Error));
