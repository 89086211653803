/*
  Error modal 
  
  that will come on
   - Internet connection lost 
   - VMC disconnect 
  
  Action we can take 
   - Try again 
     - Will checkVMC
     - Will check mqtt connection
   - Refresh
     - Reload the app
   - Check Refill mode
     - If refill mode is on it will redirect to refill login page
   - Go offline 
     - cash offline 
*/

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Modal, ModalFooter, ModalBody, ModalHeader } from 'reactstrap';
import { connectToMQTT } from '../../actions/mqtt';
// import { refillStateCheck } from '../../actions/refill';
// import { updateMachineStatus } from '../../actions/machine';
import { ESP_STATUS, MQTT_STATUS, MODAL_REDUCER } from '../../actions/constants';
import MachineToggleModalUI from '../../components/MachineToggleModalUI';

class MachineToggleModal extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isCheckingRefill: false,
      isConnectingMQTT: false,
      isEspCheckSent: false,
      isResfreshing: false,
    };

    // this.handleRefresh = this.handleRefresh.bind(this);
    // this.stopTimer = this.stopTimer.bind(this);
  }

  componentDidMount() {
    // start Feedback timer
    this._isMounted = true;
    // console.log('starting timmer');
    // this.startTimer();

    // this.startTimer();
  }

  componentWillUnmount() {
    // Stop Feedback Timer.
    this._isMounted = false;
    // console.log('Unmounting');
    // this.stopTimer();
  }

  goOffline = () => {
    // const { canUseOffline, setMachineStatus } = this.props;
    // if (canUseOffline && !navigator.onLine) {
    //   setMachineStatus({ type: MACHINE.SET_MACHINE_OFFLINE });
    // }
  };

  checkESP = () => {
    // this.setState({
    //   isEspCheckSent: true,
    // });
    // checkMultivac().then(() => {
    //   if (this._isMounted) {
    //     this.setState({
    //       isEspCheckSent: false,
    //     });
    //   }
    // });
  };

  checkRefillRequest = () => {
    // // Check Refill Open Request
    // this.setState({
    //   isCheckingRefill: true,
    // });
    // const { history } = { ...this.props };
    // refillStateCheck().then(data => {
    //   if (this._isMounted) {
    //     this.setState({
    //       isCheckingRefill: false,
    //     });
    //     if (!data.error && data.success) {
    //       updateMachineStatus({
    //         statusCode: ERROR_CODE.REFILL_MODE,
    //         message: 'Opened for refill',
    //         mode: 'interaction',
    //       });
    //       history.push('/refill');
    //     }
    //   }
    // });
  };

  checkMQTT = () => {
    // const { connectToMQTTDispatch, multivacDetails } = { ...this.props };
    // this.setState({
    //   isConnectingMQTT: true,
    // });
    // connectToMQTTDispatch(multivacDetails).then(() => {
    //   if (this._isMounted) {
    //     this.setState({
    //       isConnectingMQTT: false,
    //     });
    //   }
    // });
  };

  openServiceMode = () => {
    // window.location.href = process.env.REACT_APP_SERVICE_MODE_URL;
  };

  render() {
    const { isCheckingRefill, isConnectingMQTT, isEspCheckSent, isResfreshing } = { ...this.state };
    const { mqttStatus, espStatus, errorInComponent, showCancel, isConfiguring, modalSize, errorImageURL } = this.props;

    const isMQTTConnected = mqttStatus === MQTT_STATUS.CONNECTED;
    const isESPConnected = espStatus === ESP_STATUS.CONNECTED;
    const isLoading = isConnectingMQTT || isCheckingRefill || isEspCheckSent || isResfreshing;

    return (
      <Modal isOpen dimmer="inverted" size={modalSize || 'md'}>
        <ModalHeader data-testid="error-modal-heading">
          {/* {isConfiguring ? 'Configuring Machine' : 'System Error'} */}
          Machine Switched Off
        </ModalHeader>
        <ModalBody>
          <MachineToggleModalUI
            errorImageURL={errorImageURL}
            isLoading={isLoading}
            errorInComponent={errorInComponent}
            showCancel={showCancel}
            isMQTTConnected={isMQTTConnected}
            isESPConnected={isESPConnected}
          />
        </ModalBody>
        {!isConfiguring && (
          <ModalFooter data-testid="error-modal-contact-wendor-support">
            Contact Wendor Support if the issue persists.
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = ({ machine }) => ({
  token: machine.token,
  machineId: machine.machineId,
  canUseOffline: machine.canUseOffline,
  modalSize: machine.machineDetails.modalSize,
  errorImageURL: machine.productDisplayPage.errorImageURL,
  multivacDetails: machine.multivacDetails,
  mqttStatus: machine.mqttStatus,
  espStatus: machine.espStatus,
  isConfiguring: machine.isConfiguring,
});

const mapDispatchToProps = dispatch => ({
  closeErrorModal: () => dispatch({ type: MODAL_REDUCER.OPEN_ERROR_MODAL, payload: false }),
  connectToMQTTDispatch: multivacDetails => dispatch(connectToMQTT(multivacDetails)),
  setMachineStatus: payload => dispatch({ type: payload.type }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MachineToggleModal));
