/* eslint-disable no-return-assign */
import React, { Fragment, memo } from 'react';
import uniqid from 'uniqid';
import { WithImageRowItemCardsDubai, ThreeBubbleLoader } from './index';

// export default ({ productsData, updateCart, checkout, resetCart, isProductLoading }) => {
class NewUiWithPhotoDubai extends React.Component {
  shouldComponentUpdate(np) {
    const { productsData, isProductLoading } = { ...this.props };
    return JSON.stringify(productsData) !== JSON.stringify(np.productsData) || np.isProductLoading !== isProductLoading;
  }

  render() {
    const { productsData, updateCart, isProductLoading, machineType, productDisplayPage, maxItems } = {
      ...this.props,
    };
    let style = {};
    if (machineType === 'big') {
      style = { height: '80vh', overflow: 'scroll' };
    }
    this.newProductsData = {};
    if (productsData) {
      this.newProductsDataForPidBatch = productsData.reduce((acc, cv) => {
        if (acc[cv.product_id]) {
          acc[cv.product_id].push(cv);
        } else {
          const temp = [];
          temp.push(cv);
          acc[cv.product_id] = [...temp];
        }
        return acc;
      }, {});
      // console.log('this.newProductsDataForPidBatch', this.newProductsDataForPidBatch);
      this.newProductsData = productsData.reduce((acc, obj) => {
        let key;
        if (obj.row) {
          key = obj.row;
        } else {
          key = obj.pos.substring(0, 1).toUpperCase();
        }
        if (!(key in acc)) {
          acc[key] = [];
        }
        const tempObj = {};
        Object.keys(obj).forEach(d => {
          if (d !== 'cart_quantity' && d !== 'total_units') {
            tempObj[d] = obj[d];
          }
          return true;
        });
        acc[key].push(tempObj);
        return acc;
      }, {});
    }
    // Object.keys(productsData).map( (d)=> d!='cart_quantity'?tempProductsData[d]=productsData[d]:false)
    if (isProductLoading) {
      return (
        <div className="flex-container">
          <ThreeBubbleLoader />
        </div>
      );
    }
    if (!productsData || productsData.length === 0) {
      return (
        <div>
          <h1>Machine is empty</h1>
          <h5>Please contact support</h5>
        </div>
      );
    }
    // console.log('isProductLoading', isProductLoading);
    // console.log('productsData.length', productsData.length);

    if (!isProductLoading && machineType === 'selectionbyproductid') {
      return (
        <div data-test="products-box" className="products-list-rowproductselect">
          {Object.keys(this.newProductsDataForPidBatch).map((data, index) => {
            return (
              <Fragment key={uniqid()}>
                <WithImageRowItemCardsDubai
                  id={uniqid()}
                  index={index}
                  machineType={machineType}
                  productsData={this.newProductsDataForPidBatch[data]} // {this.newProductsData[data]}
                  updateCart={updateCart}
                  isProductLoading={isProductLoading}
                  productDisplayPage={productDisplayPage}
                  maxItems={maxItems}
                />
              </Fragment>
            );
          })}
        </div>
      );
    }

    if (!isProductLoading && machineType === 'rowproductselect') {
      return (
        <div data-test="products-box" className="products-list-rowproductselect">
          {Object.keys(this.newProductsData).map((data, index) => {
            return (
              <Fragment key={uniqid()}>
                <WithImageRowItemCardsDubai
                  id={uniqid()}
                  index={index}
                  machineType={machineType}
                  productsData={this.newProductsData[data]} // {this.newProductsData[data]}
                  updateCart={updateCart}
                  isProductLoading={isProductLoading}
                  productDisplayPage={productDisplayPage}
                  maxItems={maxItems}
                />
              </Fragment>
            );
          })}
        </div>
      );
    }
    if (!isProductLoading || productsData.length > 0) {
      return (
        <div data-test="products-box" style={style}>
          {Object.keys(this.newProductsData).map(data => {
            return (
              <Fragment key={uniqid()}>
                <WithImageRowItemCardsDubai
                  id={uniqid()}
                  machineType={machineType}
                  productsData={this.newProductsData[data]}
                  updateCart={updateCart}
                  isProductLoading={isProductLoading}
                  productDisplayPage={productDisplayPage}
                  maxItems={maxItems}
                />
              </Fragment>
            );
          })}
        </div>
      );
    }
    return <h5>Page not loaded</h5>;
  }
}
export default memo(NewUiWithPhotoDubai);
