/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import uniqid from 'uniqid';
import '../assets/css/RowItemCard.css';

class ItemCard extends React.Component {
  // export default ({ productsData, updateCart, totalItems }) => {\
  constructor(props) {
    super(props);
    this.state = {
      quantity: 0,
      totalQuantityLeft: 0,
      price: 0,
      pos: '',
      selectedPos: [],
      productId: '',
      imageMini: '',
      isEmpty: true,
      actualPrice: 0,
      productName: 'Item',
    };

    this.gradients = [
      '62deg, #8EC5FC 0%, #E0C3FC 100%',
      '62deg, #FBAB7E 0%, #F7CE68 100%',
      '45deg, #FBDA61 0%, #FF5ACD 100%',
    ];

    this.setItemDetails = this.setItemDetails.bind(this);
    this.setQuantityOfItem = this.setQuantityOfItem.bind(this);
  }

  componentDidMount() {
    const { productItems } = { ...this.props };
    // console.log('productItems', productItems);
    this.setItemDetails(productItems);
    this.setQuantityOfItem(productItems);
    // console.log('this.state', this.state);
  }

  componentDidUpdate(prevProps, prevState) {
    const { numberOfReset } = { ...this.props };
    const { totalQuantityLeft } = { ...this.state };
    // console.log('totalQuantityLeft', totalQuantityLeft);
    // console.log(prevState.totalQuantityLeft);

    if (prevProps.numberOfReset !== numberOfReset || prevState.totalQuantityLeft !== totalQuantityLeft) {
      return true;
    }
    return false;
  }

  setItemDetails(productItems) {
    if (productItems[0]) {
      this.setState({
        price: productItems[0].price,
        imageMini: productItems[0].image_mini,
        pos: productItems[0].pos,
        productName: productItems[0].name,
        actualPrice: productItems[0].actual_price,
        productId: productItems[0].id,
      });
    } else {
      // console.log('not setting items details');
    }
  }

  setQuantityOfItem(productItems) {
    const totalQuantityLeft = productItems.reduce((acc, cv) => acc + cv.left_units, 0);
    // console.log('totalQuantityLeft', totalQuantityLeft);
    // console.log('productItems', productItems);
    this.setState({
      totalQuantityLeft,
      isEmpty: totalQuantityLeft === 0,
    });
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { numberOfReset } = { ...this.props };
    const { totalQuantityLeft } = { ...this.state };
    // if (prevProps.numberOfReset !== numberOfReset || prevProps.totalQuantityLeft !== totalQuantityLeft) {
    if (prevProps.numberOfReset !== numberOfReset || prevState.totalQuantityLeft !== totalQuantityLeft) {
      this.setState({
        quantity: 0,
        isEmpty: totalQuantityLeft === 0,
        selectedPos: [],
      });
    }
    return null;
  }

  getQuantityAndPosToUpdate(quantityByCoustomer) {
    const { totalQuantityLeft, selectedPos } = { ...this.state };
    const { productItems } = { ...this.props };
    let currentQuantityLeftToAlot = quantityByCoustomer;
    const currentPosAloted = [];
    // console.log('selectedPos', selectedPos);
    // console.log('totalQuantityLeft', totalQuantityLeft);
    // console.log('quantityByCoustomer', quantityByCoustomer);
    // console.log('productItems', productItems);
    if (quantityByCoustomer > totalQuantityLeft) {
      this.setState({ isEmpty: true });
      return { gotEmpty: true };
    }
    for (let i = 0; i < productItems.length; i += 1) {
      if (currentQuantityLeftToAlot <= 0) {
        break;
      }
      if (productItems[i].left_units > 0) {
        if (currentQuantityLeftToAlot <= productItems[i].left_units) {
          currentPosAloted.push({
            pos: productItems[i].pos,
            showPos: productItems[i].show_pos,
            updatedCartBy: currentQuantityLeftToAlot,
          });
          break;
        } else {
          currentPosAloted.push({
            pos: productItems[i].pos,
            showPos: productItems[i].show_pos,
            updatedCartBy: productItems[i].left_units,
          });
        }
        currentQuantityLeftToAlot -= productItems[i].left_units;
      }
    }

    // to check to update
    // console.log('selectedPos', selectedPos);
    // console.log('currentPosAloted1', currentPosAloted);
    if (selectedPos.length === 0) {
      this.setState({
        selectedPos: currentPosAloted,
      });
      // console.log('check-1', currentPosAloted[0]);
      return { ...currentPosAloted[0] };
    }

    const changeAtPos = currentPosAloted.filter(
      currentPosAlotedItem =>
        !selectedPos.some(
          selectedPosItem =>
            currentPosAlotedItem.pos === selectedPosItem.pos &&
            currentPosAlotedItem.updatedCartBy === selectedPosItem.updatedCartBy,
        ),
    );
    this.setState({
      selectedPos: currentPosAloted,
    });
    // console.log(changeAtPos);
    // console.log('check-1', changeAtPos[0]);
    return { ...changeAtPos[0] };
  }

  handleClick = (e, quantityUpdatedBy) => {
    // console.log('CALLEDDDDDDDD');
    e.stopPropagation();
    const { productId, isEmpty, totalQuantityLeft, quantity } = { ...this.state };
    const { updateCart, disableSelect } = this.props;
    if (isEmpty && quantityUpdatedBy > 0) {
      // console.log('isEmpty', isEmpty);
      return;
    }

    if (quantityUpdatedBy < 0) {
      // console.log(' quantityUpdatedBy is negative', quantityUpdatedBy);

      this.setState({
        quantity: quantity + quantityUpdatedBy <= 0 ? 0 : quantity + quantityUpdatedBy,
        isEmpty: false,
        selectedPos: [],
      });
      updateCart(productId, quantityUpdatedBy);
      // console.log('STATE_', this.state);
    } else if (!disableSelect) {
      // console.log('totalQuantityLeft', totalQuantityLeft);
      // console.log('quantity + quantityUpdatedBy', quantity + quantityUpdatedBy);
      if (totalQuantityLeft >= quantity + quantityUpdatedBy) {
        const { pos, updatedCartBy, gotEmpty, showPos } = this.getQuantityAndPosToUpdate(quantityUpdatedBy + quantity);
        // console.log('updatedCartBy-item row', updatedCartBy);
        // console.log('updatedCartBy-item row', showPos);
        if (!gotEmpty) {
          this.setState({ quantity: quantity + quantityUpdatedBy });
          updateCart(productId, updatedCartBy, pos, showPos);
        } else {
          // console.log('Slots got empty');
          this.setState({ isEmpty: true });
        }
      } else {
        // console.log('TOtal quantity is larger');
        this.setState({ isEmpty: true });
      }
    }
  };

  render() {
    const { disableSelect, machineType, index, productDisplayPage, defaultProductURL, symbol, suffix } = {
      ...this.props,
    };
    const {
      quantity,
      isEmpty,
      imageMini,
      price,
      // pos,
      actualPrice,
      productName,
      totalQuantityLeft,
    } = { ...this.state };
    // console.log('quantityquantity', quantity);
    const renderProductImage = () => {
      if (imageMini) {
        return (
          <div className="image-display-rowproduct-select">
            <img src={imageMini} alt="product_image" />
          </div>
        );
      }
      if (!imageMini && defaultProductURL) {
        return (
          <div className="image-display-rowproduct-select">
            <img src={defaultProductURL} alt="product_image" />;
          </div>
        );
      }
      return (
        <p
          style={{
            fontSize: '20px',
            color: '#fff',
            padding: 0,
            position: 'absolute',
            top: 20,
            right: 10,
            margin: 0,
          }}>
          NO IMAGE
        </p>
      );
    };

    return (
      <div
        role="button"
        tabIndex={0}
        onClick={e => {
          if (!disableSelect) this.handleClick(e, 1);
        }}
        className={`text-align-center ${
          isEmpty || totalQuantityLeft <= 0 ? 'shake-component' : ''
        } animate-product-box-rowitemselect`}>
        <div
          key={uniqid()}
          data-test="product-display-box"
          className={`display-inline-block  ${
            quantity > 0 && machineType !== 'imshow' ? 'selected-product-card' : 'unselected-product-card'
          } product-card-type-row-select`}
          style={{ backgroundImage: `linear-gradient(${this.gradients[index]})` }}
          disabled={disableSelect}>
          <div className={` ${isEmpty || totalQuantityLeft <= 0 ? 'empty-card-row-select ' : ''}`}>
            <div className="item-pos-display">
              {productDisplayPage.showImages && totalQuantityLeft > 0 && (
                <div className="image-display-rowproduct-select-conatainer">{renderProductImage()}</div>
              )}
              {productDisplayPage.showImages && totalQuantityLeft <= 0 && (
                <div className="soldout-ui">
                  <p>SOLD OUT</p>
                </div>
              )}

              <div className="item-info-rowproduct-select">
                {productDisplayPage.showName && (
                  <h5 className="product-name-row-select">
                    {productName.substring(0, 17)}
                    {productName.length > 17 ? '...' : ''}
                  </h5>
                )}
                {/* {// console.log(pos)} */}
                {/* Disable when no. of left units are less than 0 and cart_quantity >= left_units */}{' '}
                <button
                  type="button"
                  disabled={disableSelect}
                  data-test="product-select-button"
                  className="wendor-price"
                  style={{ margin: '1px', padding: '0px' }}>
                  {productDisplayPage.showDiscount && actualPrice !== price && (
                    <span className="discount-price">
                      <NumberFormat
                        value={` ${actualPrice} `}
                        displayType="text"
                        thousandSeparator
                        prefix={suffix ? null : symbol}
                        suffix={suffix ? symbol : null}
                      />
                    </span>
                  )}
                  {/* <span> ₹{productItem.price}</span> */}
                  <div style={{ fontSize: '40px', textAlign: 'left', margin: '12px 0', color: '#fff' }}>
                    <NumberFormat
                      value={` ${price} `}
                      displayType="text"
                      thousandSeparator
                      prefix={suffix ? null : symbol}
                      suffix={suffix ? symbol : null}
                    />
                  </div>
                </button>
                <div className="item-quantity-select">
                  <button type="button" onClick={e => this.handleClick(e, -1)}>
                    -
                  </button>
                  <h2 className="product-cart-quantity" data-test="product-cart-quantity">
                    {quantity}
                  </h2>
                  <button type="button" onClick={e => this.handleClick(e, 1)}>
                    {' '}
                    +{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ cartReducer, machine }) => ({
  disableSelect: cartReducer.disableSelect,
  numberOfReset: cartReducer.numberOfReset,
  defaultProductURL: machine.machineDetails.defaultProductURL,
});

export default connect(mapStateToProps)(memo(ItemCard));
