/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import uniqid from 'uniqid';
import '../assets/css/WithImageRowItemCard.css';

class WithImageItemCardDubai extends React.Component {
  // export default ({ productsData, updateCart, totalItems }) => {\
  constructor(props) {
    super(props);
    const { productItem } = this.props;
    this.state = {
      quantity: 0,
      isEmpty: productItem.left_units <= 0,
      touchAnimationClassName: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { numberOfReset, productItem } = { ...this.props };

    if (prevProps.numberOfReset !== numberOfReset || prevProps.productItem.left_units !== productItem.left_units) {
      return true;
    }
    return false;
  }

  getSnapshotBeforeUpdate(prevProps) {
    const { numberOfReset, productItem } = { ...this.props };
    if (prevProps.numberOfReset !== numberOfReset || prevProps.productItem.left_units !== productItem.left_units) {
      this.setState({ quantity: 0, isEmpty: productItem.left_units === 0 });
    }
    return null;
  }

  handleClickAnimation = () => {
    this.setState({ touchAnimationClassName: 'touch-animation ' });
    this.timer = setTimeout(() => {
      this.setState({ touchAnimationClassName: '' });
    }, 185);
  };

  handleClick = (e, newQuantity) => {
    const { quantity } = { ...this.state };
    const { productItem, updateCart, disableSelect } = this.props;
    if (newQuantity < 0) {
      this.setState({ quantity: quantity + newQuantity });
      updateCart(productItem.id, newQuantity + quantity);
    } else if (!disableSelect) {
      if (productItem.left_units >= quantity + newQuantity) {
        this.setState({ quantity: quantity + newQuantity });
        updateCart(productItem.id, newQuantity + quantity);
      } else {
        this.setState({ isEmpty: true });
      }
    }
  };

  render() {
    const { disableSelect, productItem, productDisplayPage, maxItems, defaultProductURL, symbol, suffix } = {
      ...this.props,
    };
    const { quantity, isEmpty, touchAnimationClassName } = { ...this.state };
    const productDisplayClass = `withImageProductCardDubai new-3d-button`;

    const renderProductImage = () => {
      if (productItem && productItem.image_mini) {
        return <img className="imageProductCardDubai" src={productItem.image_mini} alt="product_image" />;
      }
      if (productItem && !productItem.image_mini && defaultProductURL) {
        return <img className="imageProductCardDubai" src={defaultProductURL} alt="product_image" />;
      }
      return (
        <div className="no-product-img-parent-dubai">
          <img alt="no-item-cart" src="/assets/no-image-box.png" />
        </div>
      );
    };

    const isSlotDisable = isEmpty || productItem.left_units <= 0 || !productItem.enabled;
    // const style = productItem.custom_css ? JSON.parse(productItem.custom_css) : {};
    return (
      <div
        className={`text-align-center p-card ${productDisplayClass} ${
          isSlotDisable ? `shake-component sold-out-product-parent` : `${touchAnimationClassName}`
        } ${quantity > 0 ? 'new-ui-added-to-cart' : ''}`}>
        <div
          role="button"
          key={uniqid()}
          data-testid="product-display-box"
          className={`display-inline-block ${
            quantity > 0 ? 'new-ui-selected-product-card' : 'new-ui-unselected-product-card'
          }`}
          disabled={disableSelect}>
          <div className={` ${isSlotDisable ? 'new-ui-empty-card ' : ''} new-uicard-body`}>
            <div className="item-pos-display">
              <div className="new-ui-item-pos-dubai">
                <h3 className="new-ui-product-position" data-test="product-position">
                  {productItem.show_pos.toUpperCase()}
                </h3>
              </div>
              {productDisplayPage.showImages && productItem.left_units > 0 && (
                <div
                  role="none"
                  onClick={e => this.handleClick(e, 1)}
                  onTouchEnd={() => this.handleClickAnimation()}
                  className="image-display">
                  {renderProductImage()}
                </div>
              )}
              {productDisplayPage.showImages && productItem.left_units <= 0 && (
                <div>
                  <div
                    role="none"
                    onClick={() => this.handleClick(1)}
                    onTouchEnd={() => this.handleClickAnimation()}
                    className="image-display sold-out-product">
                    {renderProductImage()}
                  </div>
                  <img className="sold-out-product-img" alt="sold-out-cart" src="/assets/out-of-stock-grayscale.png" />
                </div>
              )}
              <div className="item-pos-name">
                <div className="new-ui-product-info">
                  <div role="none" onClick={() => this.handleClick(1)} onTouchEnd={() => this.handleClickAnimation()}>
                    {productDisplayPage.showName && (
                      <p className="new-ui-product-name">{productItem.name.substring(0, 14)}</p>
                    )}
                  </div>
                  {/* Disable when no. of left units are less than 0 and cart_quantity >= left_units */}{' '}
                </div>
              </div>
            </div>
            <div className="new-ui-product-price-quantity">
              <div
                role="none"
                onClick={() => this.handleClick(1)}
                onTouchEnd={() => this.handleClickAnimation()}
                className="new-ui-product-price">
                <h4>
                  <NumberFormat
                    value={`${productItem.price}`}
                    displayType="text"
                    thousandSeparator
                    prefix={suffix ? null : symbol}
                    suffix={suffix ? symbol : null}
                  />
                </h4>
              </div>
              <div className={`new-ui-cart-row ${quantity > 0 ? 'new-ui-cart-row-added' : ''}`}>
                {maxItems >= 1 && quantity > 0 && (
                  <div className="edit-cart-quantity" role="none" onClick={e => this.handleClick(e, -1)}>
                    <img alt="plus-cart" src="/assets/minus-cart.svg" />
                  </div>
                )}
                {quantity === 0 && (
                  <div role="none" onClick={e => this.handleClick(e, 1)} onTouchEnd={() => this.handleClickAnimation()}>
                    <h4 style={{ color: '#333', fontWeight: 'bold', margin: 0 }}>ADD</h4>
                  </div>
                )}
                {maxItems >= 1 && quantity > 0 && (
                  <h4 style={{ color: 'white', fontWeight: 'bold', margin: 0 }}>{quantity}</h4>
                )}
                {maxItems >= 1 && quantity > 0 && (
                  <div
                    className="edit-cart-quantity"
                    role="none"
                    onClick={e => this.handleClick(e, 1)}
                    onTouchEnd={() => this.handleClickAnimation()}>
                    <img alt="plus-cart" src="/assets/plus-cart.svg" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ cartReducer, machine }) => ({
  disableSelect: cartReducer.disableSelect,
  numberOfReset: cartReducer.numberOfReset,
  defaultProductURL: machine.machineDetails.defaultProductURL,
});

export default connect(mapStateToProps)(memo(WithImageItemCardDubai));
