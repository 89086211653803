import { CASH_REDUCER, CASH_MODULE_STATUS, CASH_RETURNER_STAGE } from '../../actions/constants';

const reducer = (
  state = {
    cashReturnerStage: CASH_RETURNER_STAGE.INIT,
  },
  { type, payload },
) => {
  switch (type) {
    case CASH_REDUCER.UPDATE_CASH_MODULE:
      return {
        ...state,
        cashModuleStatus: payload.status,
      };
    case CASH_MODULE_STATUS.SAVE_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: payload,
      };
    case CASH_MODULE_STATUS.REMOVE_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: '',
      };
    case CASH_RETURNER_STAGE.SET_STATUS:
      return {
        ...state,
        cashReturnerStage: payload,
      };
    case CASH_RETURNER_STAGE.RESET:
      return {
        cashReturnerStage: CASH_RETURNER_STAGE.IDLE,
        amountDispensed: 0,
        amountRequestedToDispense: 0,
        totalCoinsDispensed: 0,
        coinResponseDetails: [],
      };
    case CASH_RETURNER_STAGE.DISPENSED: {
      const { amountDispensed, amountRequested, totalCoinsDispensed, coinResponseDetails } = payload;
      // console.log('Payload', payload);
      return {
        ...state,
        cashReturnerStage: CASH_RETURNER_STAGE.DISPENSED,
        amountDispensed,
        amountRequestedToDispense: amountRequested,
        totalCoinsDispensed,
        coinResponseDetails,
      };
    }
    default:
      return state;
  }
};

export default reducer;
