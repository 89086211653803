import React from 'react';
import { connect } from 'react-redux';
import OtpInput from 'react-otp-input';
import '../assets/css/keyboard.css';
import ReactKeyboard from './react-keyboard/KeyboardWithSigns';

const QwertyKeyboard = ({
  handleChange,
  handleEmailDomain,
  details,
  handlecheckInput,
  emailDomain,
  employeeId,
  employeeIdInput,
  //   showEnterCancle,
  value,
  // isValid,
  placeHolderString,
  inputFeildLength,
  hideInput,
  submitButton,
  isSubmitButtonLoading,
  otpButton,
  emailExtentions,
}) => {
  return (
    <div>
      <div style={{ width: '100%' }} className="numeric-keyboard-container">
        {details && <h3>{details}</h3>}
        <div style={{ width: '100%' }} className="keyboard-input-submit-container">
          {false && inputFeildLength <= 5 && (
            <OtpInput
              numInputs={inputFeildLength || 6}
              value={value}
              containerStyle="keyboard-container-style"
              inputStyle="keyboard-input-style"
              shouldAutoFocus
              hasErrored
              isInputNum
              separator={<span />}
            />
          )}
          {!hideInput && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'space-between',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
              className="email-container">
              {employeeIdInput && (
                <textarea
                  className="keyboard-output keyboard-new"
                  value={`${employeeId}`}
                  // required
                  // readOnly
                  onClick={() => handlecheckInput(true)}
                  placeholder={placeHolderString || 'Enter Manager Email Id'}
                  style={{ backgroundColor: '#fff', minWidth: '100%', margin: '5px' }}
                />
              )}
              <textarea
                className="keyboard-output keyboard-new"
                value={`${value}`}
                // required
                // readOnly
                onClick={() => handlecheckInput(false)}
                placeholder={placeHolderString || 'Enter Your Email Address'}
                style={{ backgroundColor: '#fff', minWidth: '100%', margin: '5px' }}
              />
            </div>
          )}

          <div
            style={{
              display: 'flex',
              // border:"1px solid red",
              width: '100%',
              margin: '10px',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}>
            {emailExtentions.map(el => (
              <div>
                <input
                  style={{
                    height: '20px',
                    width: '20px',
                  }}
                  onClick={e => handleEmailDomain(e.target.value)}
                  type="radio"
                  name="email"
                  checked={el === emailDomain}
                  value={el}
                />{' '}
                <p
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}>
                  {el}
                </p>
              </div>
            ))}
          </div>

          {!isSubmitButtonLoading && submitButton}
          {otpButton}
        </div>

        <ReactKeyboard onKeyPress={handleChange} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ machine }) => ({
  emailExtentions: machine?.emailExtentions?.length > 0 ? machine.emailExtentions : [],
  employeeIdInput: machine?.employeeIdInput,
});

export default connect(mapStateToProps)(QwertyKeyboard);
