import React from 'react';
import { Modal, Row, Col, Button, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { MODAL_REDUCER, SENDING_MONEY_STATUS, CASH_MODULE_STATUS } from '../../actions/constants';
import { addMoneyToWalletV2 } from '../../actions/cashActions';
import { updateRequestStatus } from '../../actions/payment';

class TimedOutModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeLeft: 15, // totalTime is in seconds
      timerStarted: false, // if totalAmountPaid > 0 then no don't start timer
      isSomeCashPaid: false,
      extraCash: 0,
      sendingMoneyStatus: SENDING_MONEY_STATUS.NOT_INITIATED,
    };
  }

  componentDidMount() {
    // start Feedback timer
    const { totalAmountPaid } = { ...this.props };
    // console.log('totalAmountPaid');
    // this.startTimer();

    if (totalAmountPaid > 0) {
      this.setState({
        isSomeCashPaid: true,
        extraCash: totalAmountPaid,
      });
    }
    this.startTimer();
  }

  componentWillUnmount() {
    const { removePhonenumber } = { ...this.props };
    removePhonenumber();
    window.customClearInterval(this.retryTimer, 'timeOutModal');
  }

  startTimer = () => {
    const { close } = { ...this.props };
    this.setState({
      timerStarted: true,
    });
    this.retryTimer = window.customSetInterval(
      () => {
        const { timeLeft } = { ...this.state };
        if (timeLeft <= 0) {
          window.customClearInterval(this.retryTimer, 'timeOutModal');
          close();
        } else {
          this.setState({
            timeLeft: timeLeft - 1,
          });
        }
      },
      1000,
      'timeOutModal',
    ); // Wait for 5 second and then close the timed out modal
  };

  closeModal = () => {
    const { timeLeft, timerStarted } = { ...this.state };
    const { close } = { ...this.props };
    if (timerStarted) {
      if (timeLeft <= 0) {
        close();
      }
    } else {
      close();
    }
  };

  getRefund = () => {
    // console.log('data --');
    const { requestId, setRequestId, machineId, phoneNumber } = { ...this.props };
    this.setState({
      sendingMoneyStatus: SENDING_MONEY_STATUS.INITIATED,
    });
    const requestData = {
      order_id: requestId,
      machineId,
      phone_number: phoneNumber,
    };

    window.heap.track('Get refund', {
      time: Date.now(),
    });

    addMoneyToWalletV2(requestData)
      .then(data => {
        window.heap.track('Add money to wallet', {
          data,
          time: Date.now(),
        });
        // console.log(data);
        // console.log('data');
        if (!data.error) {
          updateRequestStatus(requestId, 'cash - refunds'); // Update request Status to cancelled
          setRequestId(null);
          this.setState({
            sendingMoneyStatus: SENDING_MONEY_STATUS.COMPLETED,
          });
        } else {
          this.setState({
            sendingMoneyStatus: SENDING_MONEY_STATUS.FAILED,
          });
        }
      })
      .catch(e => {
        if (e) {
          this.setState({
            sendingMoneyStatus: SENDING_MONEY_STATUS.FAILED,
          });
        }
      });
  };

  render() {
    const { extraCash, timeLeft, timerStarted, sendingMoneyStatus, isSomeCashPaid } = { ...this.state };
    const { modalSize } = { ...this.props };
    return (
      <Modal isOpen toggle={() => this.closeModal()} size={modalSize || 'md'}>
        <ModalHeader toggle={() => this.closeModal()}>Oops! Payment Timed Out.</ModalHeader>
        <ModalBody>
          {timerStarted && (
            <div>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <ion-icon className="ion-icon-big" style={{ fontSize: '100px' }} name="timer" />
                  <h5>Retry in {timeLeft}s</h5>
                  {isSomeCashPaid && <p>Extra Cash {extraCash} will be shifted to your Wendor Swift account.</p>}
                </Col>
              </Row>
            </div>
          )}
          {!timerStarted && (
            <div>
              {sendingMoneyStatus === SENDING_MONEY_STATUS.NOT_INITIATED && (
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Button className="wendor-bg-green wendor-small-button" onClick={() => this.getRefund()}>
                      Send Money again
                    </Button>
                  </Col>
                </Row>
              )}

              {sendingMoneyStatus === SENDING_MONEY_STATUS.INITIATED && <div className="loader-linear" />}
              {sendingMoneyStatus === SENDING_MONEY_STATUS.COMPLETED && (
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                          <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                          <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <h1>Transfer Successful</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <Button className="wendor-bg-green wendor-big-button" onClick={() => this.closeModal()}>
                          Close
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              {sendingMoneyStatus === SENDING_MONEY_STATUS.FAILED && (
                <div className="flex-container flex-direction-col">
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <h1>Transfer failed</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} className="sorry-message">
                      <ion-icon name="sad" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <Button className="wendor-bg-red wendor-small-button" onClick={() => this.closeModal()}>
                        Cancel
                      </Button>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6}>
                      <Button className="wendor-bg-green wendor-big-button" onClick={() => this.getRefund()}>
                        Try again
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <hr />
                      <p>Please contact wendor support if error presists at 8377815867</p>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = ({ machine, requestReducer, cashReducer }) => ({
  machineId: machine.machineId,
  modalSize: machine.machineDetails.modalSize,
  requestId: requestReducer.requestId,
  totalAmountPaid: requestReducer.totalAmountPaid,
  phoneNumber: cashReducer.phoneNumber,
});

const mapDispatchToProps = dispatch => ({
  close: () => dispatch({ type: MODAL_REDUCER.OPEN_PAYMENT_TIMEDOUT_MODAL, payload: false }),
  removePhonenumber: () => dispatch({ type: CASH_MODULE_STATUS.REMOVE_PHONE_NUMBER }),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimedOutModal);
