import { MODAL_REDUCER } from '../../actions/constants';

const modalReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case MODAL_REDUCER.RESET_MODALS:
      return {
        ...state,
        isOpenVendModal: false,
        isOpenErrorModal: false,
        isOpenPushRemainder: false,
        isOpenPaymentTimedOutModal: false,
      };
    case MODAL_REDUCER.OPEN_QR_MODAL:
      return {
        ...state,
        isOpenVendModal: false,
        isOpenErrorModal: false,
        isOpenPushRemainder: false,
        isOpenQRModal: payload,
        isOpenPaymentTimedOutModal: false,
      };
    case MODAL_REDUCER.OPEN_VEND_MODAL:
      return {
        ...state,
        isOpenErrorModal: false,
        isOpenQRModal: false,
        isOpenVendModal: payload,
        isOpenPushRemainder: false,
        isOpenPaymentTimedOutModal: false,
      };
    case MODAL_REDUCER.OPEN_PAYMENT_TIMEDOUT_MODAL:
      return {
        ...state,
        isOpenErrorModal: false,
        isOpenQRModal: false,
        isOpenVendModal: false,
        isOpenPushRemainder: false,
        isOpenPaymentTimedOutModal: payload,
      };
    case MODAL_REDUCER.OPEN_ERROR_MODAL:
      return {
        ...state,
        isOpenQRModal: false,
        isOpenVendModal: false,
        isOpenErrorModal: payload,
        isOpenPushRemainder: false,
        isOpenPaymentTimedOutModal: false,
      };
    case MODAL_REDUCER.OPEN_PUSH_REMAINDER_TO_WALLET_MODAL:
      return {
        ...state,
        isOpenQRModal: false,
        isOpenVendModal: false,
        isOpenErrorModal: false,
        isOpenPushRemainder: payload,
        isOpenPaymentTimedOutModal: false,
      };
    default:
      return state;
  }
};

export default modalReducer;
