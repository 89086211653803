import { CURRENCY_REDUCER, CURRENCY_MODULE_STATUS, CURRENCY_RETURNER_STAGE } from '../../actions/constants';

const reducer = (
  state = {
    currencyReturnerStage: CURRENCY_RETURNER_STAGE.INIT,
  },
  { type, payload },
) => {
  switch (type) {
    case CURRENCY_REDUCER.UPDATE_CASH_MODULE:
      return {
        ...state,
        currencyModuleStatus: payload.status,
      };
    case CURRENCY_MODULE_STATUS.SAVE_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: payload,
      };
    case CURRENCY_MODULE_STATUS.REMOVE_PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: '',
      };
    case CURRENCY_RETURNER_STAGE.SET_STATUS:
      return {
        ...state,
        currencyReturnerStage: payload,
      };
    case CURRENCY_RETURNER_STAGE.RESET:
      return {
        currencyReturnerStage: CURRENCY_RETURNER_STAGE.IDLE,
        creditLeft: 0,
      };
    case CURRENCY_RETURNER_STAGE.DISPENSED: {
      return {
        ...state,
        creditLeft: payload.amount_left,
        currencyReturnerStage: CURRENCY_RETURNER_STAGE.DISPENSED,
      };
    }
    default:
      return state;
  }
};

export default reducer;
