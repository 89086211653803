import React from 'react';
import { Row, Col } from 'reactstrap';

import { LoaderInLine } from '../index';

const MachineToggleModalUI = ({
  isMQTTConnected,
  isESPConnected,

  isLoading,
  errorImageURL,
}) => {
  return (
    <div>
      <div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            {!isESPConnected && !isMQTTConnected && (
              <img
                data-testid="error-image"
                className="image-height-400-width-300"
                src={errorImageURL}
                alt="error_image"
              />
            )}
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            {!isMQTTConnected && !isESPConnected && (
              <h5 data-testid="machine-down">
                The machine is currently turned off. You can contact Wendor support to switch it back on.
              </h5>
            )}
          </Col>
        </Row>
        {isLoading && (
          <Row>
            <LoaderInLine />
          </Row>
        )}
      </div>
    </div>
  );
};

export default MachineToggleModalUI;
