import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { LOG } from './constants';

if (process.env.REACT_APP_NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_RAVEN_DSN,
    release: '1',
  });
} else {
  Sentry.init({
    dsn: process.env.REACT_APP_RAVEN_DSN,
    release: '1',
  });
}
const sentryLog = async (err) => {
  // Sentry.withScope(scope => {
  //   scope.setExtras(info);
  //   Sentry.captureException(err);
  // });
  // console.log(info);
  Sentry.captureException(err);
};

export const SceenSaverWasActive = () => {
  return {
    type: LOG.SCREEN_SAVER_WAS_ACTIVE,
  };
};

export const RefreshSendMail = () => {
  try {
    const url = `${process.env.REACT_APP_KIOSK_CRITICAL_SERVICE_URL}/api/kioskRefreshed`;

    const response = axios({
      url,
      method: 'get',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }).catch(error => Sentry.captureException(`refresh email request failed\n${error}`));

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export const sendMail = requestBody => {
  try {
    const url = `${process.env.REACT_APP_KIOSK_CRITICAL_SERVICE_URL}/api/sendEmail`;

    const response = axios({
      url,
      method: 'post',
      data: requestBody,
    }).catch(error => Sentry.captureException(`send email request failed\n${error}`));

    return response.data;
  } catch (err) {
    return {
      error: true,
      data: {},
    };
  }
};

export default sentryLog;
