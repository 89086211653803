import { RFID_MODULE } from '../../actions/constants';

const reducer = (state = {}, { type, payload }) => {
  switch (type) {
    case RFID_MODULE.UPDATE_RFID_MODULE:
      return {
        ...state,
        rfidModuleStatus: payload,
      };
    case RFID_MODULE.SET_CARD_DETAILS:
      return {
        ...state,
        rfidCardData: payload,
      };
    case RFID_MODULE.SET_USER_DETAILS:
      return {
        ...state,
        userDetails: payload,
      };
    default:
      return state;
  }
};

export default reducer;
