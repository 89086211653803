import React from 'react';
import { connect } from 'react-redux';
import {
  PAYMENT_STATUS,
  REQUEST_REDUCER_TYPES,
  SWIFT_WALLET_OTP_STATUS,
  MODAL_REDUCER,
  VEND_REDUCER,
} from '../../actions/constants';
import {
  checkSwiftWalletOTP,
  getPaymentDetails,
  checkEmailOtp,
  checkCustom3rdPartyOTPAuthOTP,
  checkPrasukOTP,
  checkPrasukOTPForSeparateMode,
} from '../../actions/payment';
// import SwiftUI from '../../components/PaymentModes/swift';
// import ConfirmWalletNumberModal from './confirmWalletNumberModal';
import EmailOtpUi from '../../components/PaymentModes/emailOtpUI';

class EmailOtp extends React.Component {
  constructor(props) {
    super(props);
    const { phoneNumber, emailExtentions } = { ...this.props };

    // const emailExtentions = JSON.parse(localStorage.getItem('emailExtentions'));

    this.state = {
      phoneNumber,
      checkInput: false,
      isPhoneNumberValid: phoneNumber ? phoneNumber.length === 10 : false,
      isOTPValid: false,
      otpStatus: SWIFT_WALLET_OTP_STATUS.NOT_INITIATED,
      walletPaymentStatus: PAYMENT_STATUS.NOT_INITIATED,
      enteredOTP: '', // OTP entered by user.
      triesRemaining: 3, // Tries remaining
      confirmModal: false, // Is confirm modal is open or not
      errorCheckOTP: '',
      emailDomain: emailExtentions ? emailExtentions[0] : 'gmail.com',
      employeeId: '',
      walletState: 1, // Represents on which state user is. 1-> Entering phone number. 2-> On OTP screen
    };
  }

  openConfirmModal = isOpen => {
    this.setState({
      confirmModal: isOpen,
    });
  };

  requestOTP = () => {
    const { phoneNumber, isPhoneNumberValid, employeeId } = { ...this.state };
    const { requestId, updateOTPSent, otpPaymentType, employeeIdInput } = { ...this.props };

    window.heap.track('Wendor swift resend otp', {
      phoneNumber,
      time: Date.now(),
    });
    const { emailDomain } = this.state;

    if (employeeIdInput) {
      if (isPhoneNumberValid && phoneNumber.length > 1 && employeeId.length > 1) {
        this.setState({
          otpStatus: SWIFT_WALLET_OTP_STATUS.SENDING,
          confirmModal: false,
        });
        let otpPaymentTypeToBeSent = otpPaymentType;
        if (otpPaymentType === 'prasuk-paytm') {
          otpPaymentTypeToBeSent = 'prasuk';
        }

        const requestData = {
          email: `${phoneNumber}@${emailDomain}`,
          employee_id: employeeId,
          payment_method: otpPaymentTypeToBeSent,
          request_id: requestId,
        };

        getPaymentDetails(requestData).then(data => {
          if (!data.error) {
            updateOTPSent(true);
            this.setState({
              otpStatus: SWIFT_WALLET_OTP_STATUS.SENT,
              walletState: 2, // Show OTP screen
            });
          } else {
            updateOTPSent(false);
            this.setState({
              otpStatus: SWIFT_WALLET_OTP_STATUS.FAILED,
            });
          }
        });
      }
    } else {
      if (isPhoneNumberValid && phoneNumber.length > 1) {
        this.setState({
          otpStatus: SWIFT_WALLET_OTP_STATUS.SENDING,
          confirmModal: false,
        });
        let otpPaymentTypeToBeSent = otpPaymentType;
        if (otpPaymentType === 'prasuk-paytm') {
          otpPaymentTypeToBeSent = 'prasuk';
        }

        const requestData = {
          email: `${phoneNumber}@${emailDomain}`,
          payment_method: otpPaymentTypeToBeSent,
          request_id: requestId,
        };

        getPaymentDetails(requestData).then(data => {
          if (!data.error) {
            updateOTPSent(true);
            this.setState({
              otpStatus: SWIFT_WALLET_OTP_STATUS.SENT,
              walletState: 2, // Show OTP screen
            });
          } else {
            updateOTPSent(false);
            this.setState({
              otpStatus: SWIFT_WALLET_OTP_STATUS.FAILED,
            });
          }
        });
      }

      if (12 === 1) {
        console.log('');
      }
    }
  };

  checkOTP = async () => {
    const { phoneNumber, enteredOTP, isOTPValid, triesRemaining } = { ...this.state };
    const { requestId, otpPaymentType } = this.props;

    window.heap.track('Check OTP pin', {
      phoneNumber,
      enteredOTP,
      triesRemaining,
      time: Date.now(),
    });

    if (isOTPValid && triesRemaining > 0) {
      this.setState({
        walletPaymentStatus: PAYMENT_STATUS.INITIATED,
      });
      const { emailDomain } = this.state;
      const requestBody = {
        otp: enteredOTP,
        email: `${phoneNumber}@${emailDomain}`,
        request_id: requestId,
      };
      if (otpPaymentType === 'swift') {
        try {
          const response = await checkSwiftWalletOTP(requestBody);
          this.handleAfterPayment(response, otpPaymentType);
        } catch (e) {
          // console.log('Error in CheckOTP');
        }
      } else if (otpPaymentType === 'email_otp') {
        try {
          const response = await checkEmailOtp(requestBody);
          this.handleAfterPayment(response, otpPaymentType);
        } catch (e) {
          // console.log('Error in CheckOTP');
        }
      } else if (otpPaymentType === 'lps_bossard') {
        try {
          const response = await checkCustom3rdPartyOTPAuthOTP(requestBody);
          this.handleAfterPayment(response, otpPaymentType);
        } catch (e) {
          // console.log('Error in CheckOTP');
        }
      } else if (otpPaymentType === 'prasuk') {
        try {
          const response = await checkPrasukOTP(requestBody);
          this.handleAfterPayment(response, otpPaymentType);
        } catch (e) {
          // console.log('Error in CheckOTP');
        }
      } else if (otpPaymentType === 'prasuk-paytm') {
        try {
          const response = await checkPrasukOTPForSeparateMode(requestBody);
          this.handleAfterPayment(response, otpPaymentType);
        } catch (e) {
          // console.log('Error in CheckOTP');
        }
      }
    }
  };

  handleAfterPayment = (data, method) => {
    const { triesRemaining } = { ...this.state };
    const { totalAmount, updatePaymentStatus, openVendModal, updateCustomMessage, changePaymentMethod } = this.props;
    if (!data.error) {
      if (method === 'prasuk-paytm') {
        changePaymentMethod('paytm');
      } else {
        this.setState({
          walletPaymentStatus: PAYMENT_STATUS.COMPLETED,
          errorCheckOTP: '',
        });
        updatePaymentStatus({
          status: PAYMENT_STATUS.COMPLETED,
          totalAmountPaid: totalAmount,
          method,
        });
        if (Object.prototype.hasOwnProperty.call(data, 'display_message') && data.display_message.length > 0) {
          updateCustomMessage(data.display_message);
        }
        // Start Vending is transaction is successful.
        window.setTimeout(() => openVendModal(true), 3000); // Wait for 3 sec to start vending
      }
    } else {
      this.setState({
        walletPaymentStatus: PAYMENT_STATUS.FAILED,
        triesRemaining: data.remaining_tries || triesRemaining - 1,
        errorCheckOTP: data.message,
      });
    }
  };

  handleOTPChange = e => {
    const { enteredOTP } = { ...this.state };
    // const { otpPaymentType } = this.props;
    let updatedOTP = enteredOTP;
    const otpMaxLength = 6;
    const otpMinLength = 4;
    // if (otpPaymentType === 'prasuk' || otpPaymentType === 'prasuk-paytm') {
    //   otpMaxLength = 6;
    // }
    if (e === '<') {
      updatedOTP = enteredOTP.slice(0, -1);
    } else if (enteredOTP.length < otpMaxLength) {
      updatedOTP = enteredOTP + e;
    }
    this.setState({ enteredOTP: updatedOTP });
    const otpRegex = new RegExp(`^(?=[0-9]*$)(?:.{${otpMinLength}}|.{${otpMaxLength}})$`);
    const isValidOtp = otpRegex.test(updatedOTP);
    if (isValidOtp) {
      this.setState({ isOTPValid: true });
    } else {
      this.setState({ isOTPValid: false });
    }
  };

  handleEmailDomain = e => {
    this.setState({
      emailDomain: e,
    });
  };

  handlecheckInput = e => {
    this.setState({
      checkInput: e,
    });
  };

  handlePhoneChange = e => {
    const { phoneNumber, checkInput, employeeId } = { ...this.state };

    if (!checkInput) {
      let updatedPhoneNumber = phoneNumber;
      // const phoneMaxLength = 10; // max length for phone number
      // const phoneMinLength = 9; // min length for phone number
      if (e === '&#8592;') {
        updatedPhoneNumber = phoneNumber.slice(0, -1);
      } else {
        updatedPhoneNumber = phoneNumber + e;
      }
      // const phoneRegex = new RegExp('/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:[a-zA-Z0-9-]+)*$/');
      // const isValidPhoneNumber = phoneRegex.test(updatedPhoneNumber);
      // if (isValidPhoneNumber) {
      this.setState({ isPhoneNumberValid: true });
      // } else {
      //   this.setState({ isPhoneNumberValid: false });
      // }
      this.setState({ phoneNumber: updatedPhoneNumber });
    } else {
      let updatedPhoneNumber = employeeId;
      if (e === '&#8592;') {
        updatedPhoneNumber = employeeId.slice(0, -1);
      } else {
        if (e.match(/^[a-zA-Z0-9_@.]*$/)) {
          updatedPhoneNumber = employeeId + e;
        }
        if (123 === 156) {
          console.log('qq');
        }
      }
      this.setState({ isPhoneNumberValid: true });
      this.setState({ employeeId: updatedPhoneNumber });
    }
  };

  goBack = () => {
    // Change entered number
    this.setState({
      walletState: 1,
      enteredOTP: '',
      triesRemaining: 3,
      isOTPValid: false,
      errorCheckOTP: '',
      otpStatus: SWIFT_WALLET_OTP_STATUS.NOT_INITIATED,
      walletPaymentStatus: PAYMENT_STATUS.NOT_INITIATED,
    });
  };

  render() {
    const {
      phoneNumber,
      isPhoneNumberValid,
      enteredOTP,
      otpStatus,
      walletState,
      walletPaymentStatus,
      isOTPValid,
      triesRemaining,
      errorCheckOTP,
      emailDomain,
      employeeId,
    } = {
      ...this.state,
    };
    const { swiftPaymentLanguage, otpPaymentType } = this.props;

    const {
      txtSwiftTitle,
      txtPleaseWait,
      txtErrorMsg,
      txtEmailEmptyMsg,
      txtEmployeeIdEmptyMsg,
      txtEnterOtp,
      txtPayFail,
      txtRemainingTries,
      txtotpSentInfo,
      btnChangeNumber,
      btnSendOtp,
    } = swiftPaymentLanguage;

    const { requestId } = this.props;
    return (
      <div
        style={{
          // border:"1px solid red",
          width: '100%',
        }}>
        <EmailOtpUi
          handlecheckInput={this.handlecheckInput}
          requestId={requestId}
          employeeId={employeeId}
          emailDomain={emailDomain}
          handleEmailDomain={this.handleEmailDomain}
          txtSwiftTitle={txtSwiftTitle}
          txtPleaseWait={txtPleaseWait}
          txtErrorMsg={txtErrorMsg}
          txtEmailEmptyMsg={txtEmailEmptyMsg}
          txtEmployeeIdEmptyMsg={txtEmployeeIdEmptyMsg}
          txtEnterOtp={txtEnterOtp}
          txtPayFail={txtPayFail}
          btnSendOtp={btnSendOtp}
          txtRemainingTries={txtRemainingTries}
          txtotpSentInfo={txtotpSentInfo}
          btnChangeNumber={btnChangeNumber}
          phoneNumber={phoneNumber}
          isPhoneNumberValid={isPhoneNumberValid}
          enteredOTP={enteredOTP}
          otpStatus={otpStatus}
          walletState={walletState}
          walletPaymentStatus={walletPaymentStatus}
          isOTPValid={isOTPValid}
          triesRemaining={triesRemaining}
          errorCheckOTP={errorCheckOTP}
          requestOTP={this.requestOTP}
          checkOTP={this.checkOTP}
          handleOTPChange={this.handleOTPChange}
          handlePhoneChange={this.handlePhoneChange}
          goBack={this.goBack}
          otpPaymentType={otpPaymentType}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ machine, cartReducer, requestReducer, languageReducer }) => ({
  totalAmount: cartReducer.totalAmount,
  requestId: requestReducer.requestId,
  paymentStatus: requestReducer.paymentStatus,
  swiftPaymentLanguage: languageReducer?.emailOtpPaymentLanguage
    ? languageReducer?.emailOtpPaymentLanguage
    : languageReducer?.swiftPaymentLanguage,
  emailExtentions: machine?.emailExtentions,
  employeeIdInput: machine?.employeeIdInput,
});

const mapDispatchToProps = dispatch => ({
  openVendModal: payload => dispatch({ type: MODAL_REDUCER.OPEN_VEND_MODAL, payload }),
  updatePaymentStatus: payload => dispatch({ type: REQUEST_REDUCER_TYPES.FINAL_PAYMENT, payload }),
  updateCustomMessage: payload => dispatch({ type: VEND_REDUCER.UPDATE_CUSTOM_MESSAGE, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailOtp);
